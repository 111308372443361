import React, { useState } from 'react'
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = {
    notificationRecipient: { width: '100%', marginBottom: 20 },
    notificationRecipientDetails: { width: '100%', display: 'flex', marginBottom: 16, marginTop: 4, flexDirection: 'column' },
    confirmed: { minWidth: 100, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', margin: 4, marginTop: 0 },
    confirmedText: { flex: 0 },
    buttonsContainer: { width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 16, },
    confirmationButton: { width: 200, display: 'flex', alignItems: 'center', textAlign: 'center' },
    removeButton: { width: 100, display: 'flex', alignItems: 'center', justifyContent: 'center', }
}

const SingleNotificationRecipient = ({ contact: { id, email, name, active }, deleteContact, resendConfirmation, translate }) => {

    const [isSendingConfirmation, setIsSendingConfirmation] = useState(false);
    const [confirmationSent, setConfirmationSent] = useState(false);

    const resendConfirmationEmail = async () => {
        setIsSendingConfirmation(true);
        const sendReminderResponse = await resendConfirmation(id);
        setConfirmationSent(sendReminderResponse)
        setIsSendingConfirmation(false)
    }

    return (
        <ListItem key={email}>
            <div style={styles.notificationRecipient}>
                <div style={styles.notificationRecipientDetails}>
                    <ListItemText
                        primary={`Name: ${name}`}
                        primaryTypographyProps={{ style: { wordWrap: "break-word" } }}
                    />
                    <ListItemText
                        primary={`Email: ${email}`}
                        primaryTypographyProps={{ style: { wordWrap: "break-word" } }}
                    />
                </div>
                <div style={styles.buttonsContainer}>
                    {!active && <div style={styles.confirmationButton}>
                        {isSendingConfirmation ?
                            <CircularProgress
                                size={20}
                                thickness={4}
                            />
                            : confirmationSent ?
                                <ListItemText>{translate('resources.users.notifications.confirmEmail.sent')}</ListItemText>
                                :
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    onClick={resendConfirmationEmail}
                                >
                                    {translate('resources.users.notifications.confirmEmail.buttonText')}
                                </Button>
                        }
                    </div>}
                    {
                        active && <div style={styles.confirmationButton} />
                    }
                    <div style={styles.removeButton}>
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            classes={{ root: 'delete-button' }}
                            onClick={deleteContact}
                        >
                            {translate('resources.users.notifications.remove.buttonText')}
                        </Button>
                    </div>
                </div>
            </div>
        </ListItem>
    )
}

export default SingleNotificationRecipient