import Card from "@material-ui/core/Card";
import withStyles from "@material-ui/core/styles/withStyles";
import FeedbackIcon from "@material-ui/icons/Feedback";
import React from "react";
import {
  Datagrid, DateField, Filter, List, TextField, TextInput
} from "react-admin";
import SecureLS from "secure-ls";

import extractProfile from "../analytics_config/extractProfile";
import { pageAnalytic } from "../analytics_config/plugins/setAnalytic";
import AuthenticationError from "../components/AuthenticationError";
import CardIcon from "../components/CardIcon";
import Header from "../components/Header";
import config from "../conf/common";
import CustomerLinkField from "./utilities/CustomerLinkField";
import PlatformLinkField from "./utilities/PlatformLinkField";
import ListActions from "../components/ListActions";

const ls = new SecureLS({ encodingType: "aes" });

const styles = theme => ({
  rowEven: {
    backgroundColor: "#F8F8F8"
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  table: {
    minWidth: 500
  },
  tableWrapper: {
    overflowX: "auto"
  },
  main: {
    flex: "1",
    marginRight: "1em",
    marginTop: 20
  },
  card: {
    overflow: "inherit",
    textAlign: "left",
    padding: 16,
    minHeight: 52,
    marginRight: '1em'
  }
});

const PostFilter = props => (
  <Filter {...props}>
    <TextInput label="Message" source="message" alwayson="true" />
  </Filter>
);

const FeedbackList = withStyles(styles)(({ classes, ...props }) => {
  const headerContent = "All Feedback";

  const newProfile = extractProfile();
  if (newProfile) {
    pageAnalytic(newProfile, { title: 'Feedback Listing' });
  }

  const authenticated = ls.get("authenticated");

  if (authenticated === "yes") {
    return (
      <React.Fragment>
        <Card className={classes.card}>
          <CardIcon
            Icon={FeedbackIcon}
            bgColor={config.colors.backgroundSeaLight}
            orientation="left"
          />
          <Header content={headerContent} orientation="left" />
          <List
            title="All Feedback"
            {...props}
            bulkActionButtons={false}
            filters={<PostFilter />}
            actions={<ListActions type="feedback" />}
          >
            <Datagrid rowClick="show" classes={{ rowEven: classes.rowEven }}>
              <CustomerLinkField />
              <DateField source="created_at" label="Created" />
              <TextField source="message" label="Message" sortable={false} />
              <TextField source="device_name" label="Device" sortable={false} />
              <PlatformLinkField />
            </Datagrid>
          </List>
        </Card>
      </React.Fragment>
    );
  }
  return <AuthenticationError />;
});

export default FeedbackList;
