import localforage from "localforage";
import { AUTH_LOGOUT } from "react-admin";
import SecureLS from "secure-ls";

import { log } from "./commonProviderFunctions";
import getAllDistrictResponse from "./allDistrictsProvider";
import getDistrictBannersResponse from "./districtBannersProvider";
import getDistrictTypesResponse from "./districtTypesProvider";
import getSchoolsResponse from "./schoolsProvider";
import getStudentsResponse from "./studentsProvider";
import getActivityStudentsResponse from "./activityStudentsProvider";
import getStatesResponse from "./statesProvider";
import getStatsResponse from "./statsProvider";
import getUsersResponse from "./usersProvider";
import getDashboardUsersResponse from "./dashboardUsersProvider";
import getFeedbackResponse from "./feedbackProvider";
import getRidershipResponse from "./ridershipProvider";
import getServiceAlertsResponse from "./serviceAlertsProvider";
import getTransformSchedulesResponse from "./transformScheduleProvider";
import authProvider from "../../authProviderDirectCallVersion";

const ls = new SecureLS({ encodingType: "aes" });

const resources = {
  districts: getAllDistrictResponse,
  districtBanners: getDistrictBannersResponse,
  districtTypes: getDistrictTypesResponse,
  schools: getSchoolsResponse,
  students: getStudentsResponse,
  activitystudents: getActivityStudentsResponse,
  states: getStatesResponse,
  stats: getStatsResponse,
  users: getUsersResponse,
  feedback: getFeedbackResponse,
  ridership: getRidershipResponse,
  servicealerts: getServiceAlertsResponse,
  transformschedule: getTransformSchedulesResponse,
  dashboardUsers: getDashboardUsersResponse
};

/**
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The data request params, depending on the type
 * @param {Integer} district The district request params, depending on the type
 * @returns {Promise} The response
 */

const dataProviders = () => (type, resource, params, district) => {
  const flag = resource.replace(/\s/g, "");
  const requestFunc = resources[flag];
  const request = requestFunc(type, flag, params, district);

  if (requestFunc === undefined) {
    return Promise.reject(new Error(`Unsupported fetch action type ${type}`));
  }

  return request.then(() => {
    log(type, flag, params, request);
    return Promise.resolve(request);
  }).catch(code => {
    if (code === 401) {
      const path = "/login";
      ls.removeAll();
      localStorage.clear();
      localforage.clear();
      authProvider(AUTH_LOGOUT, {});
      return window.location.assign(path);
    }
  });
};

export default dataProviders;
