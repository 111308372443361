import Card from "@material-ui/core/Card";
import FeedbackIcon from "@material-ui/icons/Feedback";
import React from "react";
import {
  DateField, Show, Tab, TabbedShowLayout, TextField
} from "react-admin";
import withStyles from '@material-ui/core/styles/withStyles';

import extractProfile from "../analytics_config/extractProfile";
import { pageAnalytic } from "../analytics_config/plugins/setAnalytic";
import CardIcon from "../components/CardIconTwo";
import Header from "../components/Header";
import config from "../conf/common";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  main: {
    flex: "1",
    marginRight: "1em",
    marginTop: 20
  },
  card: {
    overflow: "inherit",
    textAlign: "left",
    padding: 16,
    minHeight: 52,
    marginRight: "1em"
  },
  noActions: {
    marginTop: '60px',
    marginLeft: '15px'
  }
});

const FeedbackView = props => {
  const headerContent = "Feedback Details";

  const newProfile = extractProfile();
  if (newProfile) {
    pageAnalytic(newProfile, { title: 'Feedback View' });
  }

  const { classes } = props;

  return (
    <Card className={classes.card}>
      <CardIcon
        Icon={FeedbackIcon}
        bgColor={config.colors.backgroundSeaLight}
      />
      <Header content={headerContent} orientation="left" />
      <Show title="Feedback Details" {...props}>
        <TabbedShowLayout>
          <Tab label="Comment">
            <TextField source="message" label="Message" />
            <TextField source="device_name" label="Device" />
            <TextField source="platform" label="Platform" />
          </Tab>
          <Tab label="User">
            <TextField source="user.first_name" label="First Name" />
            <TextField source="user.last_name" label="Last Name" />
            <TextField source="user.email" label="Email" />
            <TextField source="user.phone" label="Phone Number" />
            <TextField source="user.status" label="Status" />
            <TextField source="user.login_count" label="Number of Logins" />
            <DateField source="user.created_at" label="Added" />
          </Tab>
        </TabbedShowLayout>
      </Show>
    </Card>
  );
};

export default withStyles(styles)(FeedbackView);
