import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-admin";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/icons/People";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/Inbox";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
  item: {
    border: "1px solid #911"
  }
};

const tighten = {
  minWidth: "170px",
  overflow: "hidden"
};

const flexy = {
  display: "flex",
  flexDirection: "column"
};

const wrapper = {
  background: "#fafafa",
  marginBottom: "1px",
  borderBottom: "1px solid #ccc"
};

const handleClick = (id, type) => {
  const path = `${window.location.origin}/${type}/${id}/show`;
  window.location.href = path;
};

const Suggestions = ({ type, results }) => {
  if (results !== undefined && Array.isArray(results)) {
    const options = results.map(r => {
      const name = `${r.first_name} ${r.last_name}`;
      let email = `${r.email}`;
      let phone = `${r.phone}`;

      if (
        !r.email
        || r.email === "null"
        || r.email === null
        || r.email === undefined
      ) {
        email = " ";
      }

      if (
        !r.phone
        || r.phone === "null"
        || r.phone === null
        || r.phone === undefined
      ) {
        phone = " ";
      }

      return (
        <div style={wrapper} key={`${r.id}_parent`}>
          <ListItem
            button
            component="a"
            key={r.id}
            onClick={() => handleClick(r.id, type)}
          >
            <div style={flexy}>
              <div style={tighten}>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
              </div>
              <div style={tighten}>
                <ListItemText primary={name} />
              </div>
              <div style={tighten}>
                <ListItemText primary={email} />
              </div>
              <div style={tighten}>
                <ListItemText primary={phone} />
              </div>
            </div>
          </ListItem>
        </div>
      );
    });
    if (results.length > 0) {
      return <List component="nav">{options}</List>;
    }
    return <span />;
  }
  return <span />;
};

Suggestions.propTypes = {
  type: PropTypes.string,
  results: PropTypes.array,
};

export default translate(withStyles(styles)(Suggestions));
