import Card from "@material-ui/core/Card";
import CircularProgress from "@material-ui/core/CircularProgress";
import stopsIcon from "@material-ui/icons/Place";
import stateIcon from "@material-ui/icons/AccountBalance";
import allDistrictsIcon from "@material-ui/icons/Public";
import schoolsIcon from "@material-ui/icons/School";
import localforage from "localforage";
import React, { Component } from "react";
import {
  GET_ONE, Responsive, Show, SimpleShowLayout, TextField
} from "react-admin";
import ReactResizeDetector from "react-resize-detector";
import SecureLS from "secure-ls";

// TODO: use for activity tracking table as icon
// import ActivityStudentsIconWhite from '../icons/ActivityStudentsIconWhite';
import extractProfile from "../analytics_config/extractProfile";
import { pageAnalytic } from "../analytics_config/plugins/setAnalytic";
import Descriptor from "../components/Descriptor";
import Header from "../components/Header";
import HubGoogleMap from "../components/google_maps/HubGoogleMap";
import Spinner from "../components/Spinner";
import SunriseSunsetMarker from "../components/SunriseSunsetMarker";
import config from "../conf/common";
import dataLink from "../dataStores/ddData/dataLink";
import ActivityTrackingTable from "./Layouts/ActivityTrackingTable";

const ls = new SecureLS({ encodingType: "aes" });

const styles = {
  flexSimple: { display: "flex" },
  flex: { display: "flex", paddingLeft: "20px", marginTop: "10px" },
  flexBase: { display: "flex", marginTop: "30px" },
  flexColumn: { display: "flex", flexDirection: "column" },
  flexRow: { display: "flex", direction: "row", marginBottom: "10px" },
  flexDeepRow: { display: "flex", direction: "row", marginBottom: "30px" },
  deepRow: { marginBottom: "30px" },
  shallowRow: { marginBottom: "10px" },
  hiddenRow: { visibility: "hidden" },
  leftCol: { flex: 1, marginRight: "1em" },
  rightCol: { flex: 1, marginLeft: "1em" },
  singleCol: { marginTop: "2em", marginBottom: "2em", padding: "20px" },
  singleColSmall: { marginTop: "2em", marginBottom: "2em", width: "80%" },
  leftColMainThinner: { flex: 1, marginRight: "1em", flexDirection: "column" },
  rightColMainWider: {
    flex: 2,
    marginLeft: "1em",
    flexDirection: "column",
    marginRight: "1em"
  },
  card: {
    overflow: "inherit",
    textAlign: "left",
    padding: 10,
    minHeight: 32,
    marginRight: 5
  },
  cardHeader: {
    color: "rgb(94, 113, 115)",
    paddingRight: "20px",
    width: "100%",
    textAlign: "left",
    fontFamily: '"Roboto Slab", serif',
    fontSize: "1.1rem"
  },
  main: {
    flex: "1",
    marginRight: "1em"
  },
  mapCard: {
    marginTop: "20px",
    marginBottom: "20px",
    background: "#ffffff",
    width: "auto",
    height: "auto",
    paddingTop: "1px",
    paddingBottom: "10px",
    boxShadow:
      "0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)"
  },
  mapCol: {
    height: "400px",
    width: "auto",
    // border: "1px solid #fff",
    margin: "20px"
  },
  pushIn: { marginLeft: "20px" },
  fixHeight: { minHeight: "40px" },
  sunriseSunsetContainer: { marginBottom: 30, padding: 15 }
};

class ActivityStudentsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      singleActivityStudent: null,
      resizing: false
    };

    this.initialiser = this.initialiser.bind(this);

    this.resize = () => {
      const { resizing } = this.state;
      if (!resizing) {
        this.setState({ resizing: true }, () => setTimeout(() => {
          this.setState({ resizing: false });
        }, 1400));
      }
    };
  }

  componentDidMount() {
    let pauseForTokenRefresh = ls.get("pauseForTokenRefresh");
    if (pauseForTokenRefresh === "undefined" || pauseForTokenRefresh === null) {
      pauseForTokenRefresh = 1;
    }
    this.timer = setTimeout(this.initialiser, pauseForTokenRefresh);
    window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    window.removeEventListener("resize", this.resize);
  }

  initialiser() {
    const { id } = this.props;

    dataLink(GET_ONE, "activitystudents", {
      id
    }).then(response => {
      if (response.error !== undefined) {
        if (response.error === "Unauthorized") {
          localforage.getItem("singleActivityStudent").then(value => {
            this.setState({ singleActivityStudent: value });
          });
        }
      } else {
        localforage.setItem("singleActivityStudent", response).then(() => {
          this.setState({ singleActivityStudent: response.data });
        });
      }
    });
  }

  compare(a, b) {
    if (a.time < b.time) return -1;
    if (a.time > b.time) return 1;
    return 0;
  }

  renderSunriseSunset() {
    const { resizing, singleActivityStudent: { school = {} } = {} } = this.state;
    const { lat, lng } = school;

    if (resizing) {
      return (
        <div
          style={{
            display: "flex",
            marginBottom: 20,
            justifyContent: "center"
          }}
        >
          <CircularProgress />
        </div>
      );
    }
    return (
      <Card style={styles.sunriseSunsetContainer}>
        <ReactResizeDetector handleWidth handleHeight>
          {({ width }) => (
            <SunriseSunsetMarker lat={lat} lng={lng} width={width} />
          )}
        </ReactResizeDetector>
      </Card>
    );
  }

  render() {
    const { singleActivityStudent } = this.state;
    if (singleActivityStudent !== null && singleActivityStudent !== undefined) {
      const districtUrl = `districts/${singleActivityStudent.district.id}/show`;
      const schoolUrl = (singleActivityStudent.school ? `schools/${singleActivityStudent.school.id}/show` : '');
      const stateUrl = `states/${singleActivityStudent.state.id}/show`;

      const headerContent = `Activities for ${singleActivityStudent.first_name} ${singleActivityStudent.last_name
        }`;
      const newProfile = extractProfile();
      if (newProfile) {
        pageAnalytic(newProfile, { title: 'Students View' });
      }
      let stopnamedisplay = <div />;
      if (singleActivityStudent.stops[0] !== undefined) {
        stopnamedisplay = (
          <div style={styles.deepRow}>
            <Descriptor
              text="Activity Name"
              color={config.colors.backgroundOrange}
              icon={stopsIcon}
              value={singleActivityStudent.stops[0].name}
            />
          </div>
        );
      }
      let stoptimedisplay = <div />;
      if (singleActivityStudent.stops[0] !== undefined) {
        stoptimedisplay = (
          <div style={styles.deepRow}>
            <Descriptor
              text="Activity Time"
              color={config.colors.backgroundOrange}
              icon={stopsIcon}
              value={singleActivityStudent.stops[0].time_zone}
            />
          </div>
        );
      }

      const routesObj = singleActivityStudent.activity_routes;
      routesObj.sort(this.compare);

      return (
        <Responsive
          small={
            <div style={styles.singleColSmall}>
              <div style={styles.flexColumn}>
                <div style={styles.deepRow}>
                  <Header
                    content={`${singleActivityStudent.first_name} ${singleActivityStudent.last_name
                      }`}
                    orientation="left"
                  />
                </div>

                <div style={styles.deepRow}>
                  <Descriptor
                    text="State"
                    color={config.colors.backgroundOrange}
                    icon={stateIcon}
                    value={singleActivityStudent.state.name}
                    link={stateUrl}
                  />
                </div>

                <div style={styles.deepRow}>
                  <Descriptor
                    text="District"
                    color={config.colors.backgroundOrange}
                    icon={allDistrictsIcon}
                    value={singleActivityStudent.district.name}
                    link={districtUrl}
                  />
                </div>
                {
                  singleActivityStudent.school
                    ? <div style={styles.deepRow}>
                      <Descriptor
                        text="School"
                        color={config.colors.backgroundOrange}
                        icon={schoolsIcon}
                        value={singleActivityStudent.school.name}
                        link={schoolUrl}
                      />
                    </div>
                    : null
                }
                <Show style={styles.deepRow} title="Student Details" {...this.props}>
                  <SimpleShowLayout>
                    <h2 style={styles.cardHeader}>Student Details</h2>
                    <TextField source="student_number" label="Student Number" />
                  </SimpleShowLayout>
                </Show>

                {stopnamedisplay}
                {stoptimedisplay}
                <div style={styles.deepRow}>
                  <div style={styles.mapCard}>
                    <Header content={headerContent} orientation="left" />
                    <div style={styles.mapCol}>
                      <HubGoogleMap zoom={13} students={singleActivityStudent} />
                    </div>
                  </div>
                </div>
                <div style={styles.deepRow}>
                  <ActivityTrackingTable data={routesObj} />
                </div>
              </div>
            </div>
          }
          medium={
            <div>
              <div style={{ marginRight: "1em" }}>
                <Header
                  content={`${singleActivityStudent.first_name} ${singleActivityStudent.last_name
                    }`}
                  orientation="left"
                />
                <div style={styles.mapCard}>
                  <Header content={headerContent} orientation="left" />
                  <div style={{ ...styles.mapCol, height: 300 }}>
                    <HubGoogleMap
                      zoom={13}
                      students={singleActivityStudent}
                      height={300}
                    />
                  </div>
                </div>
                {singleActivityStudent.school ? this.renderSunriseSunset() : null}
              </div>
              <div>
                <div style={styles.leftColMainThinner}>
                  <div style={styles.deepRow}>
                    <Descriptor
                      text="State"
                      color={config.colors.backgroundSeaLight}
                      icon={stateIcon}
                      value={singleActivityStudent.state.name}
                      link={stateUrl}
                    />
                  </div>

                  <div style={styles.deepRow}>
                    <Descriptor
                      text="District"
                      color={config.colors.backgroundBlue}
                      icon={allDistrictsIcon}
                      value={singleActivityStudent.district.name}
                      link={districtUrl}
                    />
                  </div>
                  {
                    singleActivityStudent.school
                      ? <div style={styles.deepRow}>
                        <Descriptor
                          text="School"
                          color={config.colors.backgroundOrange}
                          icon={schoolsIcon}
                          value={singleActivityStudent.school.name}
                          link={schoolUrl}
                        />
                      </div>
                      : null
                  }
                  <Show style={styles.deepRow} title="Student Details" {...this.props}>
                    <SimpleShowLayout>
                      <h2 style={styles.cardHeader}>Student Details</h2>
                      <TextField source="student_number" label="Student Number" />
                    </SimpleShowLayout>
                  </Show>
                </div>
                <div style={styles.leftColMainThinner}>
                  <div style={styles.flexColumn}>
                    <div style={styles.shallowRow}>
                      <ActivityTrackingTable data={routesObj} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          large={
            <div>
              <div style={styles.flex}>
                <div style={styles.leftCol}>
                  <Header
                    content={`${singleActivityStudent.first_name} ${singleActivityStudent.last_name
                      }`}
                    orientation="left"
                  />
                </div>
              </div>

              <div style={styles.flex}>
                <div style={styles.leftColMainThinner}>
                  <div style={styles.flexRow}>
                    <Descriptor
                      text="State"
                      color={config.colors.backgroundSeaLight}
                      icon={stateIcon}
                      value={singleActivityStudent.state.name}
                      link={stateUrl}
                    />
                  </div>

                  <div style={styles.flexDeepRow}>
                    <Descriptor
                      text="District"
                      color={config.colors.backgroundBlue}
                      icon={allDistrictsIcon}
                      value={singleActivityStudent.district.name}
                      link={districtUrl}
                    />
                  </div>
                  {
                    singleActivityStudent.school
                      ? <div style={styles.flexDeepRow}>
                        <Descriptor
                          text="School"
                          color={config.colors.backgroundOrange}
                          icon={schoolsIcon}
                          value={singleActivityStudent.school.name}
                          link={schoolUrl}
                        />
                      </div>
                      : null
                  }
                  <Show style={styles.deepRow} title="Student Details" {...this.props}>
                    <SimpleShowLayout>
                      <h2 style={styles.cardHeader}>Student Details</h2>
                      <TextField source="student_number" label="Student Number" />
                    </SimpleShowLayout>
                  </Show>
                </div>
                <div style={styles.rightColMainWider}>
                  <div style={styles.flexColumn}>
                    <div style={styles.shallowRow}>
                      <div style={styles.mapCard}>
                        <Header content={headerContent} orientation="left" />
                        <div style={styles.mapCol}>
                          <HubGoogleMap zoom={13} students={singleActivityStudent} />
                        </div>
                      </div>
                    </div>
                    {singleActivityStudent.school ? this.renderSunriseSunset() : null}
                    <div style={styles.shallowRow}>
                      <ActivityTrackingTable data={routesObj} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      );
    }
    return <Spinner />;
  }
}

export default ActivityStudentsView;
