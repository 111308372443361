module.exports = {
  api: {
    urlPrefix: '/api/v1',
    // baseUrl: 'https://staging.firstviewbackend.com'
    // baseUrl: "https://firstviewbackend.com"
  },
  app: {
    routes: {
      login: '/login'
    },
    redux: {
      persistWhitelist: ['authPersistent']
    },
    rememberMeKey: 'rememberMe'
  },
  proxy: false
};
