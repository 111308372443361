import Card from "@material-ui/core/Card";
import withStyles from "@material-ui/core/styles/withStyles";
import allDistrictsIcon from "@material-ui/icons/Public";
import get from "lodash/get";
import React from "react";
import {
  Datagrid, Filter, List, SelectInput, TextField, TextInput, useRecordContext
} from "react-admin";
import SecureLS from "secure-ls";

import extractProfile from "../analytics_config/extractProfile";
import { pageAnalytic } from "../analytics_config/plugins/setAnalytic";
import defaultDistrictLogo from "../bus-icon-material.png";
import AuthenticationError from "../components/AuthenticationError";
import CardIcon from "../components/CardIcon";
import Header from "../components/Header";
import config from "../conf/common";
import DistrictTypeField from "./utilities/DistrictTypeField";
import TimeZoneField, { formatTimeZone } from "./utilities/TimeZoneField";
import ListActions from "../components/ListActions";
import DynamicFilterField from "./utilities/DynamicFilterField";

const ls = new SecureLS({ encodingType: "aes" });

const logoStyle = { height: "3.7em", margin: "0.25em 1em 0.125em 0" };

const styles = () => ({
  rowEven: {
    backgroundColor: "#F8F8F8"
  },
  table: {
    minWidth: 500
  },
  card: {
    overflow: "inherit",
    textAlign: "left",
    padding: 16,
    minHeight: 52,
    marginRight: '1em'
  },
  largeFont: {
    fontSize: "20px"
  },
  logoAndNameContainer: { display: "flex", alignItems: "center" },
  logo: logoStyle,
  fadedLogo: { ...logoStyle, opacity: "0.5" },
  districtNameHeader: {
    paddingLeft: "5.1em"
  }
});

const DistrictFilter = props => (
  <Filter {...props}>
    <TextInput label="Search District" source="name" defaultValue="" alwayson="true" />
    <SelectInput
      style={{ width: 195 }}
      label="Filter By District Type"
      source="settings.district_type"
      choices={[
        { id: "student", name: "Student" },
        { id: "streetz", name: "Street" },
        { id: "gps_student", name: "GPS Student" },
        { id: "gps_district_code", name: "GPS District Code" },
        { id: "gps_dashboard", name: "GPS Dashboard" },
        { id: "district_dashboard_only", name: "District Dashboard Only" }
      ]}
      alwayson="true"
    />
    <DynamicFilterField
      style={{ width: 185 }}
      label="Filter By Time Zone"
      entity="districts" source="time_zone" valueExtractor={value => ({ id: value, name: formatTimeZone(value) })} alwayson />
  </Filter>
);

const NameWithLogoField = withStyles(styles)(({ classes, record }) => {

  let districtLogo = get(record, "settings.logo");
  if (
    !districtLogo
    || districtLogo === "defaults/logos/default_district_logo.png"
  ) {
    districtLogo = defaultDistrictLogo;
  }
  return (
    <span className={classes.logoAndNameContainer}>
      <img src={districtLogo} className={districtLogo === defaultDistrictLogo ? classes.fadedLogo : classes.logo} alt={`${record?.name} Logo`} />
      <span className={classes.largeFont}>{record?.name ?? ''}</span>
    </span>
  );
});

const ActivityTrackingField = () => {
  const record = useRecordContext();
  const isEnabled = record?.settings?.activity_tracking_enabled
    ? 'Yes'
    : 'No';
  return (<span>{isEnabled}</span>);
};

const AllDistrictList = withStyles(styles)(({ classes, ...props }) => {
  const headerContent = "Districts";

  const newProfile = extractProfile();
  if (newProfile) {
    pageAnalytic(newProfile, { title: 'Districts Listing' });
  }

  const authenticated = ls.get("authenticated");

  if (authenticated === "yes") {
    return (
      <React.Fragment>
        <Card className={classes.card}>
          <CardIcon
            Icon={allDistrictsIcon}
            bgColor={config.colors.backgroundBlue}
            orientation="left"
          />
          <Header content={headerContent} orientation="left" />
          <List
            title="Districts"
            {...props}
            bulkActionButtons={false}
            filters={<DistrictFilter />}
            actions={<ListActions type="districts" />}
          >
            <Datagrid rowClick="show" classes={{ rowEven: classes.rowEven }}>
              <NameWithLogoField source="name" label={<span className={classes.districtNameHeader}>District Name</span>} />
              <DistrictTypeField sortable={false} />
              <ActivityTrackingField label="Activity Tracking" />
              <TimeZoneField sortable={false} />
              <TextField source="state.name" label="State" />
              <TextField source="students_count" label="Student Count" />
            </Datagrid>
          </List>
        </Card>
      </React.Fragment>
    );
  }
  return <AuthenticationError />;
});

export default AllDistrictList;
