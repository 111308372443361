import {
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY
} from 'react-admin';
import localforage from 'localforage';
import api from '../../dd_data_module/services/api';


const getStats = () => api.getStats().then(response => new Promise((resolve => {
  const code = response.status;
  if (code === 403) {
    resolve("Not authenticated");
  } else if (code !== 200) {
    localforage.getItem('statsdatabundle').then(databundle => {
      resolve(databundle);
    });
  } else {
    const stats = response.data;
    const databundle = {data: stats};
    localforage.setItem('statsdatabundle', databundle).then(db => {
      resolve(db);
    });
  }
})));


const getStatesResponse = (type, resource, params) => {
  switch (type) {
    case GET_ONE:
      return getStats(params);
    case GET_MANY:
      return {
        data: null
      };
    case GET_MANY_REFERENCE:
      return {
        data: null
      };
    case CREATE:
      return {
        data: null
      };
    case UPDATE:
      return {
        data: null
      };
    case UPDATE_MANY:
      return {
        data: null
      };
    case DELETE:
      return {
        data: null
      };
    case DELETE_MANY:
      return {
        data: null
      };
    default:
      return false;
  }
};

export default getStatesResponse;
