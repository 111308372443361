import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import Header from '../components/Header';
import api from '../dd_data_module/services/api';
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { translate } from 'react-admin'
import { connect } from "react-redux";

const sendNotificationInputValues = {
    subject: '',
    message: '',
}

const sendNotificationErrorInitialState = {
    subject: null,
    message: null,
    response: null,
}

const maxLength = {
    subject: 100,
    message: 256
}

const SendTestNotification = (props) => {

    const { userId, translate: transl } = props;

    const [isSendingNotification, setIsSendingNotification] = useState(false);
    const [inputValues, setInputValues] = useState(sendNotificationInputValues);
    const [success, setSuccess] = useState(false);
    const [errorMessages, setErrorMessages] = useState(sendNotificationErrorInitialState);

    const validateInput = (field) => {
        return !inputValues[field].trim() ? transl(`resources.notifications.${field}.blank`) : null;
    }

    const sendNotification = () => {
        const subjectError = validateInput('subject');
        const messageError = validateInput('message');
        if (subjectError || messageError) {
            return setErrorMessages({
                subject: subjectError,
                message: messageError,
                response: null
            })
        }
        const { subject, message } = inputValues;
        setIsSendingNotification(true);
        api.sendUserTestNotification({
            userId,
            subject,
            message,
        }).then((response) => {
            setIsSendingNotification(false);
            if (response?.status === 200) {
                setInputValues(sendNotificationInputValues);
                setSuccess(true);
            } else {
                setErrorMessages(prevState => ({
                    ...prevState,
                    response: response?.data?.message ?? transl('resources.notifications.error'),
                }));
            }
        })
    }

    const fieldOnChange = (e, field) => {
        if (errorMessages[field]) {
            setErrorMessages(sendNotificationErrorInitialState)
        }
        const value = e.target?.value;
        setInputValues(prevState => ({
            ...prevState,
            [field]: value,
        }))
    }

    const backgroundColor = !inputValues.subject || !inputValues.message ? 'rgba(0, 0, 0, 0.12)' : '#2c387e';

    return (
        <div>
            <Header
                content={transl('resources.notifications.header.content')}
                orientation="left"
                pushleft="0px"
                pushdown="0px"
            />
            <FormGroup>
                {Object.keys(sendNotificationInputValues).map(key =>
                    <TextField
                        key={key}
                        multiline={key === 'message'}
                        rows={10}
                        error={errorMessages[key]}
                        value={inputValues[key]}
                        variant='outlined'
                        required={true}
                        id={key}
                        label={transl(`resources.notifications.${key}.label`)}
                        type="text"
                        margin="normal"
                        placeholder={transl(`resources.notifications.${key}.placeholder`)}
                        fullWidth={true}
                        onChange={(e) => fieldOnChange(e, key)}
                        inputProps={{ maxLength: maxLength[key] }}
                        helperText={`${inputValues[key].length} / ${maxLength[key]} ${errorMessages[key] ?? ''}`}
                    />)}

                {!isSendingNotification ?
                    <div style={{
                        width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 16
                    }}>
                        <Button
                            onClick={() => sendNotification()}
                            disabled={!inputValues.subject || !inputValues.message}
                            variant="text"
                            style={{
                                width: 200, display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center', color: '#fff', backgroundColor: backgroundColor
                            }}
                        >
                            Send
                        </Button>
                    </div>
                    :
                    <div>
                        <CircularProgress
                            color="primary"
                            size={20}
                            thickness={6}
                        />
                    </div>
                }

            </FormGroup >
            <Snackbar
                open={success || errorMessages.response}
                onClose={() => {
                    setErrorMessages(sendNotificationErrorInitialState)
                    setSuccess(false)
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                autoHideDuration={2000}
            >
                <SnackbarContent style={{
                    backgroundColor: errorMessages.response ? '#B33A3A' : null,
                }}
                    message={<span id="message-id">{success ? transl('resources.notifications.sent') : errorMessages?.response}</span>}
                />
            </Snackbar>
        </div >
    )
}

const translatedComponent = translate(SendTestNotification)
export default connect()(translatedComponent);
