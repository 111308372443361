import {
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from 'react-admin';
import api from '../../dd_data_module/services/api';

const getDistrictBanner = districtId => api.getDistrictBanner(districtId).then(response => new Promise((resolve => {
  const code = response.status;
  if (code === 403) {
    resolve('Not authenticated');
  } else if (code === 200) {
    const districtBanner = response.data;
    const dataBundle = { data: districtBanner };
    resolve(dataBundle);
  }
})));

const updateDistrictBanner = (districtId, params) => api.updateDistrictBanner(districtId, params).then(response => new Promise((resolve => {
  const code = response.status;
  if (code === 403) {
    resolve('Not authenticated');
  } else if (code === 200) {
    const districtBanner = response.data;
    const dataBundle = { data: districtBanner };
    resolve(dataBundle);
  } else {
    reject(response)
  }
})));

const getDistrictBannersResponse = (type, resource, params, districtId) => {
  switch (type) {
    case GET_ONE:
      return getDistrictBanner(districtId);
    case GET_MANY:
      return {
        data: null,
      };
    case GET_MANY_REFERENCE:
      return {
        data: null,
      };
    case CREATE:
      return {
        data: null,
      };
    case UPDATE:
      return updateDistrictBanner(districtId, params);
    case UPDATE_MANY:
      return {
        data: null,
      };
    case DELETE:
      return {
        data: null,
      };
    case DELETE_MANY:
      return {
        data: null,
      };
    default:
      return false;
  }
};

export default getDistrictBannersResponse;
