import {
  GET_LIST, // CURRENTLY DEFAULTING TO THIS
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY
} from 'react-admin';
import localforage from 'localforage';
import api from '../../dd_data_module/services/api';
import { bundle, sortResponse } from './commonProviderFunctions';

const getSchoolsList = (type, resource, params, district) => {
  if (district === undefined) {
    district = 12;
  }

  const { field, order } = params.sort;
  let direction = 'ascend';
  if (order === 'DESC') {
    direction = 'descend';
  }
  if (field === 'id') {
    direction = 'ascend';
  }

  const { perPage } = params.pagination;
  let { page } = params.pagination;

  if (params.pagination.adjust !== undefined && page > 0) {
    page += 1;
  }

  return api
    .getSchools(district,
      page,
      perPage,
      { columnKey: field, order: direction },
      params.filter,
      false)
    .then(response => new Promise((resolve => {
      const code = response.status;
      // const code = response.data.response.code;
      // const message = response.data.message;
      if (code === 403) {
        resolve("Not authenticated");
      } else if (code !== 200) {
        localforage.getItem('schoolsdatabundle').then(databundle => {
          const { datastream, count } = databundle;
          // Pick up the new params
          const repackagedBundle = bundle(datastream, type, resource, params, count);
          const thisPromise = sortResponse(repackagedBundle);
          thisPromise.then(res => {
            resolve(res);
          });
        });
      } else {
        const schools = response.data.result;
        const count = response.data.info.total_count;
        const databundle = bundle(schools, type, resource, params, count);
        localforage.setItem('schoolsdatabundle', databundle).then(db => {
          const thisPromise = sortResponse(db);
          thisPromise.then(res => {
            resolve(res);
          });
        });
      }
    })));
};

// Needs an API call to all districts
const getSchoolsAll = district => api.getSchools(district, 1, 50, {}, {}, false)
  .then(response => {
    if (response.data === null) {
      return {
        data: [],
        total: 0,
        error: 'Response/CORS failure'
      };
    }
    const code = response.status;
    const {error} = response;
    // const code = response.data.response.code;
    // const message = response.data.message;
    if (code !== 200) {
      return {
        data: [],
        total: 0,
        error
      };
    }
    const schools = response.data.result;
    const total = schools.length;
    return {
      data: schools,
      total
    };
  });
const getSchoolsOne = params => api.getSchool(
  { schoolId: params.id }
).then(response => new Promise((resolve => {
  const {message} = response.data;
  const {code} = response.data.response;
  if (message !== 'Success' || code !== 200) {
    localforage.getItem('schoolsdatabundle').then(databundle => {
      resolve(databundle);
    });
  } else {
    const school = response.data.result;
    const databundle = { data: school };
    localforage.setItem('schoolsdatabundle', databundle).then(db => {
      resolve(db);
    });
  }
})));

const getSchoolsResponse = (type, resource, params, district) => {
  switch (type) {
    case GET_LIST: {
      return getSchoolsList(type, resource, params, district);
    }
    case GET_ONE:
      return getSchoolsOne(params);
    case GET_MANY:
      return getSchoolsAll(district);
    case GET_MANY_REFERENCE:
      return {
        data: null
      };
    case CREATE:
      return {
        data: null
      };
    case UPDATE:
      return {
        data: null
      };
    case UPDATE_MANY:
      return {
        data: null
      };
    case DELETE:
      return {
        data: null
      };
    case DELETE_MANY:
      return {
        data: null
      };
    default:
      return false;
  }
};

export default getSchoolsResponse;
