import React from "react";
import { FunctionField } from "react-admin";

const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

const formatPlatformString = string => {
  const response = capitalizeFirstLetter(string);
  return response === "Ios" ? "iOS" : response;
};

const PlatformLinkField = props => (
  <FunctionField
    {...props}
    render={record => (record.platform ? formatPlatformString(record.platform) : "")
    }
  />
);

PlatformLinkField.defaultProps = {
  source: "platform",
  addLabel: true
};

export default PlatformLinkField;
