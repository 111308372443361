import React, { cloneElement, Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = () => ({
  root: {
    color: '#383939',
    display: 'flex',
    alignItems: 'flex-start',
  },
  active: {
    color: '#869EB2',
  },
  icon: { paddingRight: '1.2em' },
});

const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

export class MyMenuItemLink extends Component {
  static propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
    className: PropTypes.string,
    leftIcon: PropTypes.node,
    onClick: PropTypes.func,
    primaryText: PropTypes.string,
    staticContext: PropTypes.object,
    to: PropTypes.string.isRequired,
  };

  handleMenuTap = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render() {
    const {
      classes,
      className,
      primaryText,
      leftIcon,
      staticContext,
      ...props
    } = this.props;

    const title = capitalizeFirstLetter(primaryText);

    const linkCode = <MenuItem
      className={classnames(classes.root, className)}
      activeClassName={classes.active}
      component={NavLink}
      {...props}
      onClick={this.handleMenuTap}
    >
      {leftIcon && (
        <span className={classes.icon}>
          {cloneElement(leftIcon, { titleAccess: primaryText })}
        </span>
      )}
      {title}
    </MenuItem>;

    return (
      linkCode
    );
  }
}

export default withStyles(styles)(MyMenuItemLink);
