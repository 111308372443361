import React from "react";
import PropTypes from "prop-types";
import StopsIcon from "@material-ui/icons/Place";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardIcon from "../../components/CardIcon";
import config from "../../conf/common";
import Header from "../../components/Header";
import Spinner from "../../components/Spinner";
import CopyField from "../../components/CopyField";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  table: {
    minWidth: 500
  },
  tableWrapper: {
    overflowX: "auto"
  },
  allPadded: {
    padding: "10px 10px 10px 0",
    color: "#343434",
    textAlign: "left",
    fontSize: "0.85em"
  },
  main: {
    flex: "1",
    marginRight: "1em",
    marginTop: 20
  },
  card: {
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
    minHeight: 52,
    marginBottom: "25px"
  },
  inCard: {
    textAlign: "left"
  },
  inPut: {
    border: "none",
    borderBottom: "1px solid #ccc",
    fontSize: "1.1em",
    marginBottom: "10px"
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#F8F8F8"
    }
  },
  fieldWithCopyButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  copyableValueHeader: { marginLeft: "3rem" }
});

const CustomLeftHeaderCell = withStyles({
  head: {
    backgroundColor: "#fff",
    color: "#5E7173",
    fontWeight: "400",
    fontSize: ".9em",
    textAlign: "left",
    borderTop: "1px solid #eee"
  },
  body: {
    textAlign: "left"
  }
})(TableCell);

class RoutesPaginationTable extends React.Component {
  state = {
    data: []
  };

  componentDidMount() {
    const { routes: data } = this.props;
    this.setState({ data });
  }

  getFormattedTime(time, dispatchType) {
    if(!time){
      return 'unknown';
    }
    let times = time.split("T");
    times = (times[1] || "").split(":");
    const twelveHourFormatWithLeadingZero = `00${times[0] % 12}`.slice(-2);
    return `${twelveHourFormatWithLeadingZero}:${times[1]} ${dispatchType}`;
  }

  getKeyFromTime(time, index) {
    if(!time){
      return index;
    }
    let times = time.split("T");
    times = (times[1] || "").split(":");
    return `${times[0]}:${times[1]};${times[2]}`;
  }

  render() {
    const { classes } = this.props;
    const { data } = this.state;
    const headerContent = `Routes`;
    let resultsRender = <Spinner />;
    if (data.length === 0) {
      resultsRender = <div className={classes.allPadded}>No results found</div>;
    } else {
      resultsRender = (
        <Paper>
          <div className={classes.tableWrapper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <CustomLeftHeaderCell>Time</CustomLeftHeaderCell>
                  <CustomLeftHeaderCell>Dispatch Type</CustomLeftHeaderCell>
                  <CustomLeftHeaderCell>Run Code</CustomLeftHeaderCell>
                  <CustomLeftHeaderCell>
                    <div className={classes.copyableValueHeader}>
                      Route Name
                    </div>
                  </CustomLeftHeaderCell>
                  <CustomLeftHeaderCell>Stop Name</CustomLeftHeaderCell>
                  <CustomLeftHeaderCell>Time Zone</CustomLeftHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((route, i) => (
                  <TableRow
                    className={classes.row}
                    key={this.getKeyFromTime(route.time, i)}
                  >
                    <CustomLeftHeaderCell>
                      {this.getFormattedTime(route.time, route.dispatch_type)}
                    </CustomLeftHeaderCell>
                    <CustomLeftHeaderCell>
                      {route.dispatch_type}
                    </CustomLeftHeaderCell>
                    <CustomLeftHeaderCell>
                      {route.run_code}
                    </CustomLeftHeaderCell>
                    <CustomLeftHeaderCell component="th" scope="row">
                      <span className={classes.fieldWithCopyButton}>
                        <CopyField value={route.route} />
                        {route.route}
                      </span>
                    </CustomLeftHeaderCell>
                    <CustomLeftHeaderCell component="th" scope="row">
                      {(route.stop || {}).name || ''}
                    </CustomLeftHeaderCell>
                    <CustomLeftHeaderCell>
                      {(route.stop || {}).time_zone || ''}
                    </CustomLeftHeaderCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter />
            </Table>
          </div>
        </Paper>
      );
    }
    return (
      <div>
        <CardIcon Icon={StopsIcon} bgColor={config.colors.backgroundSeaLight} />
        <Card className={classes.card}>
          <Header content={headerContent} orientation="right" />
          {resultsRender}
        </Card>
      </div>
    );
  }
}

RoutesPaginationTable.defaultProps = {
  classes: {},
  routes: []
};

RoutesPaginationTable.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  routes: PropTypes.arrayOf(PropTypes.instanceOf(Object))
};

export default withStyles(styles)(RoutesPaginationTable);
