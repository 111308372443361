import Card from "@material-ui/core/Card";
import RidershipIcon from '../icons/RidershipIcon';
import React, {Component} from "react";
import {
  GET_ONE, Show, TextField, SimpleShowLayout
} from "react-admin";
import Spinner from "../components/Spinner";
import localforage from "localforage";
import extractProfile from "../analytics_config/extractProfile";
import { pageAnalytic } from "../analytics_config/plugins/setAnalytic";
import CardIcon from "../components/CardIconTwo";
import Header from "../components/Header";
import config from "../conf/common";
import dataLink from "../dataStores/ddData/dataLink";
import {flattenObject} from '../dd_data_module/utils/apiUtils'

const styles = () => ({
  card: {
    overflow: "inherit",
    textAlign: "left",
    padding: 16,
    minHeight: 52,
    marginRight: "1em"
  }
});

class RidershipView extends Component {
  constructor(props){
    super(props);
    this.state = {
      event: null
    };
    this.initialiser = this.initialiser.bind(this);
  }

  componentDidMount() {
    this.initialiser();
  }

  initialiser() {
    const {id} = this.props;

    dataLink(GET_ONE, "ridership", {
      id
    }).then(response => {
      if (response.error !== undefined) {
        if (response.error === "Unauthorized") {
          localforage.getItem("ridershipEvent").then(value => {
            this.setState({ event: value });
          });
        }
      } else {
        localforage.setItem("ridershipEvent", response).then(() => {
          this.setState({ event: response.data });
        });
      }
    });
  }

  render() {
    const headerContent = "Ridership Event";    
    const { event = {} } = this.state;
    

    if( event !== null){
      const flatEvent = flattenObject(event);
      const newProfile = extractProfile();
      if (newProfile) {
        pageAnalytic(newProfile, { title: 'Ridership View' });
      }
      return (
        <Card className={styles.card}>
          <CardIcon
            Icon={RidershipIcon}
            bgColor={config.colors.backgroundSeaLight}
          />
          <Header content={headerContent} orientation="left" />
          <Show title="Ridership Event Details" {...this.props}>
            <SimpleShowLayout>
                {
                  Object.keys(flatEvent).map(key =>{
                    return <TextField key={key} source={key} label={key} />
                  })
                }
            </SimpleShowLayout>
          </Show>
        </Card>
      );
    }
    return <Spinner />
  }
};

export default RidershipView;
