import Card from '@material-ui/core/Card';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import { Link, translate } from 'react-admin';

import CardIcon from './CardIcon';
import CopyField from './CopyField';

const DescriptorCard = ({
  onMouseEnter,
  onMouseLeave,
  cardClassName,
  titleClassName,
  text,
  value,
  district,
  copyableText,
  link
}) => (
  <Card
    {...{ onMouseEnter, onMouseLeave, className: cardClassName }} style={link ? {
      boxShadow: [
        '0px 3px 9px 0px rgba(0, 0, 0, 0.1)',
        '0px 3px 3px 0px rgba(0, 0, 0, 0.1)',
        '0px 6px 3px -3px rgba(0, 0, 0, 0.1)'
      ]
    } : null}>
    <Typography className={titleClassName} color="textSecondary">
      {text}
    </Typography>
    <Typography variant="h5">
      {value}
    </Typography>
    {copyableText && (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end"
        }}>
        <Typography variant="subheading" component="p" style={{ opacity: 0.7 }}>
          {copyableText}
        </Typography>
        <CopyField value={copyableText}/>
      </div>
    )}
    {district && (
      <Typography variant="h5">
        {district}
      </Typography>
    )}
  </Card>
);

const cardBackgroundColor = "#f1f1f1";
const commonCardStyles = {
  overflow: "inherit",
  textAlign: "right",
  padding: 16,
  minHeight: 52,
  minWidth: "300px"
};

const styles = {
  main: {
    flex: "1",
    marginTop: 20
  },
  cardOff: {
    ...commonCardStyles,
    border: `1px solid ${cardBackgroundColor}`
  },
  cardOn: {
    ...commonCardStyles,
    border: "1px dotted #001E61",
    background: cardBackgroundColor
  },
  cardOffRight: {
    ...commonCardStyles,
    border: `1px solid ${cardBackgroundColor}`,
    background: cardBackgroundColor,
    marginRight: "20px"
  },
  cardOnRight: {
    ...commonCardStyles,
    border: "1px dotted #001E61",
    background: cardBackgroundColor,
    marginRight: "20px"
  },
  cardNormal: {
    ...commonCardStyles
  },
  cardRight: {
    ...commonCardStyles,
    marginRight: "20px"
  }
};
class Descriptor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false
    };

    this.toggleHover = this.toggleHover.bind(this);
  }

  static getInner(props) {
    if (props.link) {
      return (
        <Link to={`/${props.link}`}>
          <DescriptorCard {...props} />
        </Link>
      );
    }
    return <DescriptorCard {...props} />;
  }

  toggleHover() {
    const { hover } = this.state;
    this.setState({ hover: !hover });
  }

  render() {
    const {
      text,
      color,
      icon,
      value,
      classes,
      link,
      marginRight,
      district,
      copyableText
    } = this.props;
    const { hover } = this.state;

    let cardClassName = classes.cardNormal;
    if (link) {
      cardClassName = marginRight
        ? classes.cardOffRight
        : classes.cardOff;
      if (hover) {
        cardClassName = marginRight
          ? classes.cardOnRight
          : classes.cardOn;
      }
    } else if (marginRight) {
      cardClassName = classes.cardRight;
    }

    const cardProps = {
      link,
      onMouseEnter: this.toggleHover,
      onMouseLeave: this.toggleHover,
      cardClassName,
      titleClassName: classes.title,
      text,
      value,
      district,
      copyableText
    };

    return (
      <div className={classes.main}>
        <CardIcon Icon={icon} bgColor={color} />
        {this.constructor.getInner(cardProps)}
      </div>
    );
  }
}

export default translate(withStyles(styles)(Descriptor));
