import React from "react";
import PropTypes from "prop-types";
import { GET_LIST } from "react-admin";
import localforage from "localforage";

import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import StudentCardIcon from "@material-ui/icons/LocalLibrary";
import dataLink from "../../dataStores/ddData/dataLink";
import CardIcon from "../../components/CardIcon";
import config from "../../conf/common";
import Pagination from "../Utilities/Pagination";
import Spinner from "../../components/Spinner";

import Header from "../../components/Header";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  table: {
    minWidth: 500
  },
  tableWrapper: {
    overflowX: "auto"
  },
  allPadded: {
    padding: "10px 10px 10px 0",
    color: "#343434",
    textAlign: "left",
    fontSize: "0.85em"
  },
  main: {
    flex: "1",
    marginRight: "1em",
    marginTop: 20
  },
  card: {
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
    minHeight: 52
  },
  inCard: {
    textAlign: "left"
  },
  inPut: {
    border: "none",
    borderBottom: "1px solid #ccc",
    fontSize: "1.1em",
    marginBottom: "10px"
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#F8F8F8"
    }
  }
});

const CustomLeftHeaderCell = withStyles({
  head: {
    backgroundColor: "#fff",
    color: "#5E7173",
    fontWeight: "400",
    fontSize: ".9em",
    textAlign: "left",
    borderTop: "1px solid #eee"
  },
  body: {
    textAlign: "left"
  }
})(TableCell);

const CustomRightHeaderCell = withStyles({
  head: {
    backgroundColor: "#fff",
    color: "#5E7173",
    fontWeight: "400",
    fontSize: ".9em",
    textAlign: "right",
    borderTop: "1px solid #eee"
  },
  body: {
    textAlign: "right"
  }
})(TableCell);

class StudentPaginationTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      page: 0,
      rowsPerPage: 5,
      total: 0,
      id: 0,
      query: "",
      searching: false
    };

    this.getInfo = this.getInfo.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    const { id } = this.props;
    this.setState({ id });
    this.getInfo(id, false);
  }

  getInfo(id, clear) {
    const { page, rowsPerPage, query } = this.state;

    let filter = { school_id: id, full_name: query };
    if (clear) {
      filter = { school_id: id, full_name: "" };
      this.setState({ page: 0 });
    }
    dataLink(GET_LIST, "students", {
      pagination: {
        page,
        perPage: rowsPerPage,
        adjust: "yes"
      },
      sort: { field: "id", direction: "ASC" },
      filter,
      district: "paginate_adjust",
      student: id
    }).then(response => {
      if (response.error !== undefined) {
        if (response.error === "Unauthorized") {
          localforage.getItem("DistrictStudentsCount").then(value => {
            this.setState({ rows: value.data });
            this.setState({ total: value.total });
            this.setState({ searching: false });
          });
        }
      } else if (response.total === undefined) {
        localforage.getItem("DistrictStudentsCount").then(value => {
          this.setState({ rows: value.data });
          this.setState({ total: value.total });
          this.setState({ searching: false });
        });
      } else {
        localforage.setItem("DistrictStudentsCount", response).then(() => {
          this.setState({ rows: response.data });
          this.setState({ total: response.total });
          this.setState({ searching: false });
        });
      }
    });
  }

  handleSubmit(event) {
    const { id } = this.state;
    event.preventDefault();
    this.setState({
      searching: true,
      page: 0
    });
    this.getInfo(id, false);
  }

  handleChangePage(event, page) {
    const { query, rowsPerPage, id } = this.state;
    this.setState({ page });
    const block = "";
    const names = query.split(" ");
    let first = query;
    let second = block;
    if (names.length > 1) {
      [first, second] = names;
    }
    if (first.length === 0) {
      first = block;
    }

    if (second.length === 0) {
      second = block;
    }
    dataLink(GET_LIST, "students", {
      pagination: {
        page,
        perPage: rowsPerPage,
        adjust: "yes"
      },
      sort: { field: "id", direction: "ASC" },
      filter: {
        school_id: id,
        first_name: first,
        last_name: second
      },
      district: "paginate_adjust",
      student: id
    }).then(response => {
      this.setState({ rows: response.data });
      this.setState({ total: response.total });
    });
  }

  handleChangeRowsPerPage(event) {
    const { id, page, query } = this.state;
    this.setState({ rowsPerPage: event.target.value });
    const block = "";
    const names = query.split(" ");
    let first = query;
    let second = block;
    if (names.length > 1) {
      [first, second] = names;
    }
    if (first.length === 0) {
      first = block;
    }

    if (second.length === 0) {
      second = block;
    }
    dataLink(GET_LIST, "students", {
      pagination: {
        page,
        perPage: event.target.value,
        adjust: "yes"
      },
      sort: { field: "id", direction: "ASC" },
      filter: {
        school_id: id,
        first_name: first,
        last_name: second
      },
      district: "paginate_adjust",
      student: id
    }).then(response => {
      this.setState({ rows: response.data });
      this.setState({ total: response.total });
    });
  }

  handleInputChange(event) {
    const { id } = this.state;
    this.setState({ query: event.target.value });
    this.setState({ page: 0 });
    if (event.target.value.length === 0) {
      this.setState({
        query: "",
        searching: true
      });
      this.getInfo(id, true);
    }
  }

  handleClick(id) {
    const path = `${window.location.origin}/students/${id}/show`;
    window.location.href = path;
  }

  render() {
    const { classes } = this.props;
    const {
      rows, rowsPerPage, page, searching, total
    } = this.state;
    const headerContent = `Students`;
    const placeHolderContent = `Search Students`;
    let resultsRender = <Spinner />;
    if (searching !== true) {
      if (rows.length === 0) {
        resultsRender = (
          <div className={classes.allPadded}>No results found</div>
        );
      } else {
        resultsRender = (
          <Paper>
            <div className={classes.tableWrapper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <CustomLeftHeaderCell>Last Name</CustomLeftHeaderCell>
                    <CustomLeftHeaderCell>First Name</CustomLeftHeaderCell>
                    <CustomRightHeaderCell numeric="true">
                      Student Number
                    </CustomRightHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map(row => (
                    <TableRow
                      hover
                      onClick={() => this.handleClick(row.id)}
                      className={classes.row}
                      key={row.id}
                    >
                      <CustomLeftHeaderCell component="th" scope="row">
                        {row.last_name}
                      </CustomLeftHeaderCell>
                      <CustomLeftHeaderCell>
                        {row.first_name}
                      </CustomLeftHeaderCell>
                      <CustomRightHeaderCell numeric="true">
                        {row.student_number}
                      </CustomRightHeaderCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      colSpan={3}
                      count={total}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      ActionsComponent={Pagination}
                      rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </div>
          </Paper>
        );
      }
    }
    return (
      <div>
        <CardIcon
          Icon={StudentCardIcon}
          bgColor={config.colors.backgroundOrange}
        />
        <Card className={classes.card}>
          <Header content={headerContent} orientation="right" />
          <div className={classes.inCard}>
            <form className="commentForm" onSubmit={this.handleSubmit}>
              <input
                className={classes.inPut}
                placeholder={placeHolderContent}
                ref={input => {
                  this.search = input;
                }}
                onChange={this.handleInputChange}
              />
            </form>
          </div>
          {resultsRender}
        </Card>
      </div>
    );
  }
}

StudentPaginationTable.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired
};

export default withStyles(styles)(StudentPaginationTable);
