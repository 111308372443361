import localforage from 'localforage';
import {
  CREATE,
  DELETE,
  DELETE_MANY,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
  UPDATE_MANY
} from 'react-admin';

import api from '../../dd_data_module/services/api';
import { bundle, sortResponse } from './commonProviderFunctions';

const getUsersList = (type, resource, params) => {
  const { field, order } = params.sort;
  let direction = "ascend";
  if (order === "DESC") {
    direction = "descend";
  }
  if (field === "id") {
    direction = "ascend";
  }

  let { page } = params.pagination;
  const { perPage } = params.pagination;

  if (params.pagination.adjust !== undefined && page > 0) {
    page += 1;
  }

  return api
    .getUsers(
      page,
      perPage,
      { columnKey: field, order: direction },
      params.filter,
      false
    )
    .then(response => new Promise(resolve => {
      const code = response.status;
      if (code !== 200) {
        localforage.getItem("usersdatabundle").then(databundle => {
          if (databundle) {
            const { datastream, count } = databundle;
            const repackagedBundle = bundle(
              datastream,
              type,
              resource,
              params,
              count
            ); // Pick up the new params
            const thisPromise = sortResponse(repackagedBundle);
            thisPromise.then(resp => {
              resolve(resp);
            });
          }
        });
      } else {
        const users = response.data.result;
        const count = response.data.info.total_count;
        const databundle = bundle(users, type, resource, params, count);

        localforage
          .setItem("usersdatabundle", databundle)
          .then(() => {
            const thisPromise = sortResponse(databundle);
            thisPromise.then(resp => {
              resolve(resp);
            });
          });
      }
    }));
};

const getUsersOne = params => api.getUser({ userId: params.id }).then(response => new Promise(resolve => {
  const { message } = response.data;
  const { code } = response.data.response;
  if (message !== "Success" || code !== 200) {
    localforage.getItem("usersdatabundle").then(databundle => {
      resolve(databundle);
    });
  } else {
    const user = response.data.result;
    const capabilities = response.data.capabilities;
    const databundle = { data: user, capabilities };
    const date = new Date(databundle.data.created_at);
    databundle.data.created_at = date;
    localforage.setItem("usersdatabundle", databundle).then(() => {
      resolve(databundle);
    });
  }
}));

const deleteUser = (params) => {
  const studentId = params.id;
  return api
    .deleteUser(studentId)
}

const assumeUser = (params) => {
  const studentId = params.id;
  return api
    .assumeUser(studentId)
}

const sendResetPasswordEmail = (params) => {
  const studentId = params.id;
  return api
    .sendPasswordResetEmail(studentId)
}

const getUsersResponse = (type, resource, params) => {
  switch (type) {
    case GET_LIST: {
      return getUsersList(type, resource, params);
    }
    case GET_ONE:
      return getUsersOne(params);
    case GET_MANY:
      return {
        data: null
      };
    case GET_MANY_REFERENCE:
      return {
        data: null
      };
    case CREATE:
      return {
        data: null
      };
    case UPDATE:
      return assumeUser(params)
    case UPDATE_MANY:
      return {
        data: null
      };
    case DELETE:
      return deleteUser(params)
    case DELETE_MANY:
      return {
        data: null
      };
    case 'SEND_EMAIL':
      return sendResetPasswordEmail(params);
    default:
      return false;
  }
};

export default getUsersResponse;
