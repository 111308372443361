import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from 'react-admin';
import api from '../../dd_data_module/services/api';

const getDistrictTypes = districtId => api.getDistrictTypes(districtId).then(response => new Promise(((resolve, reject) => {
  const code = response.status;
  if (code === 403) {
    resolve('Not authenticated');
  } else if (code === 200) {
    const districtTypes = response.data;
    const dataBundle = { data: districtTypes };
    resolve(dataBundle);
  } else {
    reject(response)
  }
})));

const updateDistrictType = (districtId, params) => api.updateDistrictType(districtId, params).then(response => new Promise(((resolve, reject) => {
  const code = response.status;
  if (code === 403) {
    resolve('Not authenticated');
  } else if (code === 200) {
    const districtType = response.data;
    const dataBundle = { data: districtType };
    resolve(dataBundle);
  } else {
    reject(code)
  }
})));

const getDistrictTypesResponse = (type, resource, params, districtId) => {
  switch (type) {
    case GET_LIST:
      return getDistrictTypes(districtId);
    case GET_ONE:
      return null
    case GET_MANY:
      return {
        data: null,
      };
    case GET_MANY_REFERENCE:
      return {
        data: null,
      };
    case CREATE:
      return {
        data: null,
      };
    case UPDATE:
      return updateDistrictType(districtId, params);
    case UPDATE_MANY:
      return {
        data: null,
      };
    case DELETE:
      return {
        data: null,
      };
    case DELETE_MANY:
      return {
        data: null,
      };
    default:
      return false;
  }
};

export default getDistrictTypesResponse;
