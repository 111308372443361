import 'clientjs';
import apisauce from 'apisauce';
import {isEmpty} from 'lodash';
import {getStore} from '../../dd_common/store';
import core from '../../core/env';
import env from '../../dd_common/env';

const client = new ClientJS(); // eslint-disable-line no-undef

const api = apisauce.create({
  baseURL: env.proxy ? env.api.urlPrefix : `${core.api.baseUrl}${core.api.urlPrefix}`,
  withCredentials: true,
  timeout: 120000
});


const authHeaders = () => ({
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
  Authorization: `Bearer ${getStore().getState().auth.auth.authToken}`
});

const showToken = () => (console.log(getStore().getState().auth.auth.authToken));

const addUserAccess = ({
  email, firstName, lastName, districts, schools
}) => {
  const data = {
    email,
    first_name: firstName,
    last_name: lastName
  };
  if (!isEmpty(districts)) {
    data.districts = districts;
  }
  if (!isEmpty(schools)) {
    data.schools = schools;
  }
  return api.post('/admin/access/add', data, {headers: authHeaders()});
};

const removeUserAccess = ({userId, districtId, schoolId}) => {
  const data = {
    user_id: userId
  };

  if (districtId) {
    data.district_id = districtId;
  }
  if (schoolId) {
    data.school_id = schoolId;
  }

  return api.delete('/admin/access/remove', data, {headers: authHeaders()});
};

const getAccess = () => api.get('/admin/access', null, {headers: authHeaders()});

const getToken = (emailOrPhone, loginToken) => api.post('/get-token', {
  email_or_phone: emailOrPhone,
  login_token: loginToken
});

const resetPassword = emailOrPhone => api.post('/password-reset', {
  email_or_phone: emailOrPhone
});

const signIn = (emailOrPhone, password) => api.post('/sign-in', {
  device_name: client.getBrowser(),
  device_uid: client.getFingerprint(),
  email_or_phone: emailOrPhone,
  password
});

const suspendUser = userId => api.post(`/admin/users/${userId}/suspend`, null, {
  headers: authHeaders()
});

const unsuspendUser = userId => api.post(`/admin/users/${userId}/unsuspend`, null, {
  headers: authHeaders()
});

const disableStudent = studentId => api.post(`/admin/students/${studentId}/disable`, null, {
  headers: authHeaders()
});

const enableStudent = studentId => api.post(`/admin/students/${studentId}/enable`, null, {
  headers: authHeaders()
});

const getProfile = () => api.get('/profile', null, { headers: authHeaders() });

export default {
  addUserAccess,
  removeUserAccess,
  getAccess,
  getToken,
  resetPassword,
  signIn,
  suspendUser,
  unsuspendUser,
  disableStudent,
  enableStudent,
  showToken,
  getProfile
};
