import Button from '@material-ui/core/Button';
import notifierIcon from '@material-ui/icons/AlarmOn';
import studentsIcon from '@material-ui/icons/LocalLibrary';
import placeIcon from '@material-ui/icons/Place';
import localforage from 'localforage';
import React, { Component } from 'react';
import {
  DateField, GET_ONE, Link, Show, SimpleShowLayout, TextField, SaveButton,
} from 'react-admin';
import SecureLS from 'secure-ls';

import extractProfile from '../analytics_config/extractProfile';
import { pageAnalytic } from '../analytics_config/plugins/setAnalytic';
import Descriptor from '../components/Descriptor';
import Header from '../components/Header';
import Notifier from '../components/Notifier';
import ShowNotificationsCount from '../components/ShowNotificationsCount';
import Spinner from '../components/Spinner';
import config from '../conf/common';
import dataLink from '../dataStores/ddData/dataLink';
import env from '../core/env';
import ActivityStudentsIconWhite from '../icons/ActivityStudentsIconWhite';
import SendTestNotification from "../components/SendTestNotification";
import { getCapability } from '../utils/capabilities';
import DeleteUser from '../components/DeleteUser';
import NotificationRecipients from '../components/UserView/NotificationRecipients';
import PushNotificationCredentials from '../components/UserView/PushNotificationCredentials';
import AssumeUser from '../components/AssumeUser';
import SendPasswordResetButton from '../components/SendPasswordResetButton';

const ls = new SecureLS({ encodingType: "aes" });

const divStyle = { marginBottom: "25px", marginTop: "25px", display: 'flex', alignItems: 'center' };
const headingStyle = { display: "block", marginTop: "25px" };
const leftCol = { width: "35%", minWidth: "450px", maxWidth: "450px" };
const rightCol = { width: "60%", minWidth: "450px", paddingTop: "50px" };

const descriptorSpace = {
  marginTop: "10px",
  marginBottom: "20px",
  maxWidth: "400px"
};

const thinner = {
  maxWidth: "430px"
};

const Card = {
  marginTop: "20px",
  marginRight: "20px",
  width: "auto",
  flexWrap: "wrap",
  background: "#ffffff",
  padding: "20px",
  paddingTop: 1,
  boxShadow:
    "0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)"
};

const flexClear = {
  display: "flex",
  width: "auto",
  flexWrap: "wrap",
  justifyContent: "flex-start"
};


class UsersView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      singleUser: null,
      dialogOpen: false,
      notificationContactToDelete: null,
      capabilities: null
    };

    this.initialiser = this.initialiser.bind(this);
    this.openRemoveNotificationDialog = this.openRemoveNotificationDialog.bind(this);
    this.closeRemoveNotificationDialog = this.closeRemoveNotificationDialog.bind(this);
    this.headerContent = "User Details";
  }

  componentDidMount() {
    let pauseForTokenRefresh = ls.get("pauseForTokenRefresh");
    if (pauseForTokenRefresh === "undefined" || pauseForTokenRefresh === null) {
      pauseForTokenRefresh = 1;
    }
    this.timer = setTimeout(this.initialiser, pauseForTokenRefresh);
  }

  UNSAFE_componentWillUnmount() {
    clearTimeout(this.timer);
  }

  initialiser() {
    const { id } = this.props;

    dataLink(GET_ONE, "users", {
      id
    }).then((response, a, b) => {
      if (response.error === "Unauthorized") {
        localforage.getItem("singleUser").then(value => {
          this.setState({
            singleUser: value.data,
            capabilities: value.capabilities
          });
        });
      } else {
        localforage.setItem("singleUser", response).then(() => {
          this.setState({
            singleUser: response.data,
            capabilities: response.capabilities
          });
        });
      }
    });
  }

  formatTime(timestring) {
    timestring = timestring.split("T");
    timestring = timestring[1];
    timestring = timestring.split(".");
    timestring = timestring[0];
    timestring = `(${timestring})`;

    return timestring;
  }

  openEmailConfirmationPage() {
    window.open(env.confirmationEmailUrl);
  }

  districtNameCompare(a, b) {
    if (a.district.name < b.district.name) return -1;
    if (a.district.name > b.district.name) return 1;
    return 0;
  }

  stopTimeCompare(a, b) {
    if (a.stop_time < b.stop_time) return -1;
    if (a.stop_time > b.stop_time) return 1;
    return 0;
  }

  filteredNulls(array) {
    return array.filter(el => el != null);
  }

  appendTimeAbbreviation(time) {
    if (time.split(":")[0] < 12) {
      return `${time} AM`;
    }
    return `${time} PM`;
  }

  convertSmallUnits(threshold, unit) {
    if (unit === 'metre') {
      threshold /= 1000;
      unit = 'kilometre';
    } else if (unit === 'foot') {
      threshold /= 5280;
      unit = 'mile';
    }
    return { threshold, unit };
  }

  openRemoveNotificationDialog(notificationId) {
    this.setState({
      dialogOpen: true,
      notificationContactToDelete: notificationId
    });
  }


  closeRemoveNotificationDialog() {
    this.setState({ dialogOpen: false });
  }



  render() {
    const newProfile = extractProfile();
    if (newProfile) {
      pageAnalytic(newProfile, { title: 'User View' });
    }

    const {
      status,
      id: userId,
      dialogOpen,
      history: {
        replace: navigateAndRemoveHistory
      }
    } = this.props;

    const { singleUser, capabilities } = this.state;

    const currentUserId = ls.get("user_id");
    const hasDeleteCapability = getCapability(capabilities, 'delete', 'user');
    const canDeleteUser = hasDeleteCapability && currentUserId != userId;
    const hasAssumeCapability = getCapability(capabilities, 'forced_password_reset', 'user');

    if (singleUser !== null && singleUser !== undefined) {
      // Notification Sort Function
      const notificationSort = (notifications, sortType, icon) => {
        const months = [
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12"
        ];
        let todayTriggered = "No";
        let previousTriggered = "No";
        let previousTriggeredTime = "None";
        let todayTriggeredTime = "None";

        // eslint-disable-next-line
        return notifications.map((notification, key) => {
          const {
            measure,
            effective_start_time: locationRangeStart,
            effective_end_time: locationRangeEnd,
            stop_time: stopTime,
            route_name: routeName,
            dispatch_type: dispatchType,
            activity_route: activityRoute,
          } = notification;

          if (!stopTime && !(locationRangeStart && locationRangeEnd)) {
            return null;
          }

          const {
            first_name: firstName,
            last_name: lastName,
            student_number: studentNumber
          } = notification.student;

          const gpsStudent = (notification.student.district.settings.district_type.indexOf("gps") >= 0);
          const student = `${firstName} ${lastName} (${studentNumber})`;

          const { threshold, unit } = this.convertSmallUnits(notification.threshold, notification.unit);

          const stop = `${notification.stop.name} (${gpsStudent || activityRoute ? `${this.appendTimeAbbreviation(locationRangeStart)} - ${this.appendTimeAbbreviation(locationRangeEnd)})`
            : `${this.appendTimeAbbreviation(stopTime)})`}`;
          let plural = "";
          if (threshold !== 1) {
            plural = "s";
          }
          const type = `${threshold} ${unit}${plural}`;
          const todayd = new Date();
          const today = `${todayd.getFullYear()}-${months[todayd.getMonth()]
            }-${todayd.getDate().toString().padStart(2, "0")}`;

          Object.keys(notification.history).map(keyName => {
            const value = notification.history[keyName];
            if (keyName === today && value.triggered) {
              todayTriggered = "Yes";
              if (value.trigger_time !== undefined) {
                todayTriggeredTime = this.formatTime(value.trigger_time);
              } else {
                todayTriggeredTime = "---";
              }
            } else if (keyName === today && !value.triggered) {
              todayTriggered = "No";
              todayTriggeredTime = "None";
            } else if (keyName !== today && value.triggered) {
              previousTriggered = "Yes";
              if (value.trigger_time !== undefined) {
                previousTriggeredTime = this.formatTime(value.trigger_time);
              } else {
                previousTriggeredTime = "---";
              }
            } else if (keyName !== today && !value.triggered) {
              previousTriggered = "No";
              previousTriggeredTime = "None";
            }
            return [todayTriggered, previousTriggered];
          });
          if (
            (sortType === "distanceActivity"
              && activityRoute
              && measure === "distance")
            || (sortType === "timeam"
              && dispatchType === "AM"
              && measure === "time")
            || (sortType === "timemid"
              && dispatchType === "MID"
              && measure === "time")
            || (sortType === "timepm"
              && dispatchType === "PM"
              && measure === "time")
            || (sortType === "distanceam"
              && !activityRoute
              && dispatchType === "AM"
              && measure === "distance")
            || (sortType === "distancemid"
              && !activityRoute
              && dispatchType === "MID"
              && measure === "distance")
            || (sortType === "distancepm"
              && !activityRoute
              && dispatchType === "PM"
              && measure === "distance")
          ) {
            return (
              <Notifier
                key={key}
                text="Notification Set: "
                color={config.colors.backgroundSeaLight}
                icon={icon}
                student={student}
                stop={stop}
                isGPSStudent={gpsStudent}
                type={type}
                triggeredToday={todayTriggered}
                todayTriggeredTime={todayTriggeredTime}
                triggeredPrevious={previousTriggered}
                previousTriggeredTime={previousTriggeredTime}
                routeName={routeName}
                isActivityRoute={activityRoute}
              />
            );
          }
          return null;
        });
      };

      // End of Notification Sort

      const user = singleUser;
      const { students, activity_students: activityStudents } = user;
      students.sort(this.districtNameCompare);

      const studentComp = students.map((student, key) => {
        const studentUrl = `students/${student.id}/show`;
        return (
          <div key={key} style={descriptorSpace}>
            <Descriptor
              text="Following Student: "
              color={config.colors.backgroundOrange}
              icon={studentsIcon}
              value={`${student.first_name} ${student.last_name}`}
              district={`(${student.district.name})`}
              link={studentUrl}
              marginRight
            />
          </div>
        );
      });

      const activityStudentComp = activityStudents.map((student, key) => {
        const studentUrl = `activitystudents/${student.id}/show`;
        return (
          <div key={key} style={descriptorSpace}>
            <Descriptor
              text="Following Student: "
              color={config.colors.backgroundOrange}
              icon={studentsIcon}
              value={`${student.first_name} ${student.last_name}`}
              district={`(${student.district.name})`}
              link={studentUrl}
              marginRight
            />
          </div>
        );
      });

      const notifications = user.notification_preferences.sort(
        this.stopTimeCompare
      );

      const notificationsTimeAMComp = this.filteredNulls(
        notificationSort(notifications, "timeam", notifierIcon)
      );
      const notificationsTimeMIDComp = this.filteredNulls(
        notificationSort(notifications, "timemid", notifierIcon)
      );
      const notificationsTimePMComp = this.filteredNulls(
        notificationSort(notifications, "timepm", notifierIcon)
      );
      const notificationsDistanceAMComp = this.filteredNulls(
        notificationSort(notifications, "distanceam", placeIcon)
      );
      const notificationsDistanceMIDComp = this.filteredNulls(
        notificationSort(notifications, "distancemid", placeIcon)
      );
      const notificationsDistancePMComp = this.filteredNulls(
        notificationSort(notifications, "distancepm", placeIcon)
      );
      const notificationsDistanceActivityComp = this.filteredNulls(
        notificationSort(notifications, "distanceActivity", ActivityStudentsIconWhite)
      );

      const numberOfNotifications = notifications.length;

      let studentNotes = (
        <div style={Card}>
          <Header
            content="No Home to School Students Followed"
            orientation="left"
            pushleft="0px"
          />
        </div>
      );
      let activityStudentNotes = (
        <div style={Card}>
          <Header
            content="No Activity Students Followed"
            orientation="left"
            pushleft="0px"
          />
        </div>
      );
      let timeNotes = (
        <div style={Card}>
          <Header
            content="No Time Notifications"
            orientation="left"
            pushleft="0px"
          />
        </div>
      );
      let distanceNotes = (
        <div style={Card}>
          <Header
            content="No Distance Notifications"
            orientation="left"
            pushleft="0px"
          />
        </div>
      );

      if (
        notificationsDistanceAMComp.length > 0
        || notificationsDistanceMIDComp.length > 0
        || notificationsDistancePMComp.length > 0
        || notificationsTimeAMComp.length > 0
        || notificationsTimeMIDComp.length > 0
        || notificationsTimePMComp.length > 0
        || notificationsDistanceActivityComp.length > 0
      ) {
        let AMTimemessage = <h4 style={headingStyle}>No Morning (AM)</h4>;
        let MIDTimemessage = <h4 style={headingStyle}>No Midday (MID)</h4>;
        let PMTimemessage = <h4 style={headingStyle}>No Afternoon (PM)</h4>;
        let AMDistancemessage = <h4 style={headingStyle}>No Morning (AM)</h4>;
        let MIDDistancemessage = <h4 style={headingStyle}>No Midday (MID)</h4>;
        let PMDistancemessage = <h4 style={headingStyle}>No Afternoon (PM)</h4>;
        let ActivityDistancemessage = <h4 style={headingStyle}>No Activity Tracking</h4>;

        if (notificationsTimeAMComp.length > 0) {
          AMTimemessage = (
            <React.Fragment>
              <h4 style={headingStyle}>Morning (AM)</h4>
              <div style={flexClear}>{notificationsTimeAMComp}</div>
            </React.Fragment>
          );
        }
        if (notificationsTimeMIDComp.length > 0) {
          MIDTimemessage = (
            <React.Fragment>
              <h4 style={headingStyle}>Midday (MID)</h4>
              <div style={flexClear}>{notificationsTimeMIDComp}</div>
            </React.Fragment>
          );
        }
        if (notificationsTimePMComp.length > 0) {
          PMTimemessage = (
            <React.Fragment>
              <h4 style={headingStyle}>Afternoon (PM)</h4>
              <div style={flexClear}>{notificationsTimePMComp}</div>
            </React.Fragment>
          );
        }
        if (notificationsDistanceAMComp.length > 0) {
          AMDistancemessage = (
            <React.Fragment>
              <h4 style={headingStyle}>Morning (AM)</h4>
              <div style={flexClear}>{notificationsDistanceAMComp}</div>
            </React.Fragment>
          );
        }
        if (notificationsDistanceMIDComp.length > 0) {
          MIDDistancemessage = (
            <React.Fragment>
              <h4 style={headingStyle}>Midday (MID)</h4>
              <div style={flexClear}>{notificationsDistanceMIDComp}</div>
            </React.Fragment>
          );
        }
        if (notificationsDistancePMComp.length > 0) {
          PMDistancemessage = (
            <React.Fragment>
              <h4 style={headingStyle}>Afternoon (PM)</h4>
              <div style={flexClear}>{notificationsDistancePMComp}</div>
            </React.Fragment>
          );
        }
        if (notificationsDistanceActivityComp.length > 0) {
          ActivityDistancemessage = (
            <React.Fragment>
              <h4 style={headingStyle}>Activity Tracking</h4>
              <div style={flexClear}>{notificationsDistanceActivityComp}</div>
            </React.Fragment>
          );
        }
        if (
          notificationsTimeAMComp.length > 0
          || notificationsTimeMIDComp.length > 0
          || notificationsTimePMComp.length > 0
        ) {
          timeNotes = (
            <div style={Card}>
              <Header
                content="Time Notifications"
                orientation="left"
                pushleft="0px"
              />
              {AMTimemessage}
              {MIDTimemessage}
              {PMTimemessage}
            </div>
          );
        }
        if (
          notificationsDistanceAMComp.length > 0
          || notificationsDistanceMIDComp.length > 0
          || notificationsDistancePMComp.length > 0
          || notificationsDistanceActivityComp.length > 0
        ) {
          distanceNotes = (
            <div style={Card}>
              <Header
                content="Distance Notifications"
                orientation="left"
                pushleft="0px"
              />
              {AMDistancemessage}
              {MIDDistancemessage}
              {PMDistancemessage}
              {ActivityDistancemessage}
            </div>
          );
        }
      }

      if (studentComp.length > 0) {
        studentNotes = (
          <div style={Card}>
            <Header
              content="Following Home To School Students"
              orientation="left"
              pushleft="0px"
              pushdown="0px"
            />
            <div style={flexClear}>{studentComp}</div>
          </div>
        );
      }

      if (activityStudentComp.length > 0) {
        activityStudentNotes = (
          <div style={Card}>
            <Header
              content="Following Activity Students"
              orientation="left"
              pushleft="0px"
              pushdown="0px"
            />
            <div style={flexClear}>{activityStudentComp}</div>
          </div>
        );
      }

      const DistrictField = ({ record = {} }) => ((record.district || {}).id ? (
        <Link to={`/districts/${(record.district || {}).id}/show`}>
          {(record.district || {}).name}
        </Link>
      ) : (
        <span>Undefined</span>
      ));

      DistrictField.defaultProps = { label: "District", addLabel: true };

      const UserTypeField = ({ record = {} }) => {
        const userType = record.district_dashboard_user
          ? 'Admin'
          : 'Parent';
        return <span>{userType}</span>;
      };

      UserTypeField.defaultProps = { label: "User Type", addLabel: true };

      return (
        <React.Fragment>

          <div style={flexClear}>
            <div style={leftCol}>
              <Header
                content={
                  `${singleUser.first_name} ${singleUser.last_name}`
                }
                orientation="left"
                pushleft="0px"
              />

              <div style={thinner}>
                <Show title="User Details" {...this.props}>
                  <SimpleShowLayout>
                    <TextField source="email" label="Email" />
                    <TextField source="phone" label="Phone Number" />
                    <TextField source="status" label="Status" />
                    <DistrictField />
                    <TextField source="login_count" label="Number of Logins" />
                    <DateField source="created_at" label="Added" />
                    <UserTypeField />
                  </SimpleShowLayout>
                </Show>
              </div>

              {status !== 'confirmed' && (
                <div style={divStyle}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.openEmailConfirmationPage}
                  >
                    Send Confirmation Email
                  </Button>
                </div>
              )}
              <SendPasswordResetButton userId={userId} />
              {canDeleteUser && <DeleteUser userId={userId} navigateAndRemoveHistory={navigateAndRemoveHistory} />}
              {hasAssumeCapability && <AssumeUser userId={userId} />}
              <ShowNotificationsCount amount={numberOfNotifications} />
              <div style={Card}>
                <Header
                  content="Notification Recipients"
                  orientation="left"
                  pushleft="0px"
                  pushdown="0px"
                />
                <NotificationRecipients notificationContacts={user.notification_contacts} reinitialize={this.initialiser} />
              </div>
              <div style={Card}>
                <Header
                  content="Push Notification Credentials"
                  orientation="left"
                  pushleft="0px"
                  pushdown="0px"
                />
                <PushNotificationCredentials 
                  notificationCredentials={user.push_notifications_credentials} 
                  externalId={user.external_id} 
                  onesignalId={user.onesignal_id}
                  onesignalActive={user.onesignal_active}
                />
              </div>
              <div style={Card}>
                <SendTestNotification userId={userId} />
              </div>
            </div>
            <div style={rightCol}>
              {studentNotes}

              {activityStudentNotes}

              {timeNotes}

              {distanceNotes}
            </div>
          </div>
        </React.Fragment>
      );
    }
    return <Spinner />;
  }
}

export { UsersView };
