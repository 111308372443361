import SecureLS from 'secure-ls';
import env from '../../core/env';
import analytics from '..';
const ls = new SecureLS({ encodingType: 'aes' });

if (!Date.now) {
  Date.now = () => new Date().getTime();
}

const getDiff = () => {
  const lastDate = ls.get('lastDate');
  const currentDate = Date.now();
  if (lastDate !== null && lastDate > 0) {
    return currentDate - lastDate;
  }
  return 0;
};

export const initAnalytic = profile => {
  if (profile && env.analytics.logging) {
    const diff = getDiff();
    if (analytics.user('userId') !== profile.$email) {
      analytics.reset();
      analytics.identify(profile.$email);
    } else if (diff > 100) {
      analytics.identify(profile.$email);
    }
  }
};

export const pageAnalytic = (profile, { title }) => {
  if (profile && env.analytics.logging) {
    const diff = getDiff();
    ls.set('lastDate', Date.now());
    if (diff > 100) {
      analytics.identify(profile.email);
      analytics.page({ title });
    }
  }
};
