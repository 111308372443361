import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Datagrid,
  DateField,
  Filter,
  List,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput
} from "react-admin";
import Card from "@material-ui/core/Card";
import ServiceAlertsIcon from "@material-ui/icons/NotificationsActive";
import SecureLS from "secure-ls";
import CustomerLinkField from "./utilities/CustomerLinkField";

import CardIcon from "../components/CardIcon";
import config from "../conf/common";
import Header from "../components/Header";
import AuthenticationError from "../components/AuthenticationError";
import extractProfile from "../analytics_config/extractProfile";
import { pageAnalytic } from "../analytics_config/plugins/setAnalytic";
import ServiceAlertOriginField from "./utilities/ServiceAlertOriginField";
import DynamicFilterField from "./utilities/DynamicFilterField";
import ListActions from "../components/ListActions";


const ls = new SecureLS({ encodingType: "aes" });

const styles = theme => ({
  rowEven: {
    backgroundColor: "#F8F8F8"
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  table: {
    minWidth: 500
  },
  tableWrapper: {
    overflowX: "auto"
  },
  main: {
    flex: "1",
    marginRight: "1em",
    marginTop: 20
  },
  card: {
    overflow: "inherit",
    textAlign: "left",
    padding: 16,
    minHeight: 52,
    marginRight: '1em'
  }
});

const originTypes = [
  { id: "district_app", name: "District app" },
  { id: "focus", name: "FOCUS" },
  { id: "adiona", name: "Adiona"},
];

const PostFilter = props => (
  <Filter {...props}>
    <DynamicFilterField label="Filter By Category" entity="service-alerts" source="category" alwayson="true"/>
    <DynamicFilterField style={{ width: 200 }} label="Filter By Message Type" entity="service-alerts" source="message_type" alwayson="true"/>
    <TextInput label="Search Messages" source="message" defaultValue="" alwayson="true" />
    <TextInput label="Search Routes" source="route" defaultValue="" alwayson="true" />
    <TextInput label="Search Run Codes" source="run_code" defaultValue="" alwayson="true" />
    <ReferenceInput
      label="Filter By District"
      source="district.id"
      reference="districts"
      allowEmpty
      perPage={1000000}
      alwayson="true"
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <SelectInput
      style={{ width: 191 }}
      label="Filter By Dispatch Type"
      source="dispatch_type"
      choices={[{ id: "AM", name: "AM" }, { id: "PM", name: "PM" }, { id: "MID", name: "MID" }]}
      alwayson="true"
    />
    <SelectInput
      style={{ width: 191 }}
      label="Filter By Origin"
      source="origin"
      choices={originTypes}
      alwayson="true"
    />
  </Filter>
);

const ServiceAlertsList = withStyles(styles)(({ classes, ...props }) => {
  const headerContent = "All Service Alerts";

  const newProfile = extractProfile();

  if (newProfile) {
    pageAnalytic(newProfile, { title: 'Service Alerts Listing' });
  }

  const authenticated = ls.get("authenticated");
  if (authenticated === "yes") {
    return (
      <React.Fragment>
        <Card className={classes.card}>
          <CardIcon
            Icon={ServiceAlertsIcon}
            bgColor={config.colors.backgroundRed}
            orientation="left"
          />
          <Header content={headerContent} orientation="left" />
          <List
            title="All Service Alerts"
            {...props}
            bulkActionButtons={false}
            filters={<PostFilter />}
            actions={<ListActions type="service_alerts" />}
          >
            <Datagrid rowClick="show" classes={{ rowEven: classes.rowEven }}>
              <DateField source="date" label="Date" />
              <TextField source="category" label="Category" />
              <TextField source="message_type" label="Message Type" />
              <TextField source="title" label="Title" />
              <TextField source="message" label="Message" sortable={false} />
              <TextField source="message_id" label="Message ID" />
              <TextField source="district.name" label="District" sortBy="district_name" />
              <CustomerLinkField sortable={false} />
              <TextField source="dispatch_type" label="Dispatch Type" />
              <TextField
                source="contractor_id"
                label="Contractor ID"
                sortable={false}
              />
              <TextField
                source="daily_task_id"
                label="Daily Task ID"
                sortable={false}
              />
              <TextField source="route" label="Route" />
              <ServiceAlertOriginField originTypes={ originTypes } />
            </Datagrid>
          </List>
        </Card>
      </React.Fragment>
    );
  }
  return <AuthenticationError />;
});

export default ServiceAlertsList;
