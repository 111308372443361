import React, { Component } from 'react';
import { Labeled, LinearProgress, SelectInput } from 'react-admin';

import api from '../../dd_data_module/services/api';

class DynamicFilterField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      choices: [],
      loading: true,
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const { entity, source: field, valueExtractor } = this.props;
    return api.getFilterOptions({ entity, field }).then(response => {
      let choices;
      if (response.ok) {
        const { values } = response.data;
        choices = values.map(valueExtractor);
        choices.unshift({ id: '', name: '' });
      } else {
        console.log('Error getting filter fields response:', { response });
        choices = [{ name: 'Could not retrieve filters' }];
      }
      return this.setState({ choices, loading: false });
    });
  }

  render() {
    const { choices, loading } = this.state;
    const {
      alwayson="true", isRequired, label, source, style, translate = true
    } = this.props;
    if (loading) {
      return (
        <Labeled label={label} source={source} isRequired={isRequired}>
          <LinearProgress />
        </Labeled>
      );
    }
    return (
      <SelectInput
        style={{...style, marginBottom: "-15px"}}
        label={label}
        source={source}
        choices={choices}
        alwayson={alwayson}
        translateChoice={translate}
      />
    );
  }
}

DynamicFilterField.defaultProps = {
  addLabel: true,
  valueExtractor: value => ({ id: value, name: value }),
  isRequired: false,
  style: {},
};

export default DynamicFilterField;
