import React from 'react';
import PropTypes from 'prop-types';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogButton from "@material-ui/core/Button";

const styles = {
  cancelButton: {
    color: "#2c387e"
  },
};

const DialogMessage = ({
  dialogOpen,
  titleText,
  contentText,
  closeFunction,
  closeButtonText,
  saveButton,
}) => (
  <Dialog
    open={dialogOpen}
    onClose={closeFunction}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {titleText}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {contentText}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <DialogButton
        style={styles.cancelButton}
        onClick={closeFunction}
        color="primary"
      >
        {closeButtonText}
      </DialogButton>
      {saveButton || null}
    </DialogActions>
  </Dialog>
);

DialogMessage.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  titleText: PropTypes.string.isRequired,
  contentText: PropTypes.string.isRequired,
  closeFunction: PropTypes.func.isRequired,
  closeButtonText: PropTypes.string.isRequired,
  saveButton: PropTypes.object,
};

export default DialogMessage;
