import immutable from 'seamless-immutable';
import localForage from 'localforage';
import {persistStore} from 'redux-persist';
import createPersistoid from 'redux-persist/es/createPersistoid'
import asyncSessionStorage from 'redux-persist/es/storage/session';
import {identity} from 'lodash';
import env from './env';

let sessionPersistor = null;
let permanentPersistor = null;

const transformPersistor = {
  in: state => (state.asMutable ? state.asMutable({deep: true}) : state),
  out: state => {
    state.mergeDeep = identity; // eslint-disable-line no-param-reassign
    return immutable(state);
  }
};

const sessionPersistorConfig = {
  transforms: [transformPersistor],
  storage: asyncSessionStorage,
  whitelist: env.app.redux.persistWhitelist
};

const permanentPersistorConfig = {
  transforms: [transformPersistor],
  storage: localForage,
  whitelist: env.app.redux.persistWhitelist
};

const persistStorePromise = (store, persistorConfig=null) => new Promise((resolve, reject) => {
  const persistor = persistStore(store, null, (err, restoredState) => {
    if (err) {
      reject(err);
    } else {
      resolve({persistor, restoredState: store.getState()});
    }
  });
});

export const getPersistors = () => ({
  permanentPersistor,
  sessionPersistor
});

export async function configurePersistors(store) {
  const rememberMe = await localForage.getItem(env.app.rememberMeKey);
  // const rememberMe = true;
  if (!rememberMe) {
    const {persistor} = await persistStorePromise(store, sessionPersistorConfig);
    sessionPersistor = persistor;
    sessionPersistor.resume();
    permanentPersistor = createPersistoid(store, permanentPersistorConfig);
    permanentPersistor.pause();
  } else {
    sessionPersistor = createPersistoid(store, sessionPersistorConfig);
    sessionPersistor.pause();
    const {persistor} = await persistStorePromise(store, permanentPersistorConfig);
    permanentPersistor = persistor;
    permanentPersistor.resume();
  }
}
