import SecureLS from "secure-ls";

const ls = new SecureLS({ encodingType: "aes" });

export const AmericaCenter = ["47.0902", "-95.7129"];

export const getStyle = (width, height) => {
  // TODO: Review on IE whether this is needed
  const usingIE = ls.get("usingIE");
  let style = { position: "relative", zIndex: "3" };
  if (!usingIE) {
    style = {
      ...style,
      width: width || "auto",
      height: height || 400
    };
  }
  return style;
};

export const convertToArray = arr => {
  if (!Array.isArray(arr)) {
    return [arr];
  }
  return arr;
};

export const findMapCenter = markers => {
  if (!Array.isArray(markers)) {
    const marker = markers;
    return marker;
  }
  const fullAmount = markers.length;
  if (fullAmount === 0) {
    return "none";
  }
  const center = Math.floor(fullAmount / 2);
  return markers[center];
};
