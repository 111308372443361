import React from "react";
import Spinner from "react-spinkit";
import PropTypes from "prop-types";

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 50,
    pointerEvents: "none",
    backgroundColor: "rgba(128,128,128,0.45)",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  spinnerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  message: { marginTop: 30 }
};

const LoadingOverlay = ({
  fadeIn, name, color, show, message
}) => (
  <div style={{ display: show ? "flex" : "none", ...styles.overlay }}>
    <div style={styles.spinnerContainer}>
      <Spinner fadeIn={fadeIn} name={name} color={color} />
      <span style={styles.message}>{message}</span>
    </div>
  </div>
);

LoadingOverlay.propTypes = {
  fadeIn: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.string,
  show: PropTypes.bool,
  message: PropTypes.string
};

LoadingOverlay.defaultProps = {
  fadeIn: "none",
  name: "wandering-cubes",
  color: "green",
  show: false,
  message: "Loading..."
};

export default LoadingOverlay;
