import React from 'react';
import { connect } from 'react-redux';
import { userLogin } from 'react-admin';
import LoginForm from '../components/LoginForm';

class LoginWrapper extends React.Component {
  render() {
    return (
      <LoginForm userLogin={this.props.userLogin} />
    );
  }
}

export default connect(undefined, { userLogin })(LoginWrapper);
