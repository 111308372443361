import React, { useState } from 'react';
import DialogMessage from "../DialogMessage";
import api from "../../dd_data_module/services/api";
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import { SaveButton, translate } from 'react-admin';
import Snackbar from "@material-ui/core/Snackbar";
import SingleNotificationRecipient from './SingleNotificationRecipient';
import {
    groupBy
} from 'lodash';

const styles = {
    subHead: {
        fontSize: '1rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        lineHeight: 1.5,
        letterSpacing: '0.00938em'
    }
}

const NotificationRecipients = ({ notificationContacts, reinitialize, translate }) => {

    const [dialogOpen, setDialogOpen] = useState(false);
    const [contactToBeDeleted, setContactToBeDeleted] = useState(null);

    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const openRemoveNotificationDialog = (id) => {
        setDialogOpen(true);
        setContactToBeDeleted(id)
    }

    const deleteContact = () => {
        api.removeNotificationContact(contactToBeDeleted).then(resp => {
            if (resp.ok) {
                setSuccessMessage(translate('resources.users.notifications.remove.success'))
                reinitialize()
            } else {
                setErrorMessage(translate('resources.users.notifications.remove.error'))
                setContactToBeDeleted(null)
            }
        });
        setDialogOpen(false);
    }

    const resendConfirmation = async (id) => {
        return await api.resendConfirmationEmail(id).then(resp => {
            if (resp.ok) {
                setSuccessMessage(translate('resources.users.notifications.confirmEmail.success'))
                return true;
            } else {
                setErrorMessage(translate('resources.users.notifications.confirmEmail.error'))
                return false
            }
        });
    }

    const snackBarClose = () => {
        if (errorMessage) {
            setErrorMessage(null);
        } else {
            setSuccessMessage(null)
        }
    }

    const GetRecipientsList = ({ notificationContacts }) => {
        const split = groupBy(notificationContacts, 'active');
        return (
            <div>
                {
                    split.true && split.true.length > 0 && (
                        <div>
                            <h1 style={styles.subHead}>Confirmed</h1>
                            {split.true.map((contact) => (
                                <SingleNotificationRecipient
                                    key={contact.id}
                                    contact={contact}
                                    deleteContact={() => openRemoveNotificationDialog(contact.id)}
                                    resendConfirmation={() => resendConfirmation(contact.id)}
                                    translate={translate}
                                />
                            ))}
                        </div>
                    )
                }
                {
                    split.false && split.false.length > 0 && (
                        <div>
                            <h1 style={styles.subHead}>Unconfirmed</h1>
                            {split.false.map((contact) => (
                                <SingleNotificationRecipient
                                    key={contact.id}
                                    contact={contact}
                                    deleteContact={() => openRemoveNotificationDialog(contact.id)}
                                    resendConfirmation={() => resendConfirmation(contact.id)}
                                    translate={translate}
                                />
                            ))}
                        </div>
                    )
                }
            </div>
        )
    }

    return (
        <List dense>
            {Array.isArray(notificationContacts) && notificationContacts.length > 0 ?
                <GetRecipientsList notificationContacts={notificationContacts} />
                : (
                    <Typography>No notification recipients configured.</Typography>
                )}
            <DialogMessage
                dialogOpen={dialogOpen}
                titleText={translate('resources.users.notifications.remove.dialog.title')}
                contentText={translate('resources.users.notifications.remove.dialog.message')}
                closeFunction={() => setDialogOpen(false)}
                closeButtonText="Cancel"
                saveButton={(
                    <SaveButton
                        label="Remove"
                        handleSubmitWithRedirect={deleteContact}
                        variant={null}
                        icon={<span />}
                    />
                )}
            />
            <Snackbar
                open={successMessage !== null || errorMessage !== null}
                onClose={() => snackBarClose()}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                autoHideDuration={2000}
                message={<span id="message-id">{errorMessage ?? successMessage}</span>}
                ContentProps={errorMessage ? {
                    classes: {
                        root: 'snackbar-error'
                    }
                } : null}
            />
        </List>
    )
}

export default translate(NotificationRecipients)