import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class ActivityStudentsIconWhite extends Component {
  static propTypes = {
    className: PropTypes.string,
  }

  render() {
    const { className } = this.props;
    return <svg className={className || ''} xmlns="http://www.w3.org/2000/svg" width="22.568" height="22.568" viewBox="0 0 22.568 22.568">
      <path id="Activity_tracking_icon" data-name="Activity tracking icon" d="M18.336,14.1H14.1a1.41,1.41,0,1,1,0-2.821h4.231s4.231-4.716,4.231-7.052a4.231,4.231,0,1,0-8.463,0c0,1.124.979,2.795,2,4.231h-2a4.231,4.231,0,0,0,0,8.463h4.231a1.41,1.41,0,1,1,0,2.821H8.176a30.431,30.431,0,0,1-2.085,2.821H18.336a4.231,4.231,0,0,0,0-8.463Zm0-11.284a1.41,1.41,0,1,1-1.41,1.41A1.409,1.409,0,0,1,18.336,2.821Zm-14.1,8.463A4.233,4.233,0,0,0,0,15.515c0,2.336,4.231,7.052,4.231,7.052s4.231-4.716,4.231-7.052A4.233,4.233,0,0,0,4.231,11.284Zm0,5.642a1.41,1.41,0,1,1,1.41-1.41A1.409,1.409,0,0,1,4.231,16.926Z" fill="#fff" />
    </svg>;
  }
}
