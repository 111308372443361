import React from "react";

const moment = require("moment-timezone");

const styles = {
  main: {
    textAlign: "left",
    border: "1px solid #000"
  },
  fixHeight: {
    minHeight: "40px",
    fontSize: "0.875rem",
    fontWeight: "400",
    color: "rgba(0,0,0,0.87)"
  }
};

const TimeZoneField = props => {
  if (props.record.time_zone) {
    const outerzone = props.record.time_zone;
    const outerarray = outerzone.split("/");
    const outermarker = outerarray[1];
    const markerarray = outermarker.split("_");
    const marker = markerarray.join(" ");
    const innerzone = moment.tz(props.record.time_zone).format("Z");
    const zone = `UTC ${innerzone} (${marker})`;
    const style = {...styles.fixHeight, ...props.style};

    return <div style={style}>{zone}</div>;
  } return <span />;
};

TimeZoneField.defaultProps = {
  source: "Time Zone",
  addLabel: true
};

export default TimeZoneField;
