import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const RidershipHome = ({...props}) => (
  <SvgIcon className={props.className} viewBox="0 0 35 35">
    <path d="M22.133 35h-1.58a1.583 1.583 0 01-1.581-1.581v-1.58H6.323v1.58A1.583 1.583 0 014.743 35H3.162a1.583 1.583 0 01-1.58-1.581v-2.814A4.742 4.742 0 010 27.096v-15.81a5.2 5.2 0 013.7-5.237c2.469-.958 5.908-1.087 8.948-1.087.7 0 1.335.008 1.93.024a11.083 11.083 0 00-1.851 6.15v.15H3.162v7.9h13.011a11.086 11.086 0 007.69 3.082 11.294 11.294 0 001.432-.091v4.914a4.741 4.741 0 01-1.581 3.509v2.814A1.583 1.583 0 0122.133 35zm-2.372-11.066a2.371 2.371 0 102.372 2.372 2.374 2.374 0 00-2.372-2.372zm-14.228 0A2.371 2.371 0 107.9 26.306a2.374 2.374 0 00-2.367-2.372z" 
      />
    <path d="M23.275 0a11.183 11.183 0 0111.184 11.183 11.05 11.05 0 01-11.184 11.184 11.05 11.05 0 01-11.183-11.184A11.183 11.183 0 0123.275 0z" fill="#FFFFFF00"/>
    <path d="M23.275 2c-5.063 0-9.183 4.12-9.183 9.183 0 2.482.93 4.792 2.62 6.503 1.706 1.729 4.037 2.68 6.563 2.68 2.527 0 4.858-.951 6.564-2.68 1.69-1.711 2.62-4.02 2.62-6.503C32.459 6.12 28.339 2 23.275 2m0-2C29.452 0 34.46 5.007 34.46 11.183c0 6.177-4.796 11.184-11.184 11.184-6.388 0-11.183-5.007-11.183-11.184C12.092 5.007 17.099 0 23.275 0z" 
      />
  </SvgIcon>
);

export default (RidershipHome);