import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";
import goSquared from "@analytics/gosquared";
import env from "../core/env";

/* initialize analytics and load plugins */
const analytics = Analytics({
  debug: env.analytics.debug,
  version: env.version,
  plugins: [
    // tabEvents(),
    googleAnalytics({
      trackingId: env.googleAnalytics.propertyId,
      debug: env.analytics.debug,
    }),
    goSquared({
      projectToken: env.goSquared.projectToken,
      debug: env.analytics.debug,
    }),
  ],
});

window.Analytics = analytics;

export default analytics;
