import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CircularProgress from "@material-ui/core/CircularProgress";
import studentsIcon from "@material-ui/icons/LocalLibrary";
import usersIcon from "@material-ui/icons/People";
import parentIcon from "@material-ui/icons/Person";
import allDistrictsIcon from "@material-ui/icons/Public";
import schoolsIcon from "@material-ui/icons/School";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import localforage from "localforage";
import _ from 'lodash';
import {
  GET_LIST,
  GET_ONE,
  Responsive,
  SaveButton,
  Show,
  SimpleShowLayout,
  TextField,
  translate,
  AUTH_CHECK,
  UPDATE,
  FunctionField
} from "react-admin";
import ReactResizeDetector from "react-resize-detector";
import SecureLS from "secure-ls";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { withStyles } from '@material-ui/core/styles';
import { reset } from 'redux-form';
import { connect } from "react-redux";

import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import DialogMessage from "../components/DialogMessage";
import extractProfile from "../analytics_config/extractProfile";
import { pageAnalytic } from "../analytics_config/plugins/setAnalytic";
import defaultDistrictLogo from "../bus-icon-material.png";
import DistrictForm from "../components/DistrictForm";
import Header from "../components/Header";
import HubGoogleMap from "../components/google_maps/HubGoogleMap";
import Spinner from "../components/Spinner";
import SunriseSunsetMarker from "../components/SunriseSunsetMarker";
import Total from "../components/Total";
import config from "../conf/common";
import dataLink from "../dataStores/ddData/dataLink";
import AdminPaginationTable from "./Layouts/AdminPaginationTable";
import SchoolPaginationTable from "./Layouts/SchoolPaginationTable";
import LatenessThresholdField from "./Utilities/LatenessThresholdField";
import TimeZoneField from "./Utilities/TimeZoneField";
import UploadForm from "../components/UploadForm";
import authProvider from "../authProviderDirectCallVersion";
import ActivityStudentsIconWhite from '../icons/ActivityStudentsIconWhite';
import { getCapability } from "../utils/capabilities";

const ls = new SecureLS({ encodingType: "aes" });

const logoStyle = { height: "2em", marginRight: "0.37em" };
const bigLogoStyle = {
  display: "block",
  margin: "10px auto",
  width: "100%",
  height: "100%"
};

const reactAdminStyles = {
  card: {
    overflow: "inherit",
    textAlign: "left",
    padding: 10,
    minHeight: 32,
    marginRight: 5
  },
  main: {
    flex: "1",
    marginRight: "1em"
  },
  noActions: {
    marginTop: 0
  },
};

const styles = {
  flexSimple: { display: "flex" },
  flex: { display: "flex", paddingLeft: "20px", marginTop: "10px" },
  flexBase: { display: "flex", marginTop: "30px" },
  flexColumn: { display: "flex", flexDirection: "column" },
  flexRow: { display: "flex", direction: "row", marginBottom: "10px" },
  flexDeepRow: { display: "flex", direction: "row", marginBottom: "30px" },
  deepRow: { marginBottom: "30px" },
  shallowRow: { marginBottom: "10px" },
  leftCol: { flex: 1, marginRight: "1em" },
  rightCol: { flex: 1, marginLeft: "1em" },
  singleCol: { marginTop: "2em", marginBottom: "2em", padding: "20px" },
  singleColSmall: { marginTop: "2em", marginBottom: "2em", width: "80%" },
  leftColMainThinner: { flex: 1, marginRight: "1em", flexDirection: "column" },
  rightColMainWider: {
    flex: 2,
    marginLeft: "1em",
    flexDirection: "column",
    marginRight: "1em"
  },
  mapCard: {
    marginTop: "20px",
    marginBottom: "25px",
    background: "#ffffff",
    width: "auto",
    height: "auto",
    paddingTop: "10px",
    paddingBottom: "10px",
    boxShadow:
      "0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)"
  },
  mapCol: {
    position: "relative",
    height: "400px",
    width: "auto",
    border: "1px solid #fff",
    margin: "20px",
    zIndex: "2"
  },
  mapOverlay: {
    position: "relative",
    marginTop: "-400px",
    left: "0",
    height: "400px",
    background: "#000",
    zIndex: "5"
  },
  pushIn: { marginLeft: "20px" },
  fixHeight: { minHeight: "40px" },
  small: {
    firstBooleanInput: {
      marginTop: "20px"
    }
  },
  cardHeader: {
    color: "rgb(94, 113, 115)",
    paddingRight: "20px",
    width: "100%",
    textAlign: "left",
    fontFamily: '"Roboto Slab", serif',
    fontSize: "1.1rem"
  },
  confirmButton: {
    color: "#2c387e"
  },
  sunriseSunsetContainer: { marginBottom: 30, padding: 15 },
  logo: logoStyle,
  fadedLogo: { ...logoStyle, opacity: "0.5" },
  bigLogoLargeView: bigLogoStyle,
  bigLogoSmallView: { ...bigLogoStyle, width: "50%", height: "50%" },
  label: {
    color: "rgba(0, 0, 0, 0.54)",
    padding: '5px 0 0 0',
    fontSize: "0.8rem",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: "0.00938em"
  }
};

const renderActivityTrackingTotal = (actTrackEnabled, value = 0, alginment) => {
  if (actTrackEnabled === 'No') {
    return <Total empty />;
  }
  return <Total
    text="pos.dashboard.total_activity_users"
    color={config.colors.backgroundBlue}
    icon={usersIcon}
    value={value}
    link={false}
    align={alginment}
    textoutput
  />;
};

class AllDistrictView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadingImage: false,
      uploadFailed: false,
      schools: undefined,
      page: 1,
      rowsPerPage: 10,
      singleDistrict: null,
      dialogOpen: false,
      dialogType: '',
      resizing: false,
      districtBannerMessage: "District banner message",
      districtBannerEnabled: false,
      displayBannerSaveConfirmation: false,
      appEtaDisplay: 'see_map',
      dashboardEtaAccess: false,
      studentAddingPermitted: false,
      securityCodeRequired: false,
      activityRegistrationOverride: false,
      displayRidershipRFIDColumn: false,
      ridershipEnabled: false,
      latenessThreshold: 0,
      distanceUnit: 1,
      displaySettingsSaveConfirmation: false,
      displaySettingsSaveError: false,
      displaySettingsSaveErrorMessage: '',
      districtSettingsSubmitValues: {},
      districtTypes: [],
      districtType: '',
      selectedDistrictType: '',
      districtTypeDialogOpen: false,
      districtTypeDisabled: false,
      districtTypeChangedDialogOpen: false
    };

    this.initialiser = this.initialiser.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
    this.handleConfirmButtonClick = this.handleConfirmButtonClick.bind(this);
    this.handleCancelButtonClick = this.handleCancelButtonClick.bind(this);
    this.handleBannerFormSubmit = this.handleBannerFormSubmit.bind(this);
    this.handleSettingsFormSubmit = this.handleSettingsFormSubmit.bind(this);
    this.uploadDistrictImage = this.uploadDistrictImage.bind(this);
    this.updateDistrictSettings = this.updateDistrictSettings.bind(this);
    this.renderUploadImageForm = this.renderUploadImageForm.bind(this);
    this.renderDistrictSettingsForm = this.renderDistrictSettingsForm.bind(this);
    this.renderTrackingSettingsForm = this.renderTrackingSettingsForm.bind(this);
    this.renderDistrictBannerForm = this.renderDistrictBannerForm.bind(this);
    this.renderDisableDistrictForm = this.renderDisableDistrictForm.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.closeDistrictTypeDialog = this.closeDistrictTypeDialog.bind(this);
    this.closeDistrictTypeChangedDialog = this.closeDistrictTypeChangedDialog.bind(this);
    this.handleUpdateDistrictType = this.handleUpdateDistrictType.bind(this);
    this.updateDistrictType = this.updateDistrictType.bind(this);

    this.resize = () => {
      const { resizing } = this.state;
      if (!resizing) {
        this.setState({ resizing: true }, () => setTimeout(() => {
          this.setState({ resizing: false });
        }, 1400));
      }
    };
  }

  componentDidMount() {
    let pauseForTokenRefresh = ls.get("pauseForTokenRefresh");
    if (pauseForTokenRefresh === "undefined" || pauseForTokenRefresh === null) {
      pauseForTokenRefresh = 1;
    }
    this.timer = setTimeout(this.initialiser, pauseForTokenRefresh);
    window.addEventListener("resize", this.resize);
  }

  UNSAFE_componentWillUnmount() {
    clearTimeout(this.timer);
    window.removeEventListener("resize", this.resize);
  }

  initialiser() {
    const { id } = this.props;

    dataLink(GET_ONE, "districts", {
      id
    }).then(response => {

      const getCurrentDistrictType = (dType) => {
        if (dType === "district_dashboard_only") {
          dType = "District Dashboard";
        }
        if (dType === "streetz") {
          dType = "Street";
        }
        if (dType === "student") {
          dType = "Student";
        }
        if (dType === "gps_student") {
          dType = "GPS Student";
        }
        if (dType === "gps_district_code") {
          dType = "GPS District Code";
        }
        if (dType === "gps_dashboard") {
          dType = "GPS Dashboard";
        }

        return dType;
      };

      const extractDistrictSettings = ({ settings = {} }) => ({
        appEtaDisplay: settings.app_eta_display,
        dashboardEtaAccess: settings.dashboard_eta_access,
        studentAddingPermitted: settings.student_adding_permitted,
        securityCodeRequired: settings.security_code_required,
        activityRegistrationOverride: settings.activity_registration_override,
        displayRidershipRFIDColumn: settings.ridership_rfid_tags_enabled,
        ridershipEnabled: settings.ridership_enabled,
        distanceUnit: settings.distance_unit,
        latenessThreshold: settings.lateness_threshold / 60,
        districtType: getCurrentDistrictType(settings.district_type),
      });

      if (response.error !== undefined) {
        if (response.error === "Unauthorized") {
          localforage.getItem("singleDistrict").then(value => {
            this.setState({ singleDistrict: value, ...extractDistrictSettings(value) });
          });
        }
      } else {
        localforage.setItem("singleDistrict", response).then(() => {
          this.setState({ capabilities: response.capabilities, singleDistrict: response.data, ...extractDistrictSettings(response.data), districtTypeDisabled: response.data.active_import }, () => {
            const { data: { id: districtId, settings: { id: districtSettingsId } } = {} } = response;
            dataLink(GET_ONE, "districtBanners", null, districtId).then(
              districtBannersResponse => {
                const { result } = districtBannersResponse.data || {};
                const {
                  enabled: districtBannerEnabled = false,
                  message: districtBannerMessage = ""
                } = result === null ? {} : result;
                this.setState({ districtBannerMessage, districtBannerEnabled });
              }
            );

            dataLink(GET_LIST, "districtTypes", null, districtSettingsId).then(
              districtTypesResponse => {
                const result = districtTypesResponse.data || {};
                const districtTypes = result === null ? [] : result;
                this.setState({ districtTypes, selectedDistrictType: districtTypes[0] });
              }
            );
          });
        });
      }
    });

    const { page, rowsPerPage } = this.state;

    dataLink(GET_LIST, "schools", {
      // Data for table
      pagination: { page, perPage: rowsPerPage },
      sort: { field: "id", direction: "ASC" },
      filter: { district_id: id }
    }).then(response => {
      if (response.error !== undefined) {
        if (response.error === "Unauthorized") {
          localforage.getItem("districtSchoolsCount").then(value => {
            this.setState({ schools: value });
          });
        }
      } else if (response.total === undefined) {
        localforage.getItem("districtSchoolsCount").then(value => {
          this.setState({ schools: value });
        });
      } else {
        localforage.setItem("districtSchoolsCount", response).then(() => {
          this.setState({ schools: response });
        });
      }
    });

    dataLink(GET_LIST, "schools", {
      // Data for map
      pagination: { page: 1, perPage: 500 },
      sort: { field: "id", direction: "ASC" },
      filter: { district_id: id }
    }).then(response => {
      if (response.error !== undefined) {
        if (response.error === "Unauthorized") {
          localforage.getItem("districtAllSchoolsCount").then(value => {
            this.setState({ allSchools: value });
          });
        }
      } else if (response.total === undefined) {
        localforage.getItem("districtAllSchoolsCount").then(value => {
          this.setState({ allSchools: value });
        });
      } else {
        localforage.setItem("districtAllSchoolsCount", response).then(() => {
          this.setState({ allSchools: response });
        });
      }
    });
  }

  handleChangePage(event, page) {
    this.setState({ page });
  }

  handleChangeRowsPerPage(event) {
    this.setState({ rowsPerPage: event.target.value });
  }

  handleSaveButtonClick() {
    this.setState({ dialogOpen: true });
  }

  handleConfirmButtonClick() {
    this.setState({ dialogOpen: false });
  }

  handleCancelButtonClick() {
    this.setState({ dialogOpen: false }, () => window.location.reload());
  }

  handleDistrictTypeChange = event => {
    this.setState({ selectedDistrictType: event.target.value });
  }

  handleDisableDistrictFormSubmit(values) {
    const {
      singleDistrict: { id: districtId }
    } = this.state;
    authProvider(AUTH_CHECK, {}).then(
      () => {
        dataLink(UPDATE, "district", values, districtId).then(
          districtBannersResponse => {
            const {
              result: {
                enabled: districtBannerEnabled,
                message: districtBannerMessage
              } = {}
            } = districtBannersResponse.data || {};
            this.setState({
              districtBannerMessage,
              districtBannerEnabled,
              displayBannerSaveConfirmation: true
            });
          }
        );
      }
    );
  }

  handleSettingsFormSubmit(values, type = 'settings') {
    let dialogType = type;
    if (type === 'tracking') {
      dialogType = 'settings';
    }

    this.setState({
      districtSettingsSubmitValues: values,
      dialogOpen: true,
      dialogType: dialogType
    });
  }

  handleBannerFormSubmit(values) {
    const { translate } = this.props;
    const {
      singleDistrict: { id: districtId }
    } = this.state;
    authProvider(AUTH_CHECK, {}).then(
      () => {
        dataLink(UPDATE, "districtBanners", values, districtId).then(
          districtBannersResponse => {
            const {
              result: {
                enabled: districtBannerEnabled,
                message: districtBannerMessage
              } = {}
            } = districtBannersResponse.data || {};
            this.setState({
              districtBannerMessage,
              districtBannerEnabled,
              displayBannerSaveConfirmation: true
            });
          }
        ).catch(() => {
          this.setState({
            displaySettingsSaveError: true,
            displaySettingsSaveErrorMessage: translate('resources.districts.error'),
          });
        });;
      }
    );
  }

  closeDialog() {
    const { dispatch } = this.props;
    dispatch(reset('districtSettings'));
    this.setState({ dialogOpen: false });
  }

  closeDistrictTypeDialog() {
    this.setState({ districtTypeDialogOpen: false });
  }

  closeDistrictTypeChangedDialog() {
    this.setState({ districtTypeChangedDialogOpen: false });
  }

  uploadDistrictImage = file => {
    this.setState({ uploadingImage: true }, () => {
      const formData = new FormData();
      formData.append("logo_attachment", file);
      const {
        singleDistrict: { settings: {
          id: districtId
        } }
      } = this.state;
      authProvider(AUTH_CHECK, {}).then(
        () => {
          dataLink(UPDATE, "districts", formData, districtId).then(
            districtSettingsResponse => {
              const {
                result: {
                  logo
                } = {}
              } = districtSettingsResponse.data || {};
              this.setState({
                singleDistrict: {
                  ...this.state.singleDistrict,
                  settings: {
                    ...this.state.singleDistrict.settings,
                    logo
                  }
                },
                uploadFailed: false
              });
            }
          ).catch(
            () => this.setState({ uploadFailed: true })
          ).finally(
            () => this.setState({ uploadingImage: false })
          );
        }
      );
    });
  };

  updateDistrictSettings() {
    const { translate } = this.props;
    this.setState({ dialogOpen: false }, () => {

      const {
        singleDistrict: { id: districtId, settings: { id: districtSettingsId } },
        districtSettingsSubmitValues: values,
        dialogType,
      } = this.state;

      const id = dialogType === 'settings' ? districtSettingsId : districtId;
      if (dialogType === 'settings' && values?.distance_unit && Number.isInteger(values.distance_unit)) {
        if (values.distance_unit === 1) {
          values.distance_unit = 'mile';
        } else {
          values.distance_unit = 'kilometre';
        }
      }

      authProvider(AUTH_CHECK, {}).then(
        () => {
          dataLink(UPDATE, "districts", values, id).then(
            districtSettingsResponse => {
              if (dialogType === 'settings') {
                const {
                  result: {
                    app_eta_display: appEtaDisplay,
                    dashboard_eta_access: dashboardEtaAccess,
                    student_adding_permitted: studentAddingPermitted,
                    security_code_required: securityCodeRequired,
                    activity_registration_override: activityRegistrationOverride,
                    ridership_rfid_tags_enabled: displayRidershipRFIDColumn,
                    lateness_threshold: latenessThreshold,
                    distance_unit: distanceUnit
                  } = {}
                } = districtSettingsResponse.data || {};
                return this.setState({
                  appEtaDisplay,
                  dashboardEtaAccess,
                  studentAddingPermitted,
                  securityCodeRequired,
                  activityRegistrationOverride,
                  displayRidershipRFIDColumn,
                  displaySettingsSaveConfirmation: true,
                  latenessThreshold: latenessThreshold / 60,
                  distanceUnit: distanceUnit,
                  singleDistrict: {
                    ...this.state.singleDistrict,
                    settings: {
                      ...this.state.singleDistrict.settings,
                      lateness_threshold: latenessThreshold,
                      distance_unit: distanceUnit,
                    }
                  }
                });
              } else {
                const {
                  result = {}
                } = districtSettingsResponse.data || {};
                this.setState({
                  singleDistrict: result,
                  displaySettingsSaveConfirmation: true
                });
              }
            }
          ).catch(() => {
            this.setState({
              displaySettingsSaveError: true,
              displaySettingsSaveErrorMessage: translate('resources.districts.error'),
            });
          });
        }
      );
    });
  }

  handleUpdateDistrictType = () => {
    this.setState({ districtTypeDialogOpen: true });
  }

  updateDistrictType = () => {
    const { translate } = this.props;
    this.setState({ districtTypeDialogOpen: false }, () => {

      const {
        singleDistrict: { settings: { id: districtSettingsId } },
        selectedDistrictType,
      } = this.state;

      authProvider(AUTH_CHECK, {}).then(
        () => {
          dataLink(UPDATE, "districtTypes", { district_type: selectedDistrictType }, districtSettingsId).then(
            districtTypeResponse => {
              const {
                result: {
                  id
                } = {}
              } = districtTypeResponse.data || {};
              this.setState({ districtType: selectedDistrictType, districtTypeDialogOpen: false, districtTypeDisabled: true });
              dataLink(GET_LIST, "districtTypes", null, id).then(
                districtTypesResponse => {
                  const result = districtTypesResponse.data || {};
                  const districtTypes = result === null ? [] : result;
                  this.setState({ districtTypes, selectedDistrictType: districtTypes[0], districtTypeChangedDialogOpen: true });
                }
              );
            }
          ).catch(() => {
            this.setState({
              displaySettingsSaveError: true,
              displaySettingsSaveErrorMessage: translate('resources.districts.error'),
            });
          });
        }
      ).catch(() => {
        this.setState({
          displaySettingsSaveError: true,
          displaySettingsSaveErrorMessage: translate('resources.districts.error'),
        });
      });
    });
  }

  renderDisableDistrictForm(transl) {
    const { singleDistrict: {
      enabled
    } } = this.state;
    return (
      <DistrictForm
        type={'enabled'}
        enableReinitialize
        options={[
          { name: 'enabled', type: 'checkbox', value: enabled }
        ]}
        onSubmit={(values) => this.handleSettingsFormSubmit(values, 'enabled')}
        header={transl(
          "resources.districts.section.enabled"
        )}
      />
    );
  }

  renderUploadImageForm() {
    const { capabilities } = this.state;
    const canUploadImage = getCapability(capabilities, 'upload_logo', 'district')
    if (!canUploadImage) {
      return
    }
    return <UploadForm
      uploadImage={this.uploadDistrictImage}
      processing={this.state.uploadingImage}
      uploadFailure={this.state.uploadFailed}
    />
  }

  renderDistrictTypeForm(transl) {
    const {
      districtTypeDisabled,
      capabilities,
      districtTypes
    } = this.state;



    const canChangeDistrictType = getCapability(capabilities, 'district_type', 'district')
    return (
      <div style={{ padding: 10 }}>
        <h2 style={styles.cardHeader}>
          {transl("resources.districts.section.type")}
        </h2>
        <p>{this.state.districtType}</p>
        {
          canChangeDistrictType && districtTypes && districtTypes.length > 0 &&
          <FormControl style={{ width: '100%' }}>
            <label style={styles.label}>{transl("resources.districts.section.changetype")}</label>
            <Select
              id="district-type-dropdown"
              value={this.state.selectedDistrictType}
              onChange={this.handleDistrictTypeChange}
              disabled={districtTypeDisabled}
            >
              {this.state.districtTypes.map((v, k) => (
                <MenuItem key={k} value={v}>{v}</MenuItem>
              ))}
            </Select>
            <Toolbar style={{ justifyContent: 'flex-end', marginTop: 16, padding: 0 }}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                onClick={this.handleUpdateDistrictType}
                disabled={districtTypeDisabled}
              >
                <SaveIcon style={{ marginRight: '0.5em' }} />
                Save
              </Button>
            </Toolbar>
          </FormControl>
        }
      </div>
    )
  }

  renderDistrictSettingsForm(transl) {

    const {
      capabilities,
      dashboardEtaAccess,
      studentAddingPermitted,
      securityCodeRequired,
      displayRidershipRFIDColumn,
      ridershipEnabled,
      activityRegistrationOverride,
      latenessThreshold,
      distanceUnit
    } = this.state;

    let newDistanceUnit = distanceUnit;

    if (distanceUnit === 'mile' || distanceUnit === 'kilometre') {
      newDistanceUnit = distanceUnit === 'mile' ? 1 : 2;
    }

    let options = [
      { name: 'dashboard_eta_access', type: 'checkbox', value: dashboardEtaAccess },
      { name: 'student_adding_permitted', type: 'checkbox', value: studentAddingPermitted },
      { name: 'security_code_required', type: 'checkbox', value: securityCodeRequired },
      { name: 'activity_registration_override', type: 'checkbox', value: activityRegistrationOverride },
      { name: 'lateness_threshold', type: 'slider', value: latenessThreshold, min: 5, max: 50, step: 5, labels: { 0: '5', 10: '10', 15: '15', 20: '20', 25: '25', 30: '30', 35: '35', 40: '40', 45: '45', 50: '50' } },
      { name: 'distance_unit', type: 'slider', value: newDistanceUnit, min: 1, max: 2, step: 1, labels: { 1: 'mile', 2: 'k/m' } },
    ]

    if (ridershipEnabled) {
      options.push({ name: 'ridership_rfid_tags_enabled', type: 'checkbox', value: displayRidershipRFIDColumn })
    }

    options = options.filter(option => getCapability(capabilities, option.name, 'district'))

    if (options.length === 0) {
      return
    }

    return (
      <DistrictForm
        type={"settings"}
        enableReinitialize
        onSubmit={(values) => this.handleSettingsFormSubmit(values, 'settings')}
        header={transl(
          "resources.districts.section.settings"
        )}
        options={options}
        showRFID={ridershipEnabled}
      />
    );
  }

  renderTrackingSettingsForm(transl) {

    const {
      capabilities,
      appEtaDisplay,
      ridershipEnabled,
    } = this.state;

    let options = [
      { name: 'app_eta_display', type: 'radio', value: appEtaDisplay },
    ]

    options = options.filter(option => getCapability(capabilities, option.name, 'district'))

    if (options.length === 0) {
      return
    }

    return (
      <DistrictForm
        type={"tracking"}
        enableReinitialize
        onSubmit={(values) => this.handleSettingsFormSubmit(values, 'tracking')}
        header={transl(
          "resources.districts.section.settings"
        )}
        options={options}
        showRFID={ridershipEnabled}
      />
    );
  }

  renderDistrictBannerForm() {
    const { districtBannerEnabled, districtBannerMessage, capabilities } = this.state;
    const canEditBanner = getCapability(capabilities, 'banner_message', 'district')
    if (!canEditBanner) {
      return
    }

    return (
      <DistrictForm
        type={'bannerMessage'}
        enableReinitialize
        options={[
          { name: 'enabled', type: 'checkbox', value: districtBannerEnabled },
          { name: 'message', type: 'text', value: districtBannerMessage }
        ]}
        onSubmit={this.handleBannerFormSubmit}
      />
    );
  }


  renderSunriseSunset() {
    const { resizing, singleDistrict = {} } = this.state;
    const { location = {} } = singleDistrict;
    const { lat, lng } = location;

    if (resizing) {
      return (
        <div
          style={{
            display: "flex",
            marginBottom: 20,
            justifyContent: "center"
          }}
        >
          <CircularProgress />
        </div>
      );
    }
    return (
      <Card style={styles.sunriseSunsetContainer}>
        <ReactResizeDetector handleWidth handleHeight>
          {({ width }) => <SunriseSunsetMarker lat={lat} lng={lng} width={width} />
          }
        </ReactResizeDetector>
      </Card>
    );
  }

  render() {
    const {
      allSchools,
      schools,
      singleDistrict,
      displayBannerSaveConfirmation,
      displaySettingsSaveConfirmation,
      displaySettingsSaveError,
      displaySettingsSaveErrorMessage,
      dialogOpen,
      districtTypeDialogOpen,
      districtTypeChangedDialogOpen
    } = this.state;

    const {
      id, translate: transl, dispatch, ...restProps
    } = this.props;

    if (
      allSchools !== undefined
      && schools !== undefined
      && singleDistrict !== null
      && singleDistrict !== undefined
    ) {
      const headerContent = `Schools for ${singleDistrict.name}`;
      let dType = singleDistrict.settings.district_type;
      if (dType === "district_dashboard_only") {
        dType = "District Dashboard";
      }
      if (dType === "streetz") {
        dType = "Street";
      }
      if (dType === "student") {
        dType = "Student";
      }
      if (dType === "gps_student") {
        dType = "GPS Student";
      }
      if (dType === "gps_district_code") {
        dType = "GPS District Code";
      }
      if (dType === "gps_dashboard") {
        dType = "GPS Dashboard";
      }

      let districtLogo = (singleDistrict.settings || {}).logo || "";
      if (!districtLogo || districtLogo === "defaults/logos/default_district_logo.png") {
        districtLogo = defaultDistrictLogo;
      }
      const districtHeader = (
        <React.Fragment>
          <img
            style={
              districtLogo === defaultDistrictLogo
                ? styles.fadedLogo
                : styles.logo
            }
            src={districtLogo}
            alt={`${singleDistrict.name} Logo`}
          />
          {singleDistrict.name}
        </React.Fragment>
      );

      const parents = singleDistrict.stats.users_count;
      const parentsfollowing = singleDistrict.stats.users_following_students_count;
      const users = singleDistrict.stats.district_dashboard_users_count;
      const actTrackEnabled = singleDistrict.settings.activity_tracking_enabled ? 'Yes' : 'No';
      const actTrackCount = singleDistrict.stats.users_following_activity_students_count;
      const newProfile = extractProfile();
      if (newProfile) {
        pageAnalytic(newProfile, { title: 'Districts View' });
      }

      const districtFilter = { district: { id: singleDistrict.id } };

      return (
        <React.Fragment>
          <Responsive
            small={
              <div style={styles.singleColSmall}>
                <div style={styles.flexColumn}>
                  <div style={styles.deepRow}>
                    <Header
                      content={districtHeader}
                      orientation="left"
                      pushleft="0px"
                    />
                  </div>
                  <div style={styles.deepRow}>
                    <Total
                      text="pos.dashboard.total_parents"
                      color={config.colors.backgroundGreen}
                      icon={parentIcon}
                      value={parents}
                      link="users"
                      filter={districtFilter}
                    />
                  </div>
                  <div style={styles.deepRow}>
                    <Total
                      text="pos.dashboard.total_parents_following"
                      color={config.colors.backgroundGreen}
                      icon={parentIcon}
                      value={parentsfollowing}
                      link={false}
                    />
                  </div>
                  <div style={styles.deepRow}>
                    <Total
                      text="pos.dashboard.total_users"
                      color={config.colors.backgroundGreen}
                      icon={usersIcon}
                      value={users}
                      link={false}
                    />
                  </div>
                  <div style={styles.deepRow}>
                    <Total
                      text="pos.dashboard.total_students"
                      color={config.colors.backgroundOrange}
                      icon={studentsIcon}
                      value={singleDistrict.stats.students_count}
                      link="students"
                      filter={districtFilter}
                    />
                  </div>
                  <div style={styles.deepRow}>
                    <Total
                      text="pos.dashboard.total_schools"
                      color={config.colors.backgroundOrange}
                      icon={schoolsIcon}
                      value={singleDistrict.stats.schools_count}
                      link={false}
                    />
                  </div>
                  <div style={styles.deepRow}>
                    <Total
                      text="pos.dashboard.district_type"
                      color={config.colors.backgroundBlue}
                      icon={allDistrictsIcon}
                      value={dType}
                      link={false}
                      textoutput
                    />
                  </div>
                  <div style={styles.deepRow}>
                    <Total
                      text="pos.dashboard.activity_tracking"
                      color={config.colors.backgroundOrange}
                      icon={ActivityStudentsIconWhite}
                      value={actTrackEnabled}
                      link={false}
                      textoutput
                    />
                  </div>
                  <div style={styles.deepRow}>
                    {renderActivityTrackingTotal(actTrackEnabled, actTrackCount)}
                  </div>
                  <div style={styles.deepRow}>
                    <Show style={styles.deepRow} {...restProps} id={id}>
                      <SimpleShowLayout>
                        <h2 style={styles.cardHeader}>
                          {transl("resources.districts.section.details")}
                        </h2>
                        <TextField source="id" label="ID" />
                        <TextField source="name" label="District" />
                        <TextField source="state.name" label="State" />
                        <TimeZoneField style={{ minHeight: 0 }} />
                        <FunctionField
                          label="Distance Unit"
                          render={(_v) => { return `${this.state.singleDistrict.settings.distance_unit}` }}
                        />
                        <LatenessThresholdField latenessThreshold={this.state.singleDistrict.settings.lateness_threshold} />
                        {this.state.uploadingImage
                          ? <Spinner margin='2em 0' />
                          : <img
                            style={styles.bigLogoSmallView}
                            src={districtLogo}
                            alt={`${singleDistrict.name} Logo`}
                          />
                        }
                        {this.renderUploadImageForm()}
                      </SimpleShowLayout>
                    </Show>
                    <Show style={styles.deepRow} title=" " {...restProps} id={id}>
                      {this.renderDistrictTypeForm(transl)}
                    </Show>
                  </div>
                  <Card style={styles.deepRow}>
                    {this.renderDisableDistrictForm(transl)}
                  </Card>
                  <Card style={styles.deepRow}>
                    {this.renderTrackingSettingsForm(transl)}
                  </Card>
                  <Card style={styles.deepRow}>
                    {this.renderDistrictSettingsForm(transl)}
                  </Card>
                  <Card style={styles.deepRow}>
                    {this.renderDistrictBannerForm(transl)}
                  </Card>
                  <div style={styles.deepRow}>
                    <div style={styles.mapCard}>
                      <Header content={headerContent} orientation="left" />
                      <div style={styles.mapCol}>
                        <HubGoogleMap
                          schools={allSchools.data}
                          zoom={13}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={styles.deepRow}>
                    <SchoolPaginationTable id={id} />
                  </div>
                  <div style={styles.deepRow}>
                    <AdminPaginationTable parent="districtadmin" id={id} />
                  </div>
                  <div style={styles.deepRow}>
                    <AdminPaginationTable parent="districtuser" id={id} />
                  </div>
                </div>
              </div>
            }
            medium={
              <div>
                <div style={{ marginRight: "1em" }}>
                  <div>
                    <Header
                      content={districtHeader}
                      orientation="left"
                      pushleft="0px"
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        ...styles.mapCard,
                        paddingTop: 1
                      }}
                    >
                      <Header content={headerContent} orientation="left" />
                      <div style={{ ...styles.mapCol, height: 300 }}>
                        <HubGoogleMap
                          schools={allSchools.data}
                          zoom={13}
                          height={300}
                        />
                      </div>
                    </div>
                  </div>
                  {this.renderSunriseSunset()}
                </div>
                <div>
                  <div style={styles.leftColMainThinner}>
                    <div
                      style={{
                        ...styles.flexRow,
                        justifyContent: "space-between"
                      }}
                    >
                      <Total
                        text="pos.dashboard.total_parents"
                        color={config.colors.backgroundGreen}
                        icon={parentIcon}
                        value={parents}
                        link="users"
                        filter={districtFilter}
                      />
                      <Total
                        text="pos.dashboard.total_parents_following"
                        color={config.colors.backgroundGreen}
                        icon={parentIcon}
                        value={parentsfollowing}
                        link={false}
                      />
                      <Total
                        text="pos.dashboard.total_users"
                        color={config.colors.backgroundGreen}
                        icon={usersIcon}
                        value={users}
                        link={false}
                      />
                    </div>
                    <div
                      style={{
                        ...styles.flexRow,
                        justifyContent: "space-between"
                      }}
                    >
                      <Total
                        text="pos.dashboard.total_students"
                        color={config.colors.backgroundOrange}
                        icon={studentsIcon}
                        value={singleDistrict.stats.students_count}
                        link="students"
                        filter={districtFilter}
                      />
                      <Total
                        text="pos.dashboard.total_schools"
                        color={config.colors.backgroundOrange}
                        icon={schoolsIcon}
                        value={singleDistrict.stats.schools_count}
                        link={false}
                      />
                      <Total
                        text="pos.dashboard.district_type"
                        color={config.colors.backgroundBlue}
                        icon={allDistrictsIcon}
                        value={dType}
                        link={false}
                        textoutput
                      />
                    </div>
                    <div
                      style={{
                        ...styles.flexDeepRow,
                        justifyContent: "space-between"
                      }}
                    >
                      <Total
                        text="pos.dashboard.activity_tracking"
                        color={config.colors.backgroundOrange}
                        icon={ActivityStudentsIconWhite}
                        value={actTrackEnabled}
                        link={false}
                        textoutput
                      />
                      {renderActivityTrackingTotal(actTrackEnabled, actTrackCount)}
                      <Total empty />
                    </div>
                    <div style={styles.flexColumn}>
                      <div style={styles.deepRow}>
                        <SchoolPaginationTable id={id} />
                      </div>
                      <div style={styles.flexRow}>
                        <div style={styles.leftCol}>
                          <Show style={styles.deepRow} {...restProps} id={id}>
                            <SimpleShowLayout>
                              <h2 style={styles.cardHeader}>
                                {transl("resources.districts.section.details")}
                              </h2>
                              <TextField source="id" label="ID" />
                              <TextField source="name" label="District" />
                              <TextField source="state.name" label="State" />
                              <TimeZoneField style={{ minHeight: 0 }} />
                              <FunctionField
                                label="Distance Unit"
                                render={(_v) => { return `${this.state.singleDistrict.settings.distance_unit}` }}
                              />
                              <LatenessThresholdField latenessThreshold={this.state.singleDistrict.settings.lateness_threshold} />
                              {this.state.uploadingImage
                                ? <Spinner margin='2em 0' />
                                : <img
                                  style={styles.bigLogoSmallView}
                                  src={districtLogo}
                                  alt={`${singleDistrict.name} Logo`}
                                />
                              }
                              {this.renderUploadImageForm()}
                            </SimpleShowLayout>
                          </Show>
                          <Show style={styles.deepRow} title=" " {...restProps} id={id}>
                            {this.renderDistrictTypeForm(transl)}
                          </Show>
                        </div>
                        <div style={styles.rightCol}>
                          <Card style={styles.deepRow}>
                            {this.renderDisableDistrictForm(transl)}
                          </Card>
                          <Card style={styles.deepRow}>
                            {this.renderTrackingSettingsForm(transl)}
                          </Card>
                          <Card style={styles.deepRow}>
                            {this.renderDistrictSettingsForm(transl)}
                          </Card>
                          <Card style={styles.deepRow}>
                            {this.renderDistrictBannerForm(transl)}
                          </Card>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      ...styles.rightColMainWider,
                      marginLeft: 0,
                      marginRight: "1em"
                    }}
                  >
                    <div style={styles.flexColumn}>
                      <div style={styles.shallowRow}>
                        <AdminPaginationTable parent="districtadmin" id={id} />
                      </div>
                      <div style={styles.shallowRow}>
                        <AdminPaginationTable parent="districtuser" id={id} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            large={
              <div>
                <div style={styles.flex}>
                  <div style={styles.leftCol}>
                    <Header
                      content={districtHeader}
                      orientation="left"
                      pushleft="0px"
                    />
                  </div>
                </div>

                <div style={styles.flex}>
                  <div style={styles.leftColMainThinner}>
                    <div style={styles.flexRow}>
                      <Total
                        text="pos.dashboard.total_parents"
                        color={config.colors.backgroundGreen}
                        icon={parentIcon}
                        value={parents}
                        link="users"
                        filter={districtFilter}
                        align="left"
                      />
                      <Total
                        text="pos.dashboard.total_parents_following"
                        color={config.colors.backgroundGreen}
                        icon={parentIcon}
                        value={parentsfollowing}
                        link={false}
                        align="right"
                      />
                    </div>
                    <div style={styles.flexRow}>
                      <Total
                        text="pos.dashboard.total_users"
                        color={config.colors.backgroundGreen}
                        icon={usersIcon}
                        value={users}
                        link={false}
                        align="left"
                      />
                      <Total
                        text="pos.dashboard.total_students"
                        color={config.colors.backgroundOrange}
                        icon={studentsIcon}
                        value={singleDistrict.stats.students_count}
                        link="students"
                        filter={districtFilter}
                        align="right"
                      />
                    </div>
                    <div style={styles.flexRow}>
                      <Total
                        text="pos.dashboard.total_schools"
                        color={config.colors.backgroundOrange}
                        icon={schoolsIcon}
                        value={singleDistrict.stats.schools_count}
                        link={false}
                        align="left"
                      />

                      <Total
                        text="pos.dashboard.district_type"
                        color={config.colors.backgroundBlue}
                        icon={allDistrictsIcon}
                        value={dType}
                        link={false}
                        align="right"
                        textoutput
                      />
                    </div>
                    <div style={styles.flexDeepRow}>
                      <Total
                        text="pos.dashboard.activity_tracking"
                        color={config.colors.backgroundOrange}
                        icon={ActivityStudentsIconWhite}
                        value={actTrackEnabled}
                        link={false}
                        textoutput
                      />
                      {renderActivityTrackingTotal(actTrackEnabled, actTrackCount, "right")}
                    </div>
                    <div style={styles.flexColumn}>
                      <div style={styles.deepRow}>
                        <SchoolPaginationTable id={id} />
                      </div>
                      <div style={styles.flexRow}>
                        <div style={styles.leftCol}>
                          <Show style={styles.deepRow} {...restProps} id={id}>
                            <SimpleShowLayout>
                              <h2 style={styles.cardHeader}>
                                {transl("resources.districts.section.details")}
                              </h2>
                              <TextField source="id" label="ID" />
                              <TextField source="name" label="District" />
                              <TextField source="state.name" label="State" />
                              <TimeZoneField style={{ minHeight: 0 }} />
                              <FunctionField
                                label="Distance Unit"
                                render={(_v) => { return `${this.state.singleDistrict.settings.distance_unit}` }}
                              />
                              <LatenessThresholdField latenessThreshold={this.state.singleDistrict.settings.lateness_threshold} />
                              {this.state.uploadingImage
                                ? <Spinner margin='2em 0' />
                                : <img
                                  style={styles.bigLogoLargeView}
                                  src={districtLogo}
                                  alt={`${singleDistrict.name} Logo`}
                                />
                              }
                              {this.renderUploadImageForm()}
                            </SimpleShowLayout>
                          </Show>
                          <Show style={styles.deepRow} title=" " {...restProps} id={id}>
                            {this.renderDistrictTypeForm(transl)}
                          </Show>
                        </div>
                        <div style={styles.rightCol}>
                          <Card style={styles.deepRow}>
                            {this.renderDisableDistrictForm(transl)}
                          </Card>
                          <Card style={styles.deepRow}>
                            {this.renderTrackingSettingsForm(transl)}
                          </Card>
                          <Card style={styles.deepRow}>
                            {this.renderDistrictSettingsForm(transl)}
                          </Card>
                          <Card style={styles.deepRow}>
                            {this.renderDistrictBannerForm(transl)}
                          </Card>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={styles.rightColMainWider}>
                    <div style={styles.flexColumn}>
                      <div style={styles.shallowRow}>
                        <div style={styles.mapCard}>
                          <Header content={headerContent} orientation="left" />
                          <div style={styles.mapCol}>
                            <HubGoogleMap
                              schools={allSchools.data}
                              zoom={13}
                            />
                          </div>
                        </div>
                      </div>
                      {this.renderSunriseSunset()}
                      <div style={styles.shallowRow}>
                        <AdminPaginationTable parent="districtadmin" id={id} />
                      </div>
                      <div style={styles.shallowRow}>
                        <AdminPaginationTable parent="districtuser" id={id} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          />
          <Snackbar
            open={displayBannerSaveConfirmation}
            onClose={() => this.setState({ displayBannerSaveConfirmation: false })
            }
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            autoHideDuration={2000}
            message={<span id="message-id">Banner settings saved!</span>}
          />
          <Snackbar
            open={displaySettingsSaveConfirmation}
            onClose={() => this.setState({ displaySettingsSaveConfirmation: false })
            }
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            autoHideDuration={2000}
            message={<span id="message-id">District settings saved!</span>}
          />
          <Snackbar
            open={displaySettingsSaveError}
            onClose={() => this.setState({
              displaySettingsSaveError: false,
              displaySettingsSaveErrorMessage: '',
            })
            }
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            autoHideDuration={2000}
          >
            <SnackbarContent style={{
              backgroundColor: '#B33A3A',
            }}
              message={<span id="message-id">{displaySettingsSaveErrorMessage}</span>}
            />
          </Snackbar>
          <DialogMessage
            dialogOpen={dialogOpen}
            titleText={transl("general.confirmation.title")}
            contentText={transl("general.confirmation.content")}
            closeFunction={this.closeDialog}
            closeButtonText={transl("general.confirmation.cancel")}
            saveButton={(
              <SaveButton
                label="general.confirmation.confirm"
                handleSubmitWithRedirect={this.updateDistrictSettings}
                variant={null}
                icon={<span />}
                style={styles.confirmButton}
              />
            )}
          />
          <DialogMessage
            dialogOpen={districtTypeDialogOpen}
            titleText={transl("general.confirmation.title")}
            contentText={transl("general.confirmation.contentDistrictType")}
            closeFunction={this.closeDistrictTypeDialog}
            closeButtonText={transl("general.confirmation.cancel")}
            saveButton={(
              <SaveButton
                label="general.confirmation.confirm"
                handleSubmitWithRedirect={this.updateDistrictType}
                variant={null}
                icon={<span />}
                style={styles.confirmButton}
              />
            )}
          />
          <DialogMessage
            dialogOpen={districtTypeChangedDialogOpen}
            titleText={transl("general.confirmation.titleDistrictTypeChanged")}
            contentText={transl("general.confirmation.contentDistrictTypeChanged")}
            closeFunction={this.closeDistrictTypeChangedDialog}
            closeButtonText={transl("general.confirmation.close")}
          />
        </React.Fragment>
      );
    }
    return <Spinner />;
  }
}

const translatedComponent = translate(AllDistrictView);
const styledComponent = withStyles(reactAdminStyles)(translatedComponent);
export default connect()(styledComponent);
