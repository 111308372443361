import Card from "@material-ui/core/Card";
import withStyles from "@material-ui/core/styles/withStyles";
import usersIcon from "@material-ui/icons/People";
import React from "react";
import {
  Datagrid,
  Filter,
  List,
  NumberField,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput
} from "react-admin";
import SecureLS from "secure-ls";

import extractProfile from "../analytics_config/extractProfile";
import { pageAnalytic } from "../analytics_config/plugins/setAnalytic";
import AuthenticationError from "../components/AuthenticationError";
import CardIcon from "../components/CardIcon";
import Header from "../components/Header";
import config from "../conf/common";
import ListActions from "../components/ListActions";

const ls = new SecureLS({ encodingType: "aes" });

const styles = theme => ({
  rowEven: {
    backgroundColor: "#F8F8F8"
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  table: {
    minWidth: 500
  },
  tableWrapper: {
    overflowX: "auto"
  },
  main: {
    flex: "1",
    marginRight: "1em",
    marginTop: 20
  },
  card: {
    overflow: "inherit",
    textAlign: "left",
    padding: 16,
    minHeight: 52,
    marginRight: '1em'
  }
});

const UserFilter = props => (
  <Filter {...props}>
    <TextInput
      label="Search First Names"
      source="first_name"
      defaultValue=""
      alwayson="true"
    />
    <TextInput label="Search Last Names" source="last_name" defaultValue="" alwayson="true" />
    <TextInput label="Search Emails" source="email" defaultValue="" alwayson="true" />
    <TextInput
      style={{ width: 200 }}
      label="Search Phone Numbers"
      source="phone"
      defaultValue=""
      alwayson="true"
    />
    <NumberInput
      style={{ width: 190 }}
      label="Search Student Count"
      source="students_count"
      alwayson="true"
    />
    <NumberInput style={{ width: 165 }} label="Search Login Count" source="login_count" alwayson="true" />
    <SelectInput
      style={{ width: 170 }}
      label="Filter By User Status"
      source="status"
      choices={[
        { id: "confirmed", name: "confirmed" },
        { id: "unconfirmed", name: "unconfirmed" },
        { id: "suspended", name: "suspended" }
      ]}
      alwayson="true"
    />
    <ReferenceInput
      label="Filter By District"
      source="district.id"
      reference="districts"
      allowEmpty
      perPage={1000000}
      alwayson="true"
      filter={{ simple: true }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <SelectInput
      style={{ width: 185 }}
      label="Filter By User Type"
      source="district_dashboard_user"
      choices={[{ id: "false", name: "Parent" }, { id: "true", name: "Admin" }]}
      alwayson="true"
    />
  </Filter>
);

const UsersList = withStyles(styles)(({ classes, ...props }) => {
  const headerContent = "All Users";

  const newProfile = extractProfile();
  if (newProfile) {
    pageAnalytic(newProfile, { title: 'Users Listing' });
  }

  const authenticated = ls.get("authenticated");

  if (authenticated === "yes") {
    return (
      <React.Fragment>
        <Card className={classes.card}>
          <CardIcon
            Icon={usersIcon}
            bgColor={config.colors.backgroundGreen}
            orientation="left"
          />
          <Header content={headerContent} orientation="left" />
          <List
            title="All Users"
            {...props}
            bulkActionButtons={false}
            filters={<UserFilter />}
            actions={<ListActions type="users" />}
            filterDefaultValues={{ status: "confirmed" }}
          >
            <Datagrid rowClick="show" classes={{ rowEven: classes.rowEven }}>
              <TextField source="first_name" label="First Name" />
              <TextField source="last_name" label="Last Name" />
              <TextField source="email" label="Email" />
              <TextField source="phone" label="Phone Number" sortable={false} />
              <TextField source="status" label="Status" sortable={false} />
              <NumberField source="students_count" label="Student Count" />
              <NumberField
                source="login_count"
                label="Login Count"
              />
            </Datagrid>
          </List>
        </Card>
      </React.Fragment>
    );
  }
  return <AuthenticationError />;
});

export default UsersList;
