import Card from "@material-ui/core/Card";
import ServiceAlertsIcon from "@material-ui/icons/NotificationsActive";
import React from "react";
import {
  DateField, Show, SimpleShowLayout, TextField
} from "react-admin";
import withStyles from '@material-ui/core/styles/withStyles';

import extractProfile from "../analytics_config/extractProfile";
import { pageAnalytic } from "../analytics_config/plugins/setAnalytic";
import CardIcon from "../components/CardIconTwo";
import Header from "../components/Header";
import config from "../conf/common";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  main: {
    flex: "1",
    marginRight: "1em",
    marginTop: 20
  },
  card: {
    overflow: "inherit",
    textAlign: "left",
    padding: 16,
    minHeight: 52
  },
  noActions: {
    marginTop: '60px',
    marginLeft: '15px'
  }
});

const ServiceAlertsView = props => {
  const headerContent = "Service Alert Details";

  const newProfile = extractProfile();
  if (newProfile) {
    pageAnalytic(newProfile, { title: 'Service Alert View' });
  }

  return (
    <Card className={styles.card}>
      <CardIcon
        Icon={ServiceAlertsIcon}
        bgColor={config.colors.backgroundRed}
      />
      <Header content={headerContent} orientation="left" />
      <Show title="Service Alert Details" {...props}>
        <SimpleShowLayout>
          <DateField source="date" label="Date" />
          <TextField source="category" label="Category" />
          <TextField source="message_type" label="Message Type" />
          <TextField source="title" label="Title" />
          <TextField source="message" label="Message" />
          <TextField source="message_id" label="Message ID" />
          <TextField source="dispatch_type" label="Dispatch Type" />
          <TextField source="contractor_id" label="Contractor ID" />
          <TextField source="daily_task_id" label="Daily Task ID" />
          <TextField source="route" label="Route" />
          <TextField source="origin" label="Origin" />
        </SimpleShowLayout>
      </Show>
    </Card>
  );
};

export default withStyles(styles)(ServiceAlertsView);
