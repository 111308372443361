import React from 'react';
import { connect } from 'react-redux';
import { getResources, Responsive } from 'react-admin';
import { withRouter } from 'react-router-dom';
import MyMenuItemLink from './MyMenuItemLink';

const CustomMenu = ({ resources, onMenuClick, logout }) => (
  <div>
    {resources.map(resource => {
      let {name} = resource;
      if (resource.options.label) {
        name = resource.options.label;
      }
      return (
        <MyMenuItemLink
          to={`/${resource.name}`}
          primaryText={name}
          key={resource.name}
          onClick={onMenuClick}
          leftIcon={<resource.icon />}
        />
      );
    })}

    <Responsive
      small={logout}
      medium={null} // Pass null to render nothing on larger devices
    />
  </div>
);

const mapStateToProps = state => ({
  resources: getResources(state)
});

export default withRouter(connect(mapStateToProps)(CustomMenu));
