import React from "react";
import { Layout, Sidebar } from "react-admin";
import withStyles from "@material-ui/core/styles/withStyles";
import SecureLS from "secure-ls";
import CustomMenu from "./CustomMenu";
import MyAppBar from "./MyAppBar";
import Spinner from "./Spinner";
import authServices from '../dd_auth_module/services/authServices';

const ls = new SecureLS({ encodingType: "aes" });

const styles = () => ({
  root: {
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
    minHeight: "100vh",
    backgroundColor: "#F1F1F1",
    position: "relative"
  },
  appFrame: {
    display: "flex",
    flexDirection: "column",
    overflowX: "auto"
  },
  contentWithSidebar: {
    display: "flex",
    flexGrow: 1
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 2,
    padding: "0px",
    paddingLeft: 5
  }
});

const CustomSidebar = props => <Sidebar {...props} size={200} />

class MyLayout extends React.Component {
  constructor() {
    super();
    this.state = {
      tokenPause: false
    };
  }

  componentWillMount() {
    const currentTokenDuration = ls.get("tokenDuration");
    const currentTokenSetTime = ls.get("tokenSet");
    const currentTime = Date.now();
    const elapsed = currentTime - currentTokenSetTime;
    if (elapsed > currentTokenDuration) {
      ls.set("pauseForTokenRefresh", 1000);
      this.setState({ tokenPause: true });
      const tokenRefreshed = authServices.checkRefreshToken();
      this.setState({ tokenPause: !tokenRefreshed });
    } else {
      const { tokenPause } = this.state;
      if (tokenPause) {
        this.setState({ tokenPause: false });
      }
    }
  }

  render() {
    const { tokenPause } = this.state;
    if (tokenPause) return <Spinner />;
    return (
      <Layout
        {...this.props}
        appBar={MyAppBar}
        menu={CustomMenu}
        sidebar={CustomSidebar}
      />
    );
  }
}

export default withStyles(styles)(MyLayout);
