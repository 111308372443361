import immutable from 'seamless-immutable';
import {createReducer} from 'reduxsauce';
import {
  get, forIn, camelCase, values, isEmpty
} from 'lodash';
import types from '../actions/types';

export const INITIAL_STATE = immutable({
  accessUsers: [],
  accessUsersFetching: false,
  accessUsersErrorCode: null,
  accessUsersPager: {
    current: 0,
    pageSize: 0,
    total: 0
  },
  addUserAccessSubmitting: false,
  addUserAccessErrorCode: null,
  addUserAccessErrors: null,
  addUserAccessErrorMessage: null,
  removeUserAccessSubmitting: false,
  removeUserAccessErrorCode: null
});

const getAccessUsersRequest = state => state.merge({
  accessUsersFetching: true
});

const getAccessUsersSuccess = (state, action) => state.merge({
  accessUsersFetching: false,
  accessUsersErrorCode: null,
  accessUsers: action.accessUsers,
  accessUsersPager: {
    current: get(action, 'pageInfo.page', 0),
    pageSize: get(action, 'pageInfo.page_size', 0),
    total: get(action, 'pageInfo.total_count', 0)
  }
});

const getAccessUsersFailure = (state, action) => state.merge({
  accessUsersFetching: false,
  accessUsersErrorCode: action.errorCode,
  accessUsers: [],
  accessUsersPager: {
    current: 0,
    pageSize: 0,
    total: 0
  }
});

const addUserAccessRequest = state => state.merge({
  addUserAccessSubmitting: true,
  addUserAccessErrorCode: null,
  addUserAccessErrors: null,
  addUserAccessErrorMessage: null
});

const addUserAccessSuccess = state => state.merge({
  addUserAccessSubmitting: false,
  addUserAccessErrorCode: null,
  addUserAccessErrors: null,
  addUserAccessErrorMessage: null
});

const addUserAccessFailure = (state, action) => {
  const camelCasedErrors = {};
  forIn(action.errors, (value, key) => {
    camelCasedErrors[camelCase(key)] = value;
  });

  return state.merge({
    addUserAccessSubmitting: false,
    addUserAccessErrorCode: action.errorCode,
    addUserAccessErrors: !isEmpty(camelCasedErrors) ? camelCasedErrors : null,
    addUserAccessErrorMessage: action.errors ? `${values(action.errors).join('. ')}.` : action.message
  });
};

const removeUserAccessRequest = state => state.merge({
  removeUserAccessSubmitting: true
});

const removeUserAccessSuccess = state => state.merge({
  removeUserAccessSubmitting: false,
  removeUserAccessErrorCode: null
});

const removeUserAccessFailure = (state, action) => state.merge({
  removeUserAccessSubmitting: false,
  removeUserAccessErrorCode: action.errorCode
});

const logout = () => INITIAL_STATE;

const ACTION_HANDLERS = {
  [types.GET_ACCESS_USERS]: getAccessUsersRequest,
  [types.GET_ACCESS_USERS_SUCCESS]: getAccessUsersSuccess,
  [types.GET_ACCESS_USERS_FAILURE]: getAccessUsersFailure,
  [types.ADD_USER_ACCESS]: addUserAccessRequest,
  [types.ADD_USER_ACCESS_SUCCESS]: addUserAccessSuccess,
  [types.ADD_USER_ACCESS_FAILURE]: addUserAccessFailure,
  [types.REMOVE_USER_ACCESS]: removeUserAccessRequest,
  [types.REMOVE_USER_ACCESS_SUCCESS]: removeUserAccessSuccess,
  [types.REMOVE_USER_ACCESS_FAILURE]: removeUserAccessFailure,
  [types.LOGOUT]: logout
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
