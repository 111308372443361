module.exports = {
  "analytics": {},
  "api": {
    "urlPrefix": "/api/v1",
    "baseUrl": "https://staging.firstviewbackend.com"
  },
  "proxy": false,
  "googleAnalytics": {
    "propertyId": "UA-105436795-2"
  },
  "goSquared": {
    "projectToken": "GSN-257311-M"
  },
  "confirmationEmailUrl": "https://staging.firstviewbackend.com/users/confirmation/new?embedded=true",
  "forgottenPasswordUrl": "https://staging.firstviewbackend.com/users/password/new",
  "googleMaps": {
    "baseUrl": "https://maps.googleapis.com/maps/api",
    "apiKey": "AIzaSyAUrQxsDUOe1S0q6b07jvHKbla3ZhfCflw"
  }
};
