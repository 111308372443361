import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  List, Datagrid, TextField, TextInput, Filter
} from 'react-admin';

import Card from '@material-ui/core/Card';
import statesIcon from '@material-ui/icons/AccountBalance';
import SecureLS from 'secure-ls';
import CardIcon from '../components/CardIcon';
import config from '../conf/common';
import Header from '../components/Header';
import AuthenticationError from '../components/AuthenticationError';
import extractProfile from '../analytics_config/extractProfile';
import { pageAnalytic } from '../analytics_config/plugins/setAnalytic';
import ListActions from "../components/ListActions";

const ls = new SecureLS({ encodingType: 'aes' });

const styles = theme => ({
  rowEven: {
    backgroundColor: '#F8F8F8'
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  table: {
    minWidth: 500
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  main: {
    flex: '1',
    marginRight: '1em',
    marginTop: 20
  },
  card: {
    overflow: 'inherit',
    textAlign: 'left',
    padding: 16,
    minHeight: 52,
    marginRight: '1em'
  }
});

export const StatesList = withStyles(styles)(({ classes, ...props }) => {
  const headerContent = 'All States';

  const newProfile = extractProfile();
  if (newProfile) {
    pageAnalytic(newProfile, { title: 'States Listing' });
  }

  const PostFilter = props => (
    <Filter {...props}>
      <TextInput label="Search States" source="name" defaultValue="" alwayson="true" />
    </Filter>
  );

  const authenticated = ls.get('authenticated');

  if (authenticated === "yes") {
    return (
      <React.Fragment>
        <Card className={classes.card}>
          <CardIcon Icon={statesIcon} bgColor={config.colors.backgroundSeaLight} orientation={"left"}/>
          <Header content={headerContent} orientation={'left'} />
          <List title="All States" {...props} bulkActionButtons={false} filters={<PostFilter />} actions={<ListActions type="states" />}>
            <Datagrid rowClick="show" classes={{ rowEven: classes.rowEven }}>
              <TextField source="name" label="Name" />
              <TextField source="" label=" " />
            </Datagrid>
          </List>
        </Card>
      </React.Fragment>
    );
  }
  return <AuthenticationError/>;
});
