import {
  GET_LIST, // CURRENTLY DEFAULTING TO THIS
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY
} from "react-admin";
import localforage from "localforage";
import api from "../../dd_data_module/services/api";
import { bundle, sortResponse } from "./commonProviderFunctions";

const getFeedbackList = (type, resource, params) => {
  const { field, order } = params.sort;
  let direction = "descend";
  if (order === "ASC") {
    direction = "ascend";
  }
  if (field === "id") {
    direction = "descend";
  }

  const { page, perPage } = params.pagination;
  return api
    .getFeedback(
      12,
      null,
      page,
      perPage,
      { columnKey: field, order: direction },
      params.filter,
      false
    )
    .then(response => new Promise((resolve => {
      const code = response.status;
      // const code = response.data.response.code;
      // const message = response.data.message;
      if (code !== 200) {
        // grab from localForage then run function
        localforage.getItem("feedbackdatabundle").then(dataBundle => {
          const { datastream, count } = dataBundle;
          const repackagedBundle = bundle(
            datastream,
            type,
            resource,
            params,
            count
          ); // Pick up the new params
          const thisPromise = sortResponse(repackagedBundle);
          thisPromise.then(resp => {
            resolve(resp);
          });
        });
      } else {
        const users = response.data.result;
        const count = response.data.info.total_count;
        // set to localforage, then run function
        const dataBundle = bundle(users, type, resource, params, count);

        localforage
          .setItem("feedbackdatabundle", dataBundle)
          .then(savedDataBundle => {
            const thisPromise = sortResponse(savedDataBundle);
            thisPromise.then(resp => {
              console.log(resp);
              resolve(resp);
            });
          });
      }
    })));
};

const getFeedbackOne = params => api.getFeedbackOne({ feedbackId: params.id }).then(response => new Promise((resolve => {
  const {message} = response.data;
  const {code} = response.data.response;
  if (message !== "Success" || code !== 200) {
    localforage.getItem("feedbackdatabundle").then(dataBundle => {
      resolve(dataBundle);
    });
  } else {
    const feedback = response.data.result;
    const dataBundle = { data: feedback };
    localforage
      .setItem("feedbackdatabundle", dataBundle)
      .then(savedDataBundle => {
        resolve(savedDataBundle);
      });
  }
})));

const getFeedbackResponse = (type, resource, params) => {
  switch (type) {
    case GET_LIST: {
      return getFeedbackList(type, resource, params);
    }
    case GET_ONE:
      return getFeedbackOne(params);
    case GET_MANY:
      return {
        data: null
      };
    case GET_MANY_REFERENCE:
      return {
        data: null
      };
    case CREATE:
      return {
        data: null
      };
    case UPDATE:
      return {
        data: null
      };
    case UPDATE_MANY:
      return {
        data: null
      };
    case DELETE:
      return {
        data: null
      };
    case DELETE_MANY:
      return {
        data: null
      };
    default:
      return false;
  }
};

export default getFeedbackResponse;
