import React from 'react';
import Switch from '@material-ui/core/Switch';
import CheckCircle from '@material-ui/icons/CheckCircle';

const styles = {
  root: {
    fontSize: '1.3rem'
  },
};

const CheckedBooleanInput = ({ ...props }) => (
  <Switch
    {...props}
    checkedIcon={<CheckCircle style={styles.root}/>}
    color="primary"
  />
);

export default CheckedBooleanInput;
