import {
  GET_LIST, // CURRENTLY DEFAULTING TO THIS
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY
} from "react-admin";
import localforage from "localforage";
import api from "../../dd_data_module/services/api";
import { bundle, sortResponse } from "./commonProviderFunctions";

const getStatesList = (type, resource, params) => {
  const { field, order } = params.sort;
  let direction = "ascend";
  if (order === "DESC") {
    direction = "descend";
  }
  if (field === "id") {
    direction = "ascend";
  }

  const { page, perPage } = params.pagination;
  return api
    .getStates(
      page,
      perPage,
      { columnKey: field, order: direction },
      params.filter,
      false
    )
    .then(response => new Promise(resolve => {
      const code = response.status;
      // const code = response.data.response.code;
      // const message = response.data.message;
      if (code !== 200) {
        // grab from localForage then run function
        localforage.getItem("statesdatabundle").then(databundle => {
          const { datastream, count } = databundle;
          const repackagedBundle = bundle(
            datastream,
            type,
            resource,
            params,
            count
          ); // Pick up the new params
          const thisPromise = sortResponse(repackagedBundle);
          thisPromise.then(response => {
            resolve(response);
          });
        });
      } else {
        const states = response.data.result;
        const count = response.data.info.total_count;
        // set to localforage, then run function
        const dataBundle = bundle(states, type, resource, params, count);

        localforage
          .setItem("statesdatabundle", dataBundle)
          .then(savedDataBundle => {
            const thisPromise = sortResponse(savedDataBundle);
            thisPromise.then(response => {
              resolve(response);
            });
          });
      }
    }));
};

const getStatesOne = params => api.getState({ stateId: params.id }).then(response => new Promise(resolve => {
  const {message} = response.data;
  const {code} = response.data.response;
  if (message !== "Success" || code !== 200) {
    localforage.getItem("statesdatabundle").then(dataBundle => {
      resolve(dataBundle);
    });
  } else {
    const state = response.data.result;
    const dataBundle = { data: state };
    localforage.setItem("statesdatabundle", dataBundle).then(savedDataBundle => {
      resolve(savedDataBundle);
    });
  }
}));

const getStatesResponse = (type, resource, params) => {
  switch (type) {
    case GET_LIST: {
      return getStatesList(type, resource, params);
    }
    case GET_ONE:
      return getStatesOne(params);
    case GET_MANY:
      return Promise.resolve({
        data: []
      });
    case GET_MANY_REFERENCE:
      return {
        data: null
      };
    case CREATE:
      return {
        data: null
      };
    case UPDATE:
      return {
        data: null
      };
    case UPDATE_MANY:
      return {
        data: null
      };
    case DELETE:
      return {
        data: null
      };
    case DELETE_MANY:
      return {
        data: null
      };
    default:
      return false;
  }
};

export default getStatesResponse;
