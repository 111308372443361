import React, { Component } from 'react';
import styled from 'styled-components';
import SunCalc from 'suncalc';
import PropTypes from 'prop-types';
import tzlookup from 'tz-lookup';
import moment from 'moment-timezone';

import circleDay from './images/circle-day.svg';
import circleNight from './images/circle-night.svg';
import sunriseIcon from './images/sunrise-icon.svg';
import sunsetIcon from './images/sunset-icon.svg';
import timelineMarker from './images/timeline-marker.svg';

const fadeStyles = (side, backgroundColor) => `
	position: absolute;
	top: 0;
	${side}: 0;
	height: 100%;
	width: 5%;
	z-index: 1;
	background: linear-gradient(${
  { left: 'to right', right: 'to left' }[side]
}, ${backgroundColor} 5%, ${backgroundColor}00);
	content: '.';
	color: transparent;	
`;

const ContainerDiv = styled.div`
	width: ${props => props.containerWidth}px;
	overflow: hidden;
	position: relative;

	&:before {
		${props => fadeStyles('left', props.backgroundColor)}
	}
	&:after {
		${props => fadeStyles('right', props.backgroundColor)}
	}
`;

const SunriseSunsetMarkerDividers = styled.div`
	position: relative;
	height: ${props => props.dividerHeight * 0.7}px;
	width: ${props => props.dividersTotalWidth}px;
	background:
		linear-gradient(
			${props => props.colors.dividers} ${props => props.dividerWidth}px,
			transparent ${props => props.dividerWidth}px
		),
		repeating-linear-gradient(
			to right,
			${props => props.colors.dividers},
			${props => props.colors.dividers} ${props => props.dividerWidth}px,
			transparent ${props => props.dividerWidth}px
				${props => props.widthPerHour + props.dividerWidth}px
		);
`;

const TimeLineMarker = styled.img.attrs(() => ({
  src: timelineMarker
}))`
	width: ${props => props.markerWidth}px;
	position: absolute;
	bottom: 25%;
	left: ${props => props.containerWidth / 2 - (props.markerWidth) / 2}px;
	z-index: 1;
`;

const TimeScroller = styled.div`
	position: relative;
	height: ${props => props.height}px;
	width: ${props => props.width}px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: ${props => props.height * 1.17}px;
	padding-bottom: ${props => props.height * 0.9}px;
	overflow: hidden;
	right: ${props => props.offset}px; // this is what will determine the scroll position of the day
	background-color: ${props => props.backgroundColor};
`;

class SunriseSunsetMarker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sunriseHours: {},
      sunsetHours: {},
      offset: 0
    };

    const hours = 50;
    const dividerWidth = 2;
    const colors = {
      dividers: 'rgba(192, 192, 192, .5)',
    };
    const { lat, lng } = this.props;
    const timeZone = (lat && lng)
      ? tzlookup(lat, lng)
      : null;

    Object.assign(this, {
      hours,
      dividerWidth,
      timeZone,
      colors
    });

    this.updateValues = this.updateValues.bind(this);
    this.sunriseDimensions = React.createRef();
  }

  componentDidMount() {
    const { lat, lng } = this.props;
    if (typeof lat === 'number' && typeof lng === 'number') {
      this.updateValues();
      this.interval = setInterval(this.updateValues, 10000);
    }
  }

  componentDidUpdate(prevProps) {
    const { width } = this.props;
    const { width: prevWidth } = prevProps;
    if (width !== prevWidth) {
      this.updateValues();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getDuskDawn(dayOffset = 0) {
    const { lat, lng } = this.props;
    const m = moment().tz(this.timeZone);

    if (dayOffset) {
      m.add(dayOffset, 'days');
    }

    const times = SunCalc.getTimes(m.toDate(), lat, lng);

    // Round hours to the nearest hour
    const [dawn, dusk] = [times.dawn, times.dusk].map(
      d => moment(d)
        .tz(this.timeZone)
        .add(30, 'minutes')
        .set({minute: 0, second: 0, millisecond: 0})
    );

    const sunriseHours = dawn.hour();
    const sunsetHours = dusk.hour();

    return {
      sunriseHours,
      sunsetHours,
    };
  }

  static getDisplayTime(val) {
    const twentyFourHourTime = (val + 12 - 1) % 24 || 24;
    if (twentyFourHourTime % 3) return '';
    if (twentyFourHourTime === 12) return 'NOON';
    if (twentyFourHourTime === 24) return 'MIDNIGHT';
    return twentyFourHourTime > 12
      ? `${twentyFourHourTime - 12} PM`
      : `${twentyFourHourTime} AM`;
  }

  getOffset() {
    const m = moment().tz(this.timeZone);
    const mMidnight = m.clone().startOf('day');
    const msSinceMidnight = m.diff(mMidnight, 'milliseconds');
    const msPerDay = 24 * 60 * 60 * 1000;

    const containerWidth = this.getContainerWidth();
    const widthPerHour = this.getWidthPerHour(containerWidth, this.dividerWidth, this.hours);

    const elapsedFraction = msSinceMidnight / msPerDay;
    const offset =			(widthPerHour + this.dividerWidth)
			* 24
			* elapsedFraction;
    return offset;
  }

  getContainerWidth() {
    const { width: detectedWidth = 0, minWidth } = this.props;
    const containerWidth = (detectedWidth < minWidth)
      ? minWidth
      : detectedWidth;
    return containerWidth;
  }

  getWidthPerHour(containerWidth, dividerWidth, hours) {
    return (containerWidth - dividerWidth) / (hours / 2) - dividerWidth;
  }

  updateValues() {
    const sunriseHours = {};
    const sunsetHours = {};

    ({
      sunriseHours: sunriseHours.yesterday,
      sunsetHours: sunsetHours.yesterday,
    } = this.getDuskDawn(-1));
    ({
      sunriseHours: sunriseHours.tomorrow,
      sunsetHours: sunsetHours.tomorrow,
    } = this.getDuskDawn(1));
    ({
      sunriseHours: sunriseHours.today,
      sunsetHours: sunsetHours.today,
    } = this.getDuskDawn());

    const offset = this.getOffset();

    this.setState({
      offset, sunriseHours, sunsetHours
    });
  }

  renderIcons({
    length, iconWidth, widthPerHour, dividerWidth
  }) {
    return Array.from({ length }).map((v, i) => {
      let icon = circleDay;
      const twentyFourHourTime = (i + 12 - 1) % 24 || 24;
      let day = 'today';
      if (icon < 13) {
        day = 'yesterday';
      } else if (i > 37) {
        day = 'tomorrow';
      }

      const { sunriseHours, sunsetHours } = this.state;

      if (
        twentyFourHourTime < sunriseHours[day]
				|| twentyFourHourTime > sunsetHours[day]
      ) icon = circleNight;
      else if (twentyFourHourTime === sunriseHours[day]) icon = sunriseIcon;
      else if (twentyFourHourTime === sunsetHours[day]) icon = sunsetIcon;

      const width = [sunriseIcon, sunsetIcon].includes(icon)
        ? iconWidth * 2
        : iconWidth;
      const top = [sunriseIcon, sunsetIcon].includes(icon) ? 0 : iconWidth / 1.9;
      const offset = [sunriseIcon, sunsetIcon].includes(icon)
        ? -(iconWidth / 2)
        : 0;

      const altText = ic => {
        switch (ic) {
          case sunriseIcon: return 'Sunrise icon';
          case sunsetIcon: return 'Sunset icon';
          case circleDay: return 'Daytime icon';
          case circleNight: return 'Nighttime icon';
          default: return 'Icon indicating time of day';
        }
      };

      return (
        <img
          alt={altText(icon)}
			  	// eslint-disable-next-line react/no-array-index-key
          key={i}
          src={icon}
          style={{
            position: 'absolute',
            left: i * (widthPerHour + dividerWidth) + dividerWidth / 2 + offset,
            top,
            width: `${width}px`,
          }}
        />
      );
    });
  }

  renderTimes({
    length, iconWidth, widthPerHour, dividerWidth
  }) {
    return Array.from({ length }).map((v, i) => (
      <div
			  // eslint-disable-next-line react/no-array-index-key
        key={i}
        style={{
          position: 'absolute',
          fontFamily: 'arial',
          color: this.colors.dividers,
          textAlign: 'center',
          left:
					i * (widthPerHour + dividerWidth)
					+ dividerWidth / 2
					- iconWidth * 1.5,
          top: `${iconWidth * 4.05}px`,
          width: `${iconWidth * 4}px`,
        }}
      >
        <span
          style={{
            fontSize: `1rem`,
            textAlign: 'center',
            marginLeft: '-100%',
            marginRight: '-100%',
          }}
        >
          {SunriseSunsetMarker.getDisplayTime(i)}
        </span>
      </div>
    ));
  }

  render() {
    const {
      hours,
      dividerWidth,
      colors
    } = this;

    const { backgroundColor, lat, lng } = this.props;
    const containerWidth = this.getContainerWidth();
    const widthPerHour = this.getWidthPerHour(containerWidth, dividerWidth, hours);
    const iconWidth = (widthPerHour + dividerWidth) / 2.5;
    const dividerHeight = (widthPerHour + dividerWidth) * 0.3;
    const dividersTotalWidth = (widthPerHour + dividerWidth) * hours + dividerWidth;
    const height = dividerHeight + iconWidth;
    const width = dividersTotalWidth + iconWidth;
    const initialOffset = (widthPerHour + dividerWidth) / 2 + iconWidth / 2;

    const { offset } = this.state;

    if (!(lat && lng)) {
      return null;
    }

    return (
      <div ref={this.sunriseDimensions}>
      <ContainerDiv containerWidth={containerWidth} backgroundColor={backgroundColor}>
        <TimeLineMarker markerWidth={iconWidth * 1.5} containerWidth={containerWidth} />
        <TimeScroller height={height} width={width} offset={initialOffset + offset} backgroundColor={backgroundColor}>
          <SunriseSunsetMarkerDividers
            {...{
              colors,
              widthPerHour,
              dividerWidth,
              dividerHeight,
              dividersTotalWidth,
            }}
          />
          {this.renderIcons({
            length: hours,
            iconWidth,
            widthPerHour,
            dividerWidth,
          })}
          {this.renderTimes({
            length: hours,
            iconWidth,
            widthPerHour,
            dividerWidth,
          })}
        </TimeScroller>
      </ContainerDiv>
      </div>
    );
  }
}

SunriseSunsetMarker.defaultProps = {
  backgroundColor: '#ffffff',
  minWidth: 580,
};

SunriseSunsetMarker.propTypes = {
  backgroundColor: (props, propName, componentName) => {
    const { [propName]: testProp } = props;
    if (!/^#[A-Fa-f0-9]{6}$/.test(testProp)) {
      return new Error(
				 `Invalid prop '${propName}' supplied to '${componentName}'. ('backgroundColor' prop should be in the format #ffffff).`
      );
    }
    return null;
  },
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  minWidth: PropTypes.number
};

export default SunriseSunsetMarker;
