import React, { useState } from 'react'
import Button from '@material-ui/core/Button';
import DialogMessage from "../components/DialogMessage";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Snackbar } from '@material-ui/core';
import {
    DELETE, SaveButton, translate
} from 'react-admin';
import dataLink from '../dataStores/ddData/dataLink';

const divStyle = { marginBottom: "25px", marginTop: "25px", display: 'flex', alignItems: 'center' };


const DeleteUser = ({ userId, navigateAndRemoveHistory, translate }) => {

    const [dialogOpen, setDialogOpen] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [userDeleted, setUserDeleted] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    const deleteUser = () => {
        setDialogOpen(false)
        setIsProcessing(true)
        dataLink(DELETE, "users", {
            id: userId
        }).then(response => {
            if (response.status !== 200) {
                setError(true)
                setErrorMessage(response.data.message);
            } else {
                setUserDeleted(true);
            }
            setIsProcessing(false)
        }).catch(() => {
            setIsProcessing(false);
            setErrorMessage(translate('resources.users.delete.error'));
            setError(true);
        });
    }

    const snackBarClose = () => {
        setUserDeleted(false);
        navigateAndRemoveHistory('/users');
    }

    const errorSnackBarClose = () => {
        setError(false);
        setErrorMessage(false);
    }

    return (
        <div>
            <div style={divStyle}>
                <Button
                    classes={{ root: 'delete-button' }}
                    variant="contained"
                    onClick={() => setDialogOpen(true)}
                    disabled={isProcessing}
                >
                    {translate('resources.users.delete.buttonText')}
                </Button>
                {isProcessing &&
                    <CircularProgress
                        size={20}
                        thickness={4}
                        isLoading={true}
                    />}
            </div>
            <DialogMessage
                dialogOpen={dialogOpen}
                titleText={translate('resources.users.delete.dialog.title')}
                contentText={translate('resources.users.delete.dialog.content')}
                closeFunction={() => setDialogOpen(false)}
                closeButtonText={translate('resources.users.delete.dialog.cancel')}
                saveButton={(
                    <SaveButton
                        label={translate('resources.users.delete.dialog.confirm')}
                        handleSubmitWithRedirect={deleteUser}
                        variant={null}
                        icon={<span />}
                    />
                )}
            />
            <Snackbar
                open={error}
                onClose={() => errorSnackBarClose()}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                autoHideDuration={2000}
                message={<span id="message-id">{errorMessage}</span>}
                ContentProps={{
                    classes: {
                        root: 'snackbar-error'
                    }
                }}
            />
            <Snackbar
                open={userDeleted}
                onClose={() => snackBarClose()}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                autoHideDuration={1500}
                message={<span id="message-id">{translate('resources.users.delete.success')}</span>}
            />
        </div>
    )
}

export default translate(DeleteUser)