import React from 'react';
import Spinner from 'react-spinkit';
import PropTypes from 'prop-types';

const AppSpinner = ({
  fadeIn, name, color, margin
}) => (
  <div
    style={{
      height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin
    }}>
    <Spinner fadeIn={fadeIn} name={name} color={color} />
  </div>
);

AppSpinner.propTypes = {
  fadeIn: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.string,
  fullHeight: PropTypes.bool,
  margin: PropTypes.string
};

AppSpinner.defaultProps = {
  fadeIn: "none",
  name: "wandering-cubes",
  color: "green",
  fullHeight: true,
  margin: ""
};

export default AppSpinner;
