import {
  createStore, applyMiddleware, compose, combineReducers
} from 'redux';
import immutable from 'seamless-immutable';
import createSagaMiddleware from 'redux-saga';
import {configurePersistors} from './persist';
import rootAuthReducer from '../dd_auth_module/reducers';
import rootAuthSaga from '../dd_auth_module/sagas';
import rootDataReducer from '../dd_auth_module/reducers'; // Will need to be combined
import rootSaga from '../dd_auth_module/sagas'; // will need to be combined

let store;

export default async function configureStore(initialState = {}) {
  const sagaMiddleware = createSagaMiddleware();

  const enhancers = [
    applyMiddleware(sagaMiddleware),
  ];

  const rootReducer = combineReducers({auth: rootAuthReducer, data: rootDataReducer});

  store = createStore(
    rootReducer,
    immutable(initialState),
    compose(...enhancers)
  );

  await configurePersistors(store);

  sagaMiddleware.run(rootAuthSaga, rootSaga);

  return store;
}

export const getStore = () => store;
