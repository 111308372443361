import studentsIcon from "@material-ui/icons/LocalLibrary";
import usersIcon from "@material-ui/icons/People";
import parentIcon from "@material-ui/icons/Person";
import schoolsIcon from "@material-ui/icons/School";
import localforage from "localforage";
import React, { Component } from "react";
import { GET_LIST, GET_ONE, Responsive } from "react-admin";
import SecureLS from "secure-ls";

import extractProfile from "../analytics_config/extractProfile";
import { pageAnalytic } from "../analytics_config/plugins/setAnalytic";
import Header from "../components/Header";
import HubGoogleMap from "../components/google_maps/HubGoogleMap";
import Spinner from "../components/Spinner";
import Total from "../components/Total";
import config from "../conf/common";
import dataLink from "../dataStores/ddData/dataLink";
import AdminPaginationTable from "./Layouts/AdminPaginationTable";
import SchoolPaginationTable from "./Layouts/SchoolPaginationTable";

const ls = new SecureLS({ encodingType: "aes" });

const styles = {
  flexSimple: { display: "flex" },
  flex: { display: "flex", paddingLeft: "20px", marginTop: "10px" },
  flexBase: { display: "flex", marginTop: "30px" },
  flexColumn: { display: "flex", flexDirection: "column" },
  flexRow: { display: "flex", direction: "row", marginBottom: "10px" },
  flexDeepRow: { display: "flex", direction: "row", marginBottom: "30px" },
  deepRow: { marginBottom: "30px" },
  shallowRow: { marginBottom: "10px" },
  leftCol: { flex: 1, marginRight: "1em" },
  rightCol: { flex: 1, marginLeft: "1em" },
  singleCol: { marginTop: "2em", marginBottom: "2em", padding: "20px" },
  singleColSmall: { marginTop: "2em", marginBottom: "2em", width: "80%" },
  leftColMainThinner: { flex: 1, marginRight: "1em", flexDirection: "column" },
  rightColMainWider: {
    flex: 2, marginLeft: "1em", flexDirection: "column", marginRight: "1em"
  },
  card: {
    overflow: "inherit",
    textAlign: "left",
    padding: 10,
    minHeight: 32,
    marginRight: 5
  },
  main: {
    flex: "1",
    marginRight: "1em"
  },
  mapCard: {
    marginTop: "20px",
    marginBottom: "25px",
    background: "#ffffff",
    width: "auto",
    height: "auto",
    paddingTop: "10px",
    paddingBottom: "10px",
    boxShadow:
      "0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)"
  },
  mapCol: {
    height: "400px",
    width: "auto",
    border: "1px solid #fff",
    margin: "20px"
  },
  pushIn: { marginLeft: "20px" },
  fixHeight: { minHeight: "40px" }
};

class AllStatesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      rowsPerPage: 10
    };

    this.initialiser = this.initialiser.bind(this);
  }

  componentDidMount() {
    let pauseForTokenRefresh = ls.get("pauseForTokenRefresh");
    if (pauseForTokenRefresh === "undefined" || pauseForTokenRefresh === null) {
      pauseForTokenRefresh = 1;
    }
    this.timer = setTimeout(this.initialiser, pauseForTokenRefresh);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  initialiser() {
    const { id } = this.props;

    dataLink(GET_ONE, "states", {
      id
    }).then(response => {
      if (response.error !== undefined) {
        if (response.error === "Unauthorized") {
          localforage.getItem("singleState").then(value => {
            this.setState({ singleState: value });
          });
        }
      } else {
        localforage.setItem("singleState", response).then(() => {
          this.setState({ singleState: response.data });
        });
      }
    });
    
    const { page, rowsPerPage } = this.state;
    
    dataLink(GET_LIST, "districts", {
      // Data for table
      pagination: { page, perPage: rowsPerPage },
      sort: { field: "id", direction: "ASC" },
      filter: { state_id: id }
    }).then(response => {
      if (response.error !== undefined) {
        if (response.error === "Unauthorized") {
          localforage.getItem("stateDistrictsCount").then(value => {
            this.setState({ districts: value });
          });
        }
      } else if (response.total === undefined) {
        localforage.getItem("stateDistrictsCount").then(value => {
          this.setState({ districts: value });
        });
      } else {
        localforage.setItem("stateDistrictsCount", response).then(() => {
          this.setState({ districts: response });
        });
      }
    });

    dataLink(GET_LIST, "districts", {
      // Data for map
      pagination: { page: 1, perPage: 500 },
      sort: { field: "id", direction: "ASC" },
      filter: { state_id: id }
    }).then(response => {
      if (response.error !== undefined) {
        if (response.error === "Unauthorized") {
          localforage.getItem("stateAllDistrictsCount").then(value => {
            this.setState({ alldistricts: value });
          });
        }
      } else if (response.total === undefined) {
        localforage.getItem("stateAllDistrictsCount").then(value => {
          this.setState({ alldistricts: value });
        });
      } else {
        localforage.setItem("stateAllDistrictsCount", response).then(() => {
          this.setState({ alldistricts: response });
        });
      }
    });
  }

  handleChangePage(event, page) {
    this.setState({ page });
  }

  handleChangeRowsPerPage(event) {
    this.setState({ rowsPerPage: event.target.value });
  }

  render() {
    const { alldistricts, districts, singleState = {} } = this.state;
    const { name, stats = {} } = singleState;
    const { id } = this.props;

    if (
      alldistricts !== undefined
      && districts !== undefined
      && singleState !== null
      && singleState !== undefined
    ) {
      const headerContent = `Districts for ${name}`;
      const parents = stats.users_count;
      const admins = stats.district_dashboard_users_count;
      const newProfile = extractProfile();
      if (newProfile) {
        pageAnalytic(newProfile, { title: 'States View' });
      }

      return (
        <Responsive
          small={
            <div style={styles.singleColSmall}>
              <div style={styles.flexColumn}>
                <div style={styles.deepRow}>
                  <Header content={name} orientation="left" pushleft="0px" />
                </div>
                <div style={styles.deepRow}>
                  <Total
                    text="pos.dashboard.total_schools"
                    color={config.colors.backgroundOrange}
                    icon={schoolsIcon}
                    value={stats.schools_count}
                  />
                </div>
                <div style={styles.deepRow}>
                  <Total
                    text="pos.dashboard.total_students"
                    color={config.colors.backgroundOrange}
                    icon={studentsIcon}
                    value={stats.students_count}
                    link="students"
                  />
                </div>
                <div style={styles.deepRow}>
                  <Total
                    text="pos.dashboard.total_users"
                    color={config.colors.backgroundGreen}
                    icon={usersIcon}
                    value={admins}
                    link="users"
                  />
                </div>
                <div style={styles.deepRow}>
                  <Total
                    text="pos.dashboard.total_parents"
                    color={config.colors.backgroundGreen}
                    icon={parentIcon}
                    value={parents}
                    link="users"
                  />
                </div>
                <div style={styles.deepRow}>
                  <div style={styles.mapCard}>
                    <Header content={headerContent} orientation="left" />
                    <div style={styles.mapCol}>
                      <HubGoogleMap
                        states={alldistricts.data}
                        zoom={6}
                      />
                    </div>
                  </div>
                </div>
                <div style={styles.deepRow}>
                  <SchoolPaginationTable id={id} />
                </div>
                <div style={styles.deepRow}>
                  <AdminPaginationTable parent="stateadmin" id={id} />
                </div>

                <div style={styles.deepRow}>
                  <AdminPaginationTable parent="stateuser" id={id} />
                </div>
              </div>
            </div>
          }
          medium={
            <div>
              <div style={{ marginRight: "1em" }}>
                <div>
                  <Header content={name} orientation="left" pushleft="0px" />
                </div>
                <div>
                  <div
                    style={{
                      ...styles.mapCard,
                      paddingTop: 1
                    }}>
                    <Header content={headerContent} orientation="left" />
                    <div style={{ ...styles.mapCol, height: 300 }}>
                      <HubGoogleMap
                        states={alldistricts.data}
                        zoom={6}
                        height={300}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div style={styles.leftColMainThinner}>
                  <div style={{ ...styles.flexRow, justifyContent: 'space-between' }}>
                    <Total
                      text="pos.dashboard.total_schools"
                      color={config.colors.backgroundOrange}
                      icon={schoolsIcon}
                      value={stats.schools_count}
                      align="left"
                    />
                    <Total
                      text="pos.dashboard.total_students"
                      color={config.colors.backgroundOrange}
                      icon={studentsIcon}
                      value={stats.students_count}
                      link="students"
                      align="right"
                    />
                  </div>
                  <div style={styles.flexDeepRow}>
                    <Total
                      text="pos.dashboard.total_users"
                      color={config.colors.backgroundGreen}
                      icon={usersIcon}
                      value={admins}
                      align="left"
                    />
                    <Total
                      text="pos.dashboard.total_parents"
                      color={config.colors.backgroundGreen}
                      icon={parentIcon}
                      value={parents}
                      align="right"
                    />
                  </div>
                  <div style={styles.flexColumn}>
                    <div style={styles.deepRow}>
                      <SchoolPaginationTable id={id} useStateFilter />
                    </div>
                  </div>
                </div>

                <div style={styles.rightColMainWider}>
                  <div style={styles.flexColumn}>
                    <div style={styles.shallowRow}>
                      <AdminPaginationTable parent="stateadmin" id={id} />
                    </div>

                    <div style={styles.shallowRow}>
                      <AdminPaginationTable parent="stateuser" id={id} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          large={
            <div>
              <div style={styles.flex}>
                <div style={styles.leftCol}>
                  <Header content={name} orientation="left" pushleft="0px" />
                </div>
              </div>

              <div style={styles.flex}>
                <div style={styles.leftColMainThinner}>
                  <div style={styles.flexRow}>
                    <Total
                      text="pos.dashboard.total_schools"
                      color={config.colors.backgroundOrange}
                      icon={schoolsIcon}
                      value={stats.schools_count}
                      align="left"
                    />
                    <Total
                      text="pos.dashboard.total_students"
                      color={config.colors.backgroundOrange}
                      icon={studentsIcon}
                      value={stats.students_count}
                      link="students"
                      align="right"
                    />
                  </div>
                  <div style={styles.flexDeepRow}>
                    <Total
                      text="pos.dashboard.total_users"
                      color={config.colors.backgroundGreen}
                      icon={usersIcon}
                      value={admins}
                      align="left"
                    />
                    <Total
                      text="pos.dashboard.total_parents"
                      color={config.colors.backgroundGreen}
                      icon={parentIcon}
                      value={parents}
                      align="right"
                    />
                  </div>
                  <div style={styles.flexColumn}>
                    <div style={styles.deepRow}>
                      <SchoolPaginationTable id={id} useStateFilter />
                    </div>
                  </div>
                </div>

                <div style={styles.rightColMainWider}>
                  <div style={styles.flexColumn}>
                    <div style={styles.shallowRow}>
                      <div style={styles.mapCard}>
                        <Header content={headerContent} orientation="left" />
                        <div style={styles.mapCol}>
                          <HubGoogleMap
                            states={alldistricts.data}
                            zoom={6}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={styles.shallowRow}>
                      <AdminPaginationTable parent="stateadmin" id={id} />
                    </div>

                    <div style={styles.shallowRow}>
                      <AdminPaginationTable parent="stateuser" id={id} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      );
    }
    return <Spinner />;
  }
}

export default AllStatesView;
