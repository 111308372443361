/*
module.exports = {
  colors: {
    iconBackground: '#a1ccd4',
    backgroundBlue: '#294a7b',
    backgroundOrange: '#faaf3a',
    backgroundGreen: '#76ae33',
    backgroundRed: '#C55E27',
    backgroundSea: '#aad3df',
    backgroundSeaLight: '#bfdce2',
    header: '#5e7173'
  },
  module.exports = {
    colors: {
      iconBackground: '#bfdce2',
      backgroundBlue: '#bfdce2',
      backgroundOrange: '#bfdce2',
      backgroundGreen: '#bfdce2',
      backgroundRed: '#bfdce2',
      backgroundSea: '#bfdce2',
      backgroundSeaLight: '#bfdce2',
      header: '#5e7173'
    },
  loginTime: 800000, //just under 15 mins in milliseconds
  defaultDistrict: {
    id: 12,
    name: 'Elmhurst D205'
  },
  initialDistrictRandomised: false,
  mergeSearch: false
};
*/

module.exports = {
  colors: {
    iconBackground: '#5E6F90',
    backgroundBlue: '#5E6F90',
    backgroundOrange: '#5E6F90',
    backgroundGreen: '#5E6F90',
    backgroundRed: '#5E6F90',
    backgroundSea: '#5E6F90',
    backgroundSeaLight: '#5E6F90',
    header: '#5e7173'
  },
  loginTime: 800000, // just under 15 mins in milliseconds
  defaultDistrict: {
    id: 12,
    name: 'Elmhurst D205'
  },
  initialDistrictRandomised: false,
  mergeSearch: false,
};
