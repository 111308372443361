import SecureLS from 'secure-ls';
const ls = new SecureLS({ encodingType: 'aes' });

const extractProfile = () => {
  const email = ls.get('email');
  if (email.length > 0) {
    const firstName = ls.get('first_name');
    const lastName = ls.get('last_name');
    const phone = ls.get('phone');

    const data = {
      userId: email,
      first_name: firstName,
      last_name: lastName,
      email,
      phone,
    };
    return data;
  }
  return null;
};

export default extractProfile;
