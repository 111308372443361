import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import SvgIcon from "@material-ui/core/SvgIcon";
import Snackbar from "@material-ui/core/Snackbar";
import withStyles from "@material-ui/core/styles/withStyles";
import { CopyToClipboard } from "react-copy-to-clipboard";

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
};

class CopyField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  render() {
    const { open } = this.state;
    const { value, classes } = this.props;
    return (
      <div className={classes.container}>
        <CopyToClipboard text={value}>
          <IconButton
            aria-label="Copy"
            onClick={() => this.setState({ open: true })}
            className={classes.copyableText}
          >
            <SvgIcon>
              <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
            </SvgIcon>
          </IconButton>
        </CopyToClipboard>
        <Snackbar
          open={open}
          onClose={() => this.setState({ open: false })}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          autoHideDuration={2000}
          message={<span id="message-id">Text copied to clipboard!</span>}
        />
      </div>
    );
  }
}

export default withStyles(styles)(CopyField);
