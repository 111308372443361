import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import {
  Button,
  TopToolbar,
  withTranslate
} from "react-admin";
import DownloadIcon from "@material-ui/icons/GetApp";
import DialogMessage from "./DialogMessage";
import api from "../dd_data_module/services/api";
import LoadingOverlay from "./LoadingOverlay";

function ListActions(props) {
  const {
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    csvDownloadEnabled,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    type,
    translate: transl
  } = props;

  const [downloading, setDownloading] = useState(false)
  const [exportDialogOpen, setExportDialogOpen] = useState(false)
  const [exportDialogType, setExportDialogType] = useState(false)
  const [exportDialogMessage, setExportDialogMessage] = useState(false)

  const getData = (sortInfo, filtersInfo, type) => {
    setDownloading(true)
    return api.getCSV({ type, sortInfo, filtersInfo }).then(response => {
      setExportDialogMessage(response?.data?.message ?? null)
      setExportDialogType(response.ok ? 'success' : 'error')
      setExportDialogOpen(true);
      setDownloading(false);
    });
  }

  useEffect(() => {
    let timeout;
    if (!exportDialogOpen) {
      timeout = setTimeout(() => {
        setExportDialogMessage(null);
        setExportDialogType(null);
      }, 500)
    }
    return () => clearTimeout(timeout)
  }, [exportDialogOpen])

  const isErrorDialog = exportDialogType === 'error';

  return (
    <React.Fragment>
      <TopToolbar>
        {bulkActions
          && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems
          })}
        {filters
          && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button"
          })}
        {csvDownloadEnabled
          && <Button
            onClick={() => getData(currentSort, filterValues, type)}
            label="Export"
          >
            <DownloadIcon />
          </Button>
        }
      </TopToolbar>
      <LoadingOverlay show={downloading} message="" />
      <DialogMessage
        dialogOpen={exportDialogOpen}
        titleText={isErrorDialog ? transl("general.error.generic") : ''}
        contentText={isErrorDialog ? transl(`ra.message.csv_error`) : exportDialogMessage}
        closeFunction={() => setExportDialogOpen(false)}
        closeButtonText={transl("general.confirmation.close")}
      />
    </React.Fragment>
  );
}

ListActions.propTypes = {
  csvDownloadEnabled: PropTypes.bool
};

ListActions.defaultProps = {
  csvDownloadEnabled: true
};

export default withTranslate(ListActions);
