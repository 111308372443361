import Card from "@material-ui/core/Card";
import withStyles from "@material-ui/core/styles/withStyles";
import SchoolCardIcon from "@material-ui/icons/School";
import React from "react";
import {
  Datagrid, Filter, List, NumberField, ReferenceInput, SelectInput, TextField, TextInput
} from "react-admin";
import SecureLS from "secure-ls";

import extractProfile from "../analytics_config/extractProfile";
import { pageAnalytic } from "../analytics_config/plugins/setAnalytic";
import AuthenticationError from "../components/AuthenticationError";
import CardIcon from "../components/CardIcon";
import Header from "../components/Header";
import config from "../conf/common";
import TimeZoneField, { formatTimeZone } from "./utilities/TimeZoneField";
import ListActions from "../components/ListActions";
import DynamicFilterField from "./utilities/DynamicFilterField";

const ls = new SecureLS({ encodingType: "aes" });

const styles = theme => ({
  rowEven: {
    backgroundColor: "#F8F8F8"
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  table: {
    minWidth: 500
  },
  tableWrapper: {
    overflowX: "auto"
  },
  main: {
    flex: "1",
    marginRight: "1em",
    marginTop: 20
  },
  card: {
    overflow: "inherit",
    textAlign: "left",
    padding: 16,
    minHeight: 52,
    marginRight: '1em'
  }
});

const SchoolFilter = props => (
  <Filter {...props}>
    <TextInput
      label="Search Schools"
      source="name"
      defaultValue=""
      alwayson="true"
    />
    <ReferenceInput
      label="Filter By District"
      source="district.id"
      reference="districts"
      allowEmpty
      perPage={1000000}
      alwayson="true"
      filter={{ simple: true }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <DynamicFilterField 
      style={{ width: 185 }}
      label="Filter By Time Zone"
      entity="districts"
      source="time_zone"
      valueExtractor={value => ({ id: value, name: formatTimeZone(value) })}
      alwayson="true"
    />
    <TextInput 
      style={{ width: 230 }}
      label="Search Number Of Students"
      source="students_count"
      defaultValue=""
      alwayson="true"
    />
  </Filter>
);

const SchoolsList = withStyles(styles)(({ classes, ...props }) => {
  const headerContent = "All Schools";

  const newProfile = extractProfile();
  if (newProfile) {
    pageAnalytic(newProfile, { title: 'Schools Listing' });
  }

  const authenticated = ls.get("authenticated");

  if (authenticated === "yes") {
    return (
      <React.Fragment>
        <Card className={classes.card}>
          <CardIcon
            Icon={SchoolCardIcon}
            bgColor={config.colors.backgroundOrange}
            orientation="left"
          />
          <Header content={headerContent} orientation="left" />
          <List
            title="All Schools"
            {...props}
            bulkActionButtons={false}
            filters={<SchoolFilter />}
            actions={<ListActions type="schools" />}
          >
            <Datagrid rowClick="show" classes={{ rowEven: classes.rowEven }}>
              <TextField source="name" label="School" />
              <TextField
                sortable={false}
                source="district.name"
                label="District"
              />
              <TimeZoneField sortable={false} label="Time Zone" />
              <NumberField source="students_count" label="Student Count" />
            </Datagrid>
          </List>
        </Card>
      </React.Fragment>
    );
  }
  return <AuthenticationError />;
});

export default SchoolsList;
