/* eslint-disable max-len */
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/string';
import 'raf/polyfill';

import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import statesIcon from '@material-ui/icons/AccountBalance';
import DashboardIcon from '@material-ui/icons/Apps';
import FeedbackIcon from '@material-ui/icons/Feedback';
import studentsIcon from '@material-ui/icons/LocalLibrary';
import ServiceAlertsIcon from '@material-ui/icons/NotificationsActive';
import TransformScheduleIcon from '@material-ui/icons/EventNote';
import usersIcon from '@material-ui/icons/People';
import allDistrictsIcon from '@material-ui/icons/Public';
import schoolsIcon from '@material-ui/icons/School';
import React from 'react';
import { Admin, Resource } from 'react-admin';
import SecureLS from 'secure-ls';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import history from './history';

import RidershipIcon from './icons/RidershipIcon';
import ActivityStudentsIconBlack from './icons/ActivityStudentsIconBlack';
import authProvider from './authProviderDirectCallVersion';
import Dashboard from './components/Dashboard';
import MyLayout from './components/MyLayout';
import LoginWrapper from './containers/LoginWrapper';
import dataProvider from './dataStores/ddData/dataLink';
import languages, { supportedLanguages } from './i18n/i18n';
import AllDistrictList from './lists/AllDistrictList';
import FeedbackList from './lists/FeedbackList';
import RidershipList from './lists/RidershipList';
import SchoolsList from './lists/SchoolsList';
import ServiceAlertsList from './lists/ServiceAlertsList';
import { StatesList } from './lists/StatesList';
import StudentsList from './lists/StudentsList';
import ActivityStudentsList from './lists/ActivityStudentsList';
import UsersList from './lists/UsersList';
import AllDistrictView from './views/AllDistrictView';
import AllSchoolsView from './views/AllSchoolsView';
import AllStatesView from './views/AllStatesView';
import FeedbackView from './views/FeedbackView';
import RidershipView from './views/RidershipView';
import ServiceAlertsView from './views/ServiceAlertsView';
import StudentsView from './views/StudentsView';
import ActivityStudentsView from './views/ActivityStudentsView';
import { UsersView } from './views/UsersView';
import analytics from './analytics_config';
import TransformScheduleList from './lists/TransformScheduleList';
import TransformScheduleView from './views/TransformScheduleView';

const ls = new SecureLS({ encodingType: 'aes' });

history.listen(() => {
  analytics.page();
});

const i18nProvider = polyglotI18nProvider(locale => {
  if (supportedLanguages.includes(locale)) {
    return languages[locale];
  }
  return languages.en;
}, 'en');

const shadows = [
  "none",
  "0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)",
  "0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)",
  "0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)",
  "0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)",
  "0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)",
  "0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)",
  "0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)",
  "0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)",
  "0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)",
  "0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)",
  "0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)",
  "0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)",
  "0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)",
  "0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)",
  "0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)",
  "0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)",
  "0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)",
  "0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)",
  "0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)",
  "0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)",
  "0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)",
  "0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)",
  "0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)",
  "0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)"
];

const theme = createMuiTheme({
  shadows,
  palette: {
    text: {
      link_primary: "#001E61",
      link_secondary: "#0075C8"
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#2c387e"
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "#2c387e",
      main: "#fefefe",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#2c387e"
    },
    typography: {},
  },
  sidebar: {
    width: '220px',
  }
});

/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 * Browser sniffing used for sunrise/sunset map overlay
 */
const detectIE = () => {
  const ua = window.navigator.userAgent;

  // Test values; Uncomment to check result …

  // IE 10
  // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

  // IE 11
  // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

  // Edge 12 (Spartan)
  // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

  // Edge 13
  // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

  const msie = ua.indexOf("MSIE ");
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
  }

  const trident = ua.indexOf("Trident/");
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf("rv:");
    return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
  }

  const edge = ua.indexOf("Edge/");
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
  }

  // other browser
  return false;
};

const App = () => {
  if (detectIE()) {
    ls.set("usingIE", true);
  } else {
    ls.set("usingIE", false);
  }

  return (
    <Admin
      theme={theme}
      layout={MyLayout}
      authProvider={authProvider}
      dataProvider={dataProvider}
      loginPage={LoginWrapper}
      i18nProvider={i18nProvider}
      title=" "
      history={history}
    >
      <Resource name="dashboard" list={Dashboard} icon={DashboardIcon} />
      <Resource
        name="districts"
        list={AllDistrictList}
        icon={allDistrictsIcon}
        show={AllDistrictView}
      />
      <Resource
        name="schools"
        list={SchoolsList}
        icon={schoolsIcon}
        show={AllSchoolsView}
      />
      <Resource
        name="students"
        list={StudentsList}
        icon={studentsIcon}
        show={StudentsView}
      />
      <Resource
        name="activitystudents"
        options={{ label: "Activity Students" }}
        list={ActivityStudentsList}
        icon={ActivityStudentsIconBlack}
        show={ActivityStudentsView}
      />
      <Resource
        name="states"
        list={StatesList}
        show={AllStatesView}
        icon={statesIcon}
      />
      <Resource
        name="users"
        list={UsersList}
        icon={usersIcon}
        show={UsersView}
      />
      <Resource
        name="feedback"
        options={{ label: "Feedback" }}
        list={FeedbackList}
        icon={FeedbackIcon}
        show={FeedbackView}
      />
      <Resource
        name="ridership"
        options={{ label: "Ridership" }}
        list={RidershipList}
        icon={RidershipIcon}
        show={RidershipView}
      />
      <Resource
        name="servicealerts"
        options={{ label: "Service Alerts" }}
        list={ServiceAlertsList}
        icon={ServiceAlertsIcon}
        show={ServiceAlertsView}
      />
      <Resource
        name="transformschedule"
        options={{ label: "Transform Schedule" }}
        list={TransformScheduleList}
        icon={TransformScheduleIcon}
        show={TransformScheduleView}
      />
    </Admin>
  );
};

App.contextTypes = {};

export default App;
