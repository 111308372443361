import api from './api';
import SecureLS from "secure-ls";
import localforage from "localforage";
import { AUTH_LOGOUT } from "react-admin";
import authProvider from '../../authProviderDirectCallVersion';

const ls = new SecureLS({ encodingType: "aes" });

const checkRefreshToken = () => {
    const emailOrPhone = ls.get("emailOrPhone");
    const loginToken = ls.get("loginToken");
    return api.getToken(emailOrPhone, loginToken).then(response => {
        ls.set("pauseForTokenRefresh", 1);
        const { ok } = response;
        if (!ok) {
            logout();
            return false;
        } else {
            const { message } = response.data;
            const { code } = response.data.response;
            if (message !== "Success" || code !== 200) {
                logout();
                return false;
            } else {
                const { auth_token: authToken, expiry } = response.data || {};
                setToken(authToken, expiry);
                return authToken;
            }
        }
    });    
}

const setToken = (authToken, expiry) => {
    setTime();
    ls.set("token", authToken);
    const newTokenDuration = (expiry - 60) * 1000;
    ls.set("tokenDuration", newTokenDuration);
    const newTokenSetTime = Date.now();
    ls.set("tokenSet", newTokenSetTime);
}

const logout = () => {
    const path = "/login";
    ls.removeAll();
    localStorage.clear();
    localforage.clear();
    authProvider(AUTH_LOGOUT, {});
    window.location.assign(path);
}

const setTime = () => {
    const groundzero = Date.now();
    ls.set("lastchecked", groundzero);
};

export default {
    checkRefreshToken,
    setToken,
    logout
}