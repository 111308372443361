import React from "react";
import PropTypes from "prop-types";
import { GET_LIST } from "react-admin";
import localforage from "localforage";

import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Card from "@material-ui/core/Card";
import Pagination from "../Utilities/Pagination";

import Header from "../../components/Header";
import Spinner from "../../components/Spinner";

import dataLink from "../../dataStores/ddData/dataLink";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  table: {
    minWidth: 500
  },
  allPadded: {
    padding: "10px 10px 10px 0",
    color: "#343434",
    textAlign: "left",
    fontSize: "0.85em"
  },
  noUsersString: {
    color: "#000000de",
    fontSize: ".85em"
  },
  tableWrapper: {
    overflowX: "auto"
  },
  padder: {
    marginTop: "30px",
    border: "1px solid #fff"
  },
  card: {
    overflow: "inherit",
    textAlign: "left",
    padding: 16,
    minHeight: 52,
    marginBottom: "25px"
  },
  inCard: {
    textAlign: "left"
  },
  input: {
    border: "none",
    borderBottom: "1px solid #ccc",
    fontSize: "1.1em",
    marginBottom: "10px",
    minWidth: "200px"
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#F8F8F8"
    }
  }
});

const getFilters = (id, fullNameString) => ({
  districtadmin: { district_id: id, full_name: fullNameString },
  districtuser: { district_id: id, full_name: fullNameString },
  schooladmin: { school_id: id, full_name: fullNameString },
  schooluser: { school_id: id, full_name: fullNameString },
  stateadmin: { state_id: id, full_name: fullNameString },
  stateuser: { state_id: id, full_name: fullNameString },
  studentadmin: { student_id: id, full_name: fullNameString },
  studentuser: { student_id: id, full_name: fullNameString }
});

const CustomLeftHeaderCell = withStyles({
  head: {
    backgroundColor: "#fff",
    color: "#5E7173",
    fontWeight: "400",
    fontSize: ".9em",
    textAlign: "left",
    borderTop: "1px solid #eee"
  },
  body: {
    textAlign: "left"
  }
})(TableCell);

class UserPaginationTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      page: 0,
      rowsPerPage: 5,
      total: 0,
      id: 0,
      filter: {},
      selection: "",
      header: "Adminstrators",
      loading: false,
      query: "",
      placeHolderContent: "Search"
    };

    this.headers = {
      districtadmin: "District Dashboard Users",
      districtuser: "Parents",
      schooladmin: "District Dashboard Users",
      schooluser: "Parents",
      stateadmin: "District Dashboard Users",
      stateuser: "Parents",
      studentadmin: "District Dashboard Users",
      studentuser: "Student Users"
    };

    this.placeHolders = {
      districtadmin: "Search Dashboard Users",
      districtuser: "Search Users",
      schooladmin: "Search Dashboard Users",
      schooluser: "Search Users",
      stateadmin: "Search Dashboard Users",
      stateuser: "Search Users",
      studentadmin: "Search Dashboard Users",
      studentuser: "Search Users"
    };

    this.selections = {
      districtadmin: "dashboardUsers",
      districtuser: "users",
      schooladmin: "dashboardUsers",
      schooluser: "users",
      stateadmin: "dashboardUsers",
      stateuser: "users",
      studentadmin: "dashboardUsers",
      studentuser: "users"
    };

    this.noUsersStrings = {
      districtadmin: "There are no registered FirstView users for this district.",
      districtuser: "There are no registered parents for this district.",
      schooladmin: "There are no registered FirstView users for this school.",
      schooluser: "There are no registered parents for this school.",
      stateadmin: "There are no registered FirstView users for this state.",
      stateuser: "There are no registered parents for this state.",
      studentadmin: "No registered FirstView users are following this student.",
      studentuser: "No registered parents are following this student."
    };

    this.getInfo = this.getInfo.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this._isMounted = false;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    const { id, parent } = this.props;

    const filters = getFilters(id, "");
    const filter = filters[parent];

    const selection = this.selections[parent];
    const header = this.headers[parent];
    const placeHolderContent = this.placeHolders[parent];
    this.setState({
      filter, selection, header, placeHolderContent, id, loading: true
    });
    const { page, rowsPerPage } = this.state;
    dataLink(GET_LIST, selection, {
      pagination: {
        page,
        perPage: rowsPerPage,
        adjust: "yes"
      },
      sort: { field: "id", direction: "ASC" },
      filter
    }).then(response => {
      if(this._isMounted){
        if (response.error !== undefined) {
          if (response.error === "Unauthorized") {
            localforage.getItem("DistrictAdminUsersCount").then(value => {
              this.setState({ rows: value.data, total: value.total, loading: false });
            });
          }
        } else if (response.total === undefined) {
          localforage.getItem("DistrictAdminUsersCount").then(value => {
            this.setState({ rows: value.data, total: value.total, loading: false });
          });
        } else {
          localforage.setItem("DistrictAdminUsersCount", response).then(() => {
            this.setState({ rows: response.data, total: response.total, loading: false });
          });
        }
      }
    });
  }

  getInfo(id, clear) {
    const block = "";
    const { query } = this.state;
    const names = query.split(" ");
    let first = query;
    let second = block;
    if (names.length > 1) {
      [first, second] = names;
    }
    if (first.length === 0) {
      first = block;
    }

    if (second.length === 0) {
      second = block;
    }

    const { parent } = this.props;

    if (clear) {
      first = "";
      second = "";
      this.setState({ page: 0 });
    }

    const fullNameString = names.join("|");

    const filters = getFilters(id, fullNameString);
    const filter = filters[parent];

    const selection = this.selections[parent];

    const header = this.headers[parent];
    this.setState({
      filter, selection, header, id
    });

    const { page, rowsPerPage } = this.state;
    dataLink(GET_LIST, selection, {
      pagination: {
        page,
        perPage: rowsPerPage,
        adjust: "yes"
      },
      sort: { field: "id", direction: "ASC" },
      filter,
      district: "paginate_adjust",
      student: id
    }).then(response => {
      if (response.error !== undefined) {
        if (response.error === "Unauthorized") {
          localforage.getItem("DistrictUsersCount").then(value => {
            this.setState({
              rows: value.data,
              total: value.total,
              loading: false
            });
          });
        }
      } else if (response.total === undefined) {
        localforage.getItem("DistrictUsersCount").then(value => {
          this.setState({
            rows: value.data,
            total: value.total,
            loading: false
          });
        });
      } else {
        localforage.setItem("DistrictUsersCount", response).then(() => {
          this.setState({
            rows: response.data,
            total: response.total,
            loading: false
          });
        });
      }
    });
  }

  handleChangePage(event, page) {
    this.setState({ page, loading: true });
    const { rowsPerPage, selection, filter } = this.state;
    dataLink(GET_LIST, selection, {
      pagination: {
        page,
        perPage: rowsPerPage,
        adjust: "yes"
      },
      sort: { field: "id", direction: "ASC" },
      filter,
      district: "paginate_adjust"
    }).then(response => {
      this.setState({ rows: response.data, total: response.total, loading: false });
    });
  }

  handleChangeRowsPerPage(event) {
    this.setState({ rowsPerPage: event.target.value, loading: true });
    const { page, filter, selection } = this.state;
    dataLink(GET_LIST, selection, {
      pagination: {
        page,
        perPage: event.target.value,
        adjust: "yes"
      },
      sort: { field: "id", direction: "ASC" },
      filter
    }).then(response => {
      this.setState({ rows: response.data, total: response.total, loading: false });
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({
      loading: true,
      page: 0
    });
    const { id } = this.state;
    this.getInfo(id, false);
  }

  handleInputChange(event) {
    const newState = {
      query: event.target.value,
      page: 0
    };
    this.setState(newState);
    if (event.target.value.length === 0) {
      this.setState({
        query: "",
        loading: true
      }, () => {
        const { id } = this.state;
        this.getInfo(id, true);
      });      
    }
  }

  handleClick(id) {
    const path = `${window.location.origin}/users/${id}/show`;
    window.location.href = path;
  }

  render() {
    const { parent, classes } = this.props;
    const {
      loading,
      query,
      rows,
      rowsPerPage,
      page,
      header,
      total,
      placeHolderContent
    } = this.state;
    const headerContent = `${header}`;
    let resultsRender = <Spinner />;
    if (loading !== true) {
      if (query && rows.length === 0) {
        resultsRender = (
          <div className={classes.allPadded}>No results found</div>
        );
      } else if (!query && rows.length === 0) {
        resultsRender = (
          <span className={classes.noUsersString}>
            {this.noUsersStrings[parent]}
          </span>
        );
      } else {
        resultsRender = (
          <Paper>
            <div className={classes.padder}>
              <div className={classes.tableWrapper}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <CustomLeftHeaderCell>Last Name</CustomLeftHeaderCell>
                      <CustomLeftHeaderCell>First Name</CustomLeftHeaderCell>
                      <CustomLeftHeaderCell>Email</CustomLeftHeaderCell>
                      <CustomLeftHeaderCell>Status</CustomLeftHeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map(row => (
                      <TableRow
                        hover
                        onClick={() => this.handleClick(row.id)}
                        className={classes.row}
                        key={row.id}
                      >
                        <CustomLeftHeaderCell component="th" scope="row">
                          {row.last_name}
                        </CustomLeftHeaderCell>
                        <CustomLeftHeaderCell numeric="true">
                          {row.first_name}
                        </CustomLeftHeaderCell>
                        <CustomLeftHeaderCell numeric="true">
                          {row.email}
                        </CustomLeftHeaderCell>
                        <CustomLeftHeaderCell numeric="true">
                          {row.status}
                        </CustomLeftHeaderCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        colSpan={4}
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        ActionsComponent={Pagination}
                        rowsPerPageOptions={[5,10,25,50,100]}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </div>
            </div>
          </Paper>
        );
      }
    }
    return (
      <Card className={classes.card}>
        <Header content={headerContent} orientation="right" />
        {!(!query && rows.length === 0) && (
          <div className={classes.inCard}>
            <form className="commentForm" onSubmit={this.handleSubmit}>
              <input
                className={classes.input}
                placeholder={placeHolderContent}
                ref={c => {
                  this.search = c;
                }}
                onChange={this.handleInputChange}
              />
            </form>
          </div>
        )}
        {resultsRender}
      </Card>
    );
  }
}

UserPaginationTable.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired
};

export default withStyles(styles)(UserPaginationTable);
