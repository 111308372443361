import {
  GET_LIST, // CURRENTLY DEFAULTING TO THIS
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY
} from "react-admin";
import localforage from "localforage";
import api from "../../dd_data_module/services/api";
import { bundle, sortResponse } from "./commonProviderFunctions";

const getServiceAlertsList = (type, resource, params) => {
  const { field, order } = params.sort;
  let direction = "descend";
  if (order === "ASC") {
    direction = "ascend";
  }
  if (field === "id") {
    direction = "descend";
  }

  const { page, perPage } = params.pagination;
  return api
    .getServiceAlerts(
      null,
      page,
      perPage,
      { columnKey: field, order: direction },
      params.filter,
      false
    )
    .then(response => new Promise(resolve => {
      const code = response.status;
      // const code = response.data.response.code;
      // const message = response.data.message;
      if (code !== 200) {
        // grab from localForage then run function
        localforage.getItem("servicealertsdatabundle").then(databundle => {
          const { datastream, count } = databundle;
          const repackagedBundle = bundle(
            datastream,
            type,
            resource,
            params,
            count
          ); // Pick up the new params
          const thisPromise = sortResponse(repackagedBundle);
          thisPromise.then(resp => {
            resolve(resp);
          });
        });
      } else {
        const servicealerts = response.data.result;
        const count = response.data.info.total_count;
        // set to localforage, then run function
        const databundle = bundle(servicealerts, type, resource, params, count);

        localforage
          .setItem("servicealertsdatabundle", databundle)
          .then(() => {
            const thisPromise = sortResponse(databundle);
            thisPromise.then(resp => {
              console.log(resp);
              resolve(resp);
            });
          });
      }
    }));
};

const getServiceAlertOne = params => {
  return api
    .getServiceAlertOne({ serviceAlertId: params.id })
    .then(response => new Promise(resolve => {
      const {message} = response.data;
      const {code} = response.data.response;
      if (message !== "Success" || code !== 200) {
        localforage.getItem("servicealertdatabundle").then(databundle => {
          resolve(databundle);
        });
      } else {
        const feedback = response.data.result;
        const databundle = { data: feedback };
        localforage
          .setItem("servicealertdatabundle", databundle)
          .then(() => {
            resolve(databundle);
          });
      }
    }));
};

const getServiceAlertsResponse = (type, resource, params) => {
  switch (type) {
    case GET_LIST: {
      return getServiceAlertsList(type, resource, params);
    }
    case GET_ONE:
      return getServiceAlertOne(params);
    case GET_MANY:
      return {
        data: null
      };
    case GET_MANY_REFERENCE:
      return {
        data: null
      };
    case CREATE:
      return {
        data: null
      };
    case UPDATE:
      return {
        data: null
      };
    case UPDATE_MANY:
      return {
        data: null
      };
    case DELETE:
      return {
        data: null
      };
    case DELETE_MANY:
      return {
        data: null
      };
    default:
      return false;
  }
};

export default getServiceAlertsResponse;
