import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Toolbar from '@material-ui/core/Toolbar';
import SaveIcon from '@material-ui/icons/Save';
import RadioGroup from '../components/RadioGroup';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { translate } from 'react-admin';
import CheckedSwitch from './CheckedSwitch';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';


const renderSwitch = ({ input, label }) => {
    return (
        <CheckedSwitch
            type='checkbox'
            label={label}
            checked={input.value}
            onChange={input.onChange}
        />
    )
};

const renderTextField = ({ input, label, meta: { touched, error, warning } }) => (
    <div>
        <TextField
            label={label}
            value={input.value}
            onChange={input.onChange}
        />
        {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
);

const renderSlider = ({ input, label, min, max, step, labels }) => (
    <div style={{ width: '100%' }}>
        <p style={{ width: '100%' }}>{label}</p>
        <Slider
            min={min}
            max={max}
            step={step}
            value={input.value}
            tooltip={false}
            labels={labels}
            onChange={input.onChange}
        />
    </div>
);

const fieldTypes = {
    "radio": RadioGroup,
    "checkbox": renderSwitch,
    "text": renderTextField,
    "slider": renderSlider
}

const DistrictForm = ({
    options, handleSubmit, type, pristine, translate
}) => {
    return (
        <form onSubmit={handleSubmit}>
            <div style={{ paddingTop: 16, paddingLeft: 24, paddingRight: 24 }}>
                <h2
                    style={{
                        color: 'rgb(94, 113, 115)',
                        paddingRight: '20px',
                        width: '100%',
                        textAlign: 'left',
                        fontFamily: '"Roboto Slab", serif',
                        fontSize: '1.1rem',
                    }}
                >
                    {translate(`resources.districts.section.${type}`)}
                </h2>
                {options.map(option => {
                    let divstyle = {}
                    if (option.type !== 'slider') {
                        divstyle = { marginLeft: 14 }
                    } else {
                        divstyle = { marginLeft: 14, marginBottom: 25 }
                    }
                    return (
                        <div style={divstyle}>
                            <FormControlLabel
                                type={option.type}
                                key={option}
                                label={option.type === 'checkbox' ? translate(`resources.districts.field_labels.${option.name}`) : null}
                                control={
                                    <Field
                                        type={option.name}
                                        name={option.name}
                                        label={option.type !== 'checkbox' ? translate(`resources.districts.field_labels.${option.name}`) : null}
                                        min={option.type === 'slider' ? option.min : null}
                                        max={option.type === 'slider' ? option.max : null}
                                        labels={option.type === 'slider' ? option.labels : null}
                                        step={option.type === 'slider' ? option.step : null}
                                        component={fieldTypes[option.type]}
                                    />
                                }
                            />
                        </div>);
                })}
            </div>
            <Toolbar style={{ justifyContent: 'flex-end', marginTop: 16 }}>
                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={pristine}
                >
                    <SaveIcon style={{ marginRight: '0.5em' }} />
                    Save
                </Button>
            </Toolbar>
        </form>
    )
};

DistrictForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool,
    options: PropTypes.shape({
        name: PropTypes.string,
        type: PropTypes.string,
        value: PropTypes.string | PropTypes.boolean | PropTypes.number,
    })
};

const ReduxFormDistrictForm = ({ record, resource, options, ...restProps }) => {
    const initialValues = options.reduce((obj, option) => ({ ...obj, [option.name]: option.value }), {});
    return (
        <Form initialValues={initialValues} {...restProps}>
            {({ handleSubmit, pristine }) => <DistrictForm {...restProps} options={options} handleSubmit={handleSubmit} pristine={pristine} />}
        </Form>
    )
};

export default translate(ReduxFormDistrictForm);
