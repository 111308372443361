import MuiAppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import withWidth from "@material-ui/core/withWidth";
import MenuIcon from "@material-ui/icons/Menu";
import classNames from "classnames";
import PropTypes from "prop-types";
import { toggleSidebar as toggleSidebarAction } from "ra-core";
import React, { cloneElement } from "react";
import { Link, UserMenu } from "react-admin";
import { connect } from "react-redux";
import compose from "recompose/compose";

import mainLogo from "../fv.png";
import Headroom from "./Headroom";
import LoadingIndicator from "./LoadingIndicator";

// import UserMenu from './UserMenu';
const styles = theme => ({
  toolbar: {
    paddingRight: 24,
    height: "60px",
    maxHeight: "60px",
    overflow: "hidden"
  },
  menuButton: {
    marginLeft: "0.5em",
    marginRight: "0.5em"
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    transform: "rotate(0deg)"
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    transform: "rotate(180deg)"
  },
  title: {
    flex: 1,
    marginTop: "10px",
    marginLeft: "0px",
    color: "#666",
    fontFamily: "'Roboto Slab', serif"
  },
  logo: {
    marginLeft: "15px",
    marginRight: "20px"
  }
});

const AppBar = ({
  children,
  classes,
  className,
  logout,
  open,
  title,
  toggleSidebar,
  userMenu,
  width,
  ...rest
}) => (
  <Headroom>
    <MuiAppBar
      className={className}
      color="secondary"
      position="static"
      {...rest}
    >
      <Toolbar
        disableGutters
        variant={width === "xs" ? "regular" : "dense"}
        className={classes.toolbar}
      >
        <Link to="/">
          <img
            src={mainLogo}
            alt="FirstView Hub"
            height="55"
            className={classes.logo}
          />
        </Link>
        <Typography
          variant="h6"
          color="inherit"
          className={classes.title}
          id="react-admin-title"
        />
        <LoadingIndicator />
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleSidebar}
          className={classNames(classes.menuButton)}
        >
          <MenuIcon
            classes={{
              root: open
                ? classes.menuButtonIconOpen
                : classes.menuButtonIconClosed
            }}
          />
        </IconButton>
        {cloneElement(userMenu, { logout })}
      </Toolbar>
    </MuiAppBar>
  </Headroom>
);

AppBar.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.instanceOf(Object),
  className: PropTypes.string,
  logout: PropTypes.element,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  userMenu: PropTypes.node,
  width: PropTypes.string
};

AppBar.defaultProps = {
  userMenu: <UserMenu />
};

const enhance = compose(
  connect(
    state => ({
      locale: state && state.i18n && state.i18n.locale || 'en' // force redraw on locale change
    }),
    {
      toggleSidebar: toggleSidebarAction
    }
  ),
  withStyles(styles),
  withWidth()
);

export default enhance(AppBar);
