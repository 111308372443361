import React, { useState } from 'react'
import Button from '@material-ui/core/Button';
import DialogMessage from "./DialogMessage";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Snackbar } from '@material-ui/core';
import {
  SaveButton, translate
} from 'react-admin';
import dataLink from '../dataStores/ddData/dataLink';

const divStyle = { marginBottom: "25px", marginTop: "25px", display: 'flex', alignItems: 'center' };


const SendPasswordResetButton = ({ userId, translate }) => {

  const [dialogOpen, setDialogOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const sendPasswordResetEmail = () => {
    setDialogOpen(false);
    setIsProcessing(true);
    dataLink("SEND_EMAIL", "users", {
      id: userId
    }).then(response => {
      if (response.status !== 200) {
        setError(true)
        setErrorMessage(response.data.message)
      } else {
        setPasswordReset(true);
      }
      setIsProcessing(false);
    }).catch(() => {
      setIsProcessing(false);
      setErrorMessage(translate('resources.users.resetpassword.error'));
      setError(true);
    });
  }

  const snackBarClose = () => {
    setPasswordReset(false);
  }

  const errorSnackBarClose = () => {
    setError(false);
    setErrorMessage('');
  }

  return (
    <div>
      <div style={divStyle}>
        <Button
          variant="contained"
          onClick={() => setDialogOpen(true)}
          disabled={isProcessing}
          color="primary"
        >
          {translate('resources.users.resetpassword.buttonText')}
        </Button>
        {isProcessing &&
          <CircularProgress
            size={20}
            thickness={4}
            isLoading={true}
          />}
      </div>
      <DialogMessage
        dialogOpen={dialogOpen}
        titleText={translate('resources.users.resetpassword.dialog.title')}
        contentText={translate('resources.users.resetpassword.dialog.content')}
        closeFunction={() => setDialogOpen(false)}
        closeButtonText={translate('resources.users.resetpassword.dialog.cancel')}
        saveButton={(
          <SaveButton
            label={translate('resources.users.resetpassword.dialog.confirm')}
            handleSubmitWithRedirect={sendPasswordResetEmail}
            variant={null}
            icon={<span />}
          />
        )}
      />
      <Snackbar
        open={error}
        onClose={() => errorSnackBarClose()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        autoHideDuration={2000}
        message={<span id="message-id">{errorMessage}</span>}
        ContentProps={{
          classes: {
            root: 'snackbar-error'
          }
        }}
      />
      <Snackbar
        open={passwordReset}
        onClose={() => snackBarClose()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        autoHideDuration={1500}
        message={<span id="message-id">{translate('resources.users.resetpassword.success')}</span>}
      />
    </div>
  )
}

export default translate(SendPasswordResetButton)