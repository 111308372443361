import immutable from 'seamless-immutable';
import {createReducer} from 'reduxsauce';
import types from '../actions/types';

export const INITIAL_STATE = immutable({
  access: null,
  accessFetching: false,
  accessErrorCode: null
});

const getAccessRequest = state => state.merge({
  accessFetching: true,
  accessErrorCode: null
});

const getAccessSuccess = (state, action) => state.merge({
  access: action.access,
  accessFetching: false,
  accessErrorCode: null
});

const getAccessFailure = (state, action) => state.merge({
  access: null,
  accessFetching: false,
  accessErrorCode: action.errorCode
});

const logout = () => INITIAL_STATE;

const ACTION_HANDLERS = {
  [types.GET_ACCESS]: getAccessRequest,
  [types.GET_ACCESS_SUCCESS]: getAccessSuccess,
  [types.GET_ACCESS_FAILURE]: getAccessFailure,
  [types.LOGOUT]: logout
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
