import React, { Component } from "react";
import PropTypes from 'prop-types';
import { GET_LIST, translate } from "react-admin";
import withStyles from "@material-ui/core/styles/withStyles";
import ClearIcon from "@material-ui/icons/Clear";
import { IconButton, TextField } from "@material-ui/core";
import Suggestions from "./Suggestions";
import dataLink from "../dataStores/ddData/dataLink";
import HeaderSmall from "./HeaderSmall";

const styles = {
  main: {
    flex: "1",
    marginRight: "1em",
    marginBottom: 30
  },
  card: {
    overflow: "inherit",
    textAlign: "center",
    padding: 16,
    minHeight: 52
  },
  listings: {
    background: "#fafafa",
    borderTop: "1px solid #aaa",
    borderBottom: "1px solid #fff"
  },
  scroller: {
    height: "auto",
    maxHeight: "300px",
    overflowY: "auto"
  },
  formStyle: {
    display: 'flex'
  },
  clearableInput: {
    width: "100%"
  }
};

class SearchSingle extends Component {
  static propTypes = {
    type: PropTypes.string,
    parameter: PropTypes.string,
    classes: PropTypes.instanceOf(Object)
  };

  constructor(props) {
    super(props);
    this.state = {
      error: false,
      query: "",
      firstnameResults: [],
      lastnameResults: [],
      results: [],
      type: ""
    };
    this.typingTimeout = 0;
  }

  getInfo = () => {
    const { type, parameter } = this.props;
    const block = "xxhhxxhhxxhhxxhhxxhhxxhhxx";
    const { query } = this.state;
    let first = "";
    let second = "";
    if (parameter === "name" && query !== "") {
      const names = query.split(" ");
      first = query;
      second = block;
      if (names.length > 1 && second.length > 0) {
        [first, second] = names;
      }
      if (first.length === 0) {
        first = block;
      }
      if (second.length === 0) {
        second = block;
      }
      if (first === block) {
        first = "";
      }
      if (second === block) {
        second = "";
      }
    }
    if (query.length < 2) {
      this.setState({ results: {} });
    } else {
      const filters = {
        name: { first_name: first, last_name: second },
        email: { email: query },
        phone: { phone: query }
      };

      let filter = filters[parameter];

      dataLink(GET_LIST, type, {
        pagination: { page: 1, perPage: 20 },
        sort: { field: "last_name", direction: "ASC" },
        filter
      }).then(response => {
        if (filter.first_name !== undefined) {
          if (response.data.length === 0 && response.last_name === undefined) {
            filter = { first_name: "", last_name: first };

            dataLink(GET_LIST, type, {
              pagination: { page: 1, perPage: 20 },
              sort: { field: "last_name", direction: "ASC" },
              filter
            }).then(resp => {
              this.setState({ results: resp.data });
            });
          } else {
            this.setState({ results: response.data });
          }
        } else {
          this.setState({ results: response.data });
        }
      });
    }
  };

  handleInputChange = e => {
    this.setState({ 
      results: {},
      query: e.target.value
    });
    if (this.typingTimeout) {
      clearTimeout(this.typingTimeout);   
    }
    if(e.target.value.length > 2){
      this.typingTimeout = setTimeout(() => {
        this.getInfo();
      }, 1000)
    }
  };

  handleClearInput = () => {
    this.setState({
      query: "",
      results: []
    });
  };

  render() {
    const { classes, parameter, type } = this.props;
    const { results, query } = this.state;
    const headerContent = `By ${parameter.charAt(0).toUpperCase()
      + parameter.slice(1)}`;
    let suggestions = <span />;
    if (results.length > 0) {
      suggestions = (
        <div className={classes.scroller}>
          <Suggestions
            results={results}
            style={classes}
            type={type}
            parameter={parameter}
          />
        </div>
      );
    }
    return (
      <React.Fragment>
        <HeaderSmall content={headerContent} orientation="left" />
        <form className={classes.formStyle}>
          <TextField
            className={classes.clearableInput}
            clearable="true"
            value={query}
            onChange={e => this.handleInputChange(e)}
            InputProps={{
              endAdornment: (
                <IconButton>
                  {!!query && (
                    <div>
                      <ClearIcon
                        onClick={this.handleClearInput}
                      />
                    </div>
                  )}
                </IconButton>
              )
            }}
          />
        </form>
        {suggestions}
      </React.Fragment>
    );
  }
}

export default translate(withStyles(styles)(SearchSingle));
