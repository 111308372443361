import React from "react";

const styles = {
  main: {
    textAlign: "left",
    fontSize: "20px"
  }
};

const DistrictTypeField = ({ record }) => {

  if (record?.settings?.district_type) {
    let type = record.settings.district_type;

    if (type === "district_dashboard_only") {
      type = "District Dashboard";
    }
    if (type === "streetz") {
      type = "Street";
    }
    if (type === "student") {
      type = "Student";
    }
    if (type === "gps_student") {
      type = "GPS Student";
    }
    if (type === "gps_district_code") {
      type = "GPS District Code";
    }
    if (type === "gps_dashboard") {
      type = "GPS Dashboard";
    }

    return <span style={styles.main}>{type}</span>;
  } return <span />;
};

DistrictTypeField.defaultProps = {
  source: "District Type",
  label: "District Type",
  addLabel: true
};

export default DistrictTypeField;
