import {
  forEach, get, omit, isNil
} from 'lodash';


function strip(html) {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return get(doc, 'body.textContent', "");
}

export function flattenObject(obj, prefix = '') {
  if(!obj)
    return null;
  return Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? `${prefix}.` : '';
    if (typeof obj[k] === 'object') Object.assign(acc, flattenObject(obj[k], pre + k));
    else acc[pre + k] = obj[k];
    return acc;
  }, {});
}

export const getDistrictOrSchoolProperty = ({districtId, schoolId}) => {
  const data = {};
  if (districtId) {
    data.district_id = districtId;
  } else if (schoolId) {
    data.school_id = schoolId;
  }
  return data;
};

export const getFilterProperties = filtersInfo => {
  const data = {};
  forEach(filtersInfo, (value, key) => {
    if (typeof value === 'object') {
      const flattened = flattenObject({[key]: value});
      const [newKey, newVal] = (Object.entries(flattened) || [])[0] || {};
      data[`filter_${newKey}`.replace('.', '_')] = strip(newVal);
    } else if(key === "date"){
      data[key] = strip(value);
    } else {
      data[`filter_${key}`.replace('.', '_')] = strip(value);
    }
  });
  return data;
};

export const getSortingProperties = sortInfo => {
  const data = {
    sort_column: get(sortInfo, 'columnKey'),
    sort_order: get(sortInfo, 'order')
  };
  return omit(data, isNil);
};
