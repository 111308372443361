import React from "react";
import config from "../conf/common";

const color = config.colors.header;

const Header = ({
  content,
  orientation,
  pushdown: paddingTop = "0px",
  pushleft: paddingLeft = "20px"
}) => {
  const style = orientation === "left"
    ? {
      color,
      paddingLeft,
      paddingTop,
      fontFamily: "'Roboto Slab', serif",
      display: 'flex',
      alignItems: 'center',
      fontSize: '24px'
    }
    : {
      color,
      paddingRight: "20px",
      width: "100%",
      textAlign: "right",
      paddingTop,
      fontFamily: "'Roboto Slab', serif",
    };

  return <h2 style={style}>{content}</h2>;
};

export default Header;
