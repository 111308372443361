import React from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import DistrictIcon from "@material-ui/icons/Search";
import withStyles from "@material-ui/core/styles/withStyles";
import { translate } from "react-admin";
import CardIcon from "./CardIcon";
import config from "../conf/common";
import Header from "./Header";
import SearchSingle from "./SearchSingle";

const styles = {
  main: {
    flex: "1"
  },
  card: {
    overflow: "inherit",
    textAlign: "center",
    padding: 16,
    minHeight: 52
  },
  inputtext: {
    background: "#f3f4f9",
    width: "99%",
    height: 50,
    fontSize: "1.2em",
    marginTop: "10px",
    padding: "5px",
    borderRadius: "5px"
  },
  listings: {
    background: "#fafafa",
    borderTop: "1px solid #aaa",
    borderBottom: "1px solid #fff"
  }
};

const SearchContainer = ({
  classes, marginBottom, parameters, type
}) => {
  const headerContent = `Find ${type.charAt(0).toUpperCase() + type.slice(1)}`;
  return (
    <div className={classes.main} style={{ marginBottom }}>
      <CardIcon
        Icon={DistrictIcon}
        bgColor={config.colors.backgroundSeaLight}
        orientation="down"
      />
      <Card className={classes.card}>
        <Header content={headerContent} orientation="right" />
        {parameters.map(parameter => (
          <SearchSingle type={type} parameter={parameter} key={parameter} />
        ))}
      </Card>
    </div>
  );
};

SearchContainer.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  marginBottom: PropTypes.number.isRequired,
  parameters: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
};

export default translate(withStyles(styles)(SearchContainer));
