import React, { Component, Fragment } from "react";
import PropTypes from 'prop-types';

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import FormGroup from "@material-ui/core/FormGroup";
import Image from "@material-ui/icons/Image";

class UploadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      name: ""
    };
  }

  handleCapture = ({ target }) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(target.files[0]);

    fileReader.onload = e => {
      this.setState({
        file: e.target.result,
        name: target.files[0].name
      });
    };
  };

  render() {
    return (
      <Fragment>
        <hr />
        <Typography
          variant="body2"
          style={{
            color: "rgba(0, 0, 0, 0.87)",
            margin: "1em 0.25em",
            display: "inline-block"
          }}
        >
          Upload new district image:
        </Typography>
        <label htmlFor="icon-button-photo">
          <IconButton color="primary" component="span">
            <Image />
          </IconButton>
        </label>
        <FormGroup>
          <input
            accept="image/*"
            id="icon-button-photo"
            onChange={this.handleCapture}
            type="file"
            style={{ display: "none" }}
          />
          <Typography
            variant="caption"
            style={{
              color: "rgba(0, 0, 0, 0.87)",
              margin: "0 0.25em 0.25em 0.25em",
              display: "block",
              fontSize: "0.8125rem",
              fontWeight: 400
            }}
          >
            {this.state.name || "None chosen"}
          </Typography>
          <Button
            onClick={() => this.props.uploadImage(this.state.file)}
            variant="text"
            color="primary"
            disabled={!this.state.name || this.props.processing}
            style={{
              textAlign: "right"
            }}
          >
            Upload
          </Button>
        </FormGroup>
        { this.props.uploadFailure
          ? <Typography
            variant="p"
            component="p"
            style={{
              color: "darkred",
              margin: "0.5em 0",
              display: "block",
              "font-size": "0.8125rem",
              "font-weight": 600
            }}
          >
            Upload failed
          </Typography> : null
        }
      </Fragment>
    );
  }
}

UploadForm.propTypes = {
  uploadImage: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  uploadFailure: PropTypes.bool.isRequired
};

export default UploadForm;
