import 'clientjs';
import apisauce from 'apisauce';
import {
  isEmpty, toUpper, omitBy, isNil
} from 'lodash';
import SecureLS from 'secure-ls';
import core from '../../core/env';
import env from '../../dd_common/env';
import {
  getDistrictOrSchoolProperty, getFilterProperties, getSortingProperties
} from '../utils/apiUtils';
import authServices from '../../dd_auth_module/services/authServices';

const client = new ClientJS(); // eslint-disable-line no-undef

const ls = new SecureLS({ encodingType: 'aes' });

const api = apisauce.create({
  baseURL: env.proxy ? env.api.urlPrefix : `${core.api.baseUrl}${core.api.urlPrefix}`,
  withCredentials: true,
  timeout: 5000
});
const newapi = apisauce.create({
  baseURL: env.proxy ? env.api.urlPrefix : `${core.api.baseUrl}${core.api.urlPrefix}/hub`,
  withCredentials: true,
  paramsSerializer(params) {
    const searchParams = new URLSearchParams();
    for (const key of Object.keys(params)) {
      const param = params[key];
      if (Array.isArray(param)) {
        for (const p of param) {
          searchParams.append(key, p);
        }
      } else {
        searchParams.append(key, param);
      }
    }
    return searchParams.toString();
  },
  timeout: 50000
});


newapi.addAsyncResponseTransform(async response => {
  if (response.status === 401) {
    const token = await authServices.checkRefreshToken();
    if (token) {
      const config = {
        headers: authHeaders(),
        method: response.config.method,
        url: response.config.url,
        params: response.config.params,
      }
      const data = await newapi.any(config);
      response.data = data.data;
      response.status = data.status;
      response.problem = null;
    }
  }
})

const authHeaders = () => ({
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
  Authorization: `Bearer ${ls.get('token')}`
});

const addUserAccess = ({
  email, firstName, lastName, districts, schools
}) => {
  const data = {
    email,
    first_name: firstName,
    last_name: lastName
  };
  if (!isEmpty(districts)) {
    data.districts = districts;
  }
  if (!isEmpty(schools)) {
    data.schools = schools;
  }
  return api.post('/admin/access/add', data, { headers: authHeaders() });
};

const removeUserAccess = ({ userId, districtId, schoolId }) => {
  const data = {
    user_id: userId
  };

  if (districtId) {
    data.district_id = districtId;
  }
  if (schoolId) {
    data.school_id = schoolId;
  }

  return api.delete('/admin/access/remove', data, { headers: authHeaders() });
};

const exportSecurityCodes = ({ districtId, schoolId }) => {
  const data = {
    ...getDistrictOrSchoolProperty({ districtId, schoolId })
  };
  return api.get(`/admin/students/security-codes.csv`, data, {
    headers: authHeaders(), timeout: 60000
  });
};

const getAccess = () => api.get('/admin/access', null, {
  headers: authHeaders()
});

const getProfile = () => api.get('/profile', null, {
  headers: authHeaders()
});

const getDistrict = districtId => api.get(`/admin/districts/${districtId}`, null, {
  headers: authHeaders()
});

const getDistrictBanner = districtId => newapi
  .get(`/districts/${districtId}/banner_message`, null, {
    headers: authHeaders()
  });

const updateDistrictBanner = (districtId, data) => newapi
  .post(`/districts/${districtId}/banner_message`, data, {
    headers: authHeaders()
  });

const getDistrictTypes = districtId => newapi
  .get(`/district_settings/${districtId}/district_types`, null, {
    headers: authHeaders()
  });

const updateDistrictType = (districtId, data) => newapi
  .patch(`/district_settings/${districtId}/update_district_type`, data, {
    headers: authHeaders()
  });

const getSchool = ({ schoolId }) => newapi.get(`/schools/${schoolId}`, null, {
  headers: authHeaders()
});

const getSchools = (districtId, page, pageSize, sortInfo, filtersInfo, simple) => {
  const data = {
    district_id: districtId,
    page,
    page_size: pageSize,
    ...getSortingProperties(sortInfo),
    ...getFilterProperties(filtersInfo)
  };
  if (simple) {
    data.simple = true;
  }
  return newapi.get('/schools', data, { headers: authHeaders(), timeout: 60000 });
};

const getServiceAlerts = (schoolId, page, pageSize, sortInfo, filtersInfo) => {
  const data = {
    page,
    page_size: pageSize,
    ...getSortingProperties(sortInfo),
    ...getDistrictOrSchoolProperty({ schoolId }),
    ...getFilterProperties(filtersInfo)
  };

  return newapi.get('/service_alerts', data, { headers: authHeaders() });
};

const getStats = () => newapi.get(`/stats`, null, { headers: authHeaders() });

const getServiceAlertOne = ({ serviceAlertId }) => newapi
  .get(`/service_alerts/${serviceAlertId}`, null, { headers: authHeaders() });

const getStopUsers = ({ districtId, schoolId, stopId }) => {
  const data = {
    stop_id: stopId,
    ...getDistrictOrSchoolProperty({ districtId, schoolId })
  };
  return api.get(`/admin/stops/${stopId}/users`, data, { headers: authHeaders() });
};

const getStopStudents = ({ districtId, schoolId, stopId }) => {
  const data = {
    stop_id: stopId,
    ...getDistrictOrSchoolProperty({ districtId, schoolId })
  };
  return api.get(`/admin/stops/${stopId}/students`, data, { headers: authHeaders() });
};

const getStopRoutes = ({ districtId, schoolId, stopId }) => {
  const data = {
    stop_id: stopId,
    ...getDistrictOrSchoolProperty({ districtId, schoolId })
  };
  return api.get(`/admin/stops/${stopId}/routes`, data, { headers: authHeaders() });
};

const postUserSettings = (
  userId,
  studentAdding,
  pinCode,
  showParentEtas,
  showDDEtas
) => {
  const data = {
    studentAdding,
    pinCode,
    showParentEtas,
    showDDEtas
  };
  return newapi.post(`/user/${userId}`, data, { headers: authHeaders() });
};

const getRoute = ({
  routeName, districtId, schoolId, dispatchType = 'AM', startTime, date = 'current'
}) => {
  const data = {
    dispatch_type: toUpper(dispatchType),
    start_time: startTime,
    date,
    ...getDistrictOrSchoolProperty({ districtId, schoolId })
  };
  return api.get(`/admin/routes/${routeName}`, data, { headers: authHeaders() });
};

const getRouteReplay = ({
  routeName, districtId, schoolId, dispatchType = 'AM', startTime, date = 'current'
}) => {
  const data = {
    dispatch_type: toUpper(dispatchType),
    start_time: startTime,
    date,
    ...getDistrictOrSchoolProperty({ districtId, schoolId })
  };
  return api.get(`/admin/routes/${routeName}/replay`, data, { headers: authHeaders() });
};

const getStudent = ({ studentId }) => newapi
  .get(`/students/${studentId}`, null, { headers: authHeaders() });

const getStates = (page, pageSize, sortInfo, filtersInfo) => {
  const data = {
    page,
    page_size: pageSize,
    ...getSortingProperties(sortInfo),
    ...getFilterProperties(filtersInfo)
  };
  return newapi.get('/states', data, { headers: authHeaders() });
};

const getState = ({ stateId }) => newapi
  .get(`/states/${stateId}`, null, { headers: authHeaders() });

const getNewDistricts = (page, pageSize, sortInfo, filtersInfo, simpleList) => {
  const data = {
    page,
    page_size: pageSize,
    ...getSortingProperties(sortInfo),
    ...getFilterProperties(filtersInfo)
  };
  const url = simpleList ? '/districts/all_districts' : '/districts';
  return newapi.get(url, data, { headers: authHeaders() });
};

const getNewDistrict = districtId => newapi
  .get(`/districts/${districtId}`, null, { headers: authHeaders() });

const updateDistrict = (districtId, data) => newapi
  .put(`/districts/${districtId}`, data, { headers: authHeaders() });

const updateDistrictSettings = (districtSettingsId, data) => newapi
  .put(`/district_settings/${districtSettingsId}`, data, { headers: authHeaders() });

const getFeedback = (districtId, schoolId, page, pageSize, sortInfo, filtersInfo) => {
  const data = {
    page,
    page_size: pageSize,
    ...getSortingProperties(sortInfo),
    ...getDistrictOrSchoolProperty({ districtId, schoolId }),
    ...getFilterProperties(filtersInfo)
  };
  return newapi.get('/feedback', data, { headers: authHeaders() });
};

const getFeedbackOne = ({ feedbackId }) => newapi
  .get(`/feedback/${feedbackId}`, null, { headers: authHeaders() });

const getRidershipEvents = (page, pageSize, sortInfo, filtersInfo) => {
  const data = {
    page,
    page_size: pageSize,
    ...getSortingProperties(sortInfo),
    ...getFilterProperties(filtersInfo)
  };
  return newapi.get('/ridership_events', data, { headers: authHeaders() });
};

const getRidershipEvent = ({ id }) => newapi.get(`/ridership_events/${id}`, null, {
  headers: authHeaders()
});

const getStudentSecurityCode = studentId => api
  .get(`/admin/students/${studentId}/security-code`, null, { headers: authHeaders() });

const getToken = (emailOrPhone, loginToken) => newapi.post('/get-token', {
  email_or_phone: emailOrPhone,
  login_token: loginToken
});

const getStudents = (districtId, schoolId, page, pageSize, sortInfo, filtersInfo, activityStudents) => {
  const data = {
    page,
    page_size: pageSize,
    ...getSortingProperties(sortInfo),
    ...getDistrictOrSchoolProperty({ districtId, schoolId }),
    ...getFilterProperties(filtersInfo)
  };

  if (activityStudents) {
    data.activity_students = true;
  }

  return newapi.get('/students', data, { headers: authHeaders() });
};

const getUsers = (page, pageSize, sortInfo, filtersInfo) => {
  const data = {
    page,
    page_size: pageSize,
    ...getSortingProperties(sortInfo),
    ...getFilterProperties(filtersInfo)
  };

  return newapi.get('/users', data, { headers: authHeaders() });
};

const getCSV = ({ sortInfo, filtersInfo, type }) => {
  const data = {
    ...getSortingProperties(sortInfo),
    ...getFilterProperties(filtersInfo)
  };

  return newapi.get(`/${type}.csv`, data, { headers: authHeaders(), timeout: 70000 });
};

const getFilterOptions = ({ entity, field }) => {
  const data = {
    field
  };

  const urlEntity = entity.replace('-', '_');

  return newapi.get(`/${urlEntity}/field-values`, data, { headers: authHeaders() });
};

const getUser = ({ userId }) => newapi.get(`/users/${userId}`, null, { headers: authHeaders() });

const getDashboardUsers = (districtId, schoolId, page, pageSize, sortInfo, filtersInfo) => {
  const data = {
    page,
    page_size: pageSize,
    ...getSortingProperties(sortInfo),
    ...getFilterProperties(filtersInfo)
  };
  return newapi.get('/district-dashboard-users', data, { headers: authHeaders() });
};

const getDashboardUser = ({ userId }) => newapi
  .get(`/district-dashboard-users/${userId}`, null, { headers: authHeaders() });

const resetPassword = emailOrPhone => api.post('/password-reset', {
  email_or_phone: emailOrPhone
});

const resetStudentSecurityCode = studentId => api
  .post(`/admin/students/${studentId}/reset-security-code`, null, { headers: authHeaders() });

const signIn = (emailOrPhone, password) => newapi.post('/sign-in', {
  device_name: client.getBrowser(),
  device_uid: client.getFingerprint(),
  email_or_phone: emailOrPhone,
  password
});

const suspendUser = userId => api
  .post(`/admin/users/${userId}/suspend`, null, { headers: authHeaders() });

const unsuspendUser = userId => api
  .post(`/admin/users/${userId}/unsuspend`, null, { headers: authHeaders() });

const sendSchoolServiceAlert = ({
  schoolId, title, message, category
}) => api.post(
  `/admin/service_alerts/school/send`,
  {
    school_id: schoolId,
    title,
    message,
    category
  },
  { headers: authHeaders() }
);

const sendDistrictServiceAlert = ({
  districtId, title, message, category
}) => api.post(
  `/admin/service_alerts/district/send`,
  {
    district_id: districtId,
    title,
    message,
    category
  },
  { headers: authHeaders() }
);

const sendTaskServiceAlert = ({
  districtId,
  schoolId,
  title,
  message,
  category,
  contractorId,
  serviceNumber,
  dispatchType,
  startTime,
  runCode
}) => api.post(
  `/admin/service_alerts/task/send`,
  omitBy(
    {
      ...getDistrictOrSchoolProperty({ districtId, schoolId }),
      title,
      message,
      category,
      contractor_id: contractorId,
      service_number: serviceNumber,
      dispatch_type: toUpper(dispatchType),
      start_time: startTime,
      run_code: runCode
    },
    isNil
  ),
  { headers: authHeaders() }
);

const sendFeedback = ({
  name, email, districtId, schoolId, category, message
}) => api.post(
  `/feedback`,
  {
    source: 'district_admin',
    name,
    email,
    district_id: districtId,
    school_id: schoolId,
    category,
    message
  },
  { headers: authHeaders() }
);

const disableStudent = studentId => api
  .post(`/admin/students/${studentId}/disable`, null, { headers: authHeaders() });

const enableStudent = studentId => api
  .post(`/admin/students/${studentId}/enable`, null, { headers: authHeaders() });

const deleteUser = studentId => newapi
  .delete(`/users/${studentId}`, null, { headers: authHeaders() });

const assumeUser = studentId => newapi
  .patch(`/users/${studentId}/forced_password_reset`, null, { headers: authHeaders() });

const sendPasswordResetEmail = studentId => newapi
  .post(`/users/${studentId}/send_reset_password_email`, null, { headers: authHeaders() });

const removeNotificationContact = userId => newapi
  .delete(`/notification_contact/${userId}`, null, { headers: authHeaders() });

const sendUserTestNotification = ({ userId, subject, message }) => newapi
  .post('send-notification',
    {
      subject_class: 'user',
      subject_ids: userId,
      subject,
      message,
    },
    { headers: authHeaders() })

const getTransformScheduleList = (page, pageSize, sortInfo, filtersInfo) => {
  const data = {
    page,
    page_size: pageSize,
    ...getSortingProperties(sortInfo),
    ...getFilterProperties(filtersInfo)
  };
  return newapi
    .get('transform_schedule_requests', data, { headers: authHeaders() })
}

const getTransformSchedule = ({ transformScheduleId }) => newapi
  .get('transform_schedule_requests/' + transformScheduleId, null, { headers: authHeaders() })

const resendConfirmationEmail = (notificationContactId) => newapi.get(`notification_contact/${notificationContactId}/resend_notification_email`, null, { headers: authHeaders() })

export default {
  removeNotificationContact,
  addUserAccess,
  disableStudent,
  enableStudent,
  deleteUser,
  assumeUser,
  sendPasswordResetEmail,
  exportSecurityCodes,
  getAccess,
  getDistrictBanner,
  getDistrictTypes,
  getDashboardUser,
  getDashboardUsers,
  getDistrict,
  getFeedback,
  getFeedbackOne,
  getNewDistrict,
  getNewDistricts,
  getProfile,
  getRoute,
  getRouteReplay,
  getSchool,
  getSchools,
  getServiceAlertOne,
  getServiceAlerts,
  getState,
  getStates,
  getStats,
  getStopRoutes,
  getStopStudents,
  getStopUsers,
  getStudent,
  getStudents,
  getStudentSecurityCode,
  getToken,
  getUser,
  getUsers,
  getCSV,
  getFilterOptions,
  updateDistrictBanner,
  updateDistrictType,
  postUserSettings,
  removeUserAccess,
  resetPassword,
  resetStudentSecurityCode,
  sendDistrictServiceAlert,
  sendFeedback,
  sendSchoolServiceAlert,
  sendTaskServiceAlert,
  signIn,
  suspendUser,
  unsuspendUser,
  updateDistrict,
  updateDistrictSettings,
  getRidershipEvents,
  getRidershipEvent,
  sendUserTestNotification,
  getTransformScheduleList,
  getTransformSchedule,
  resendConfirmationEmail,
};
