import React from "react";
import { FunctionField } from "react-admin";

const CustomerLinkField = props => (
  <FunctionField
    {...props}
    render={record => (record.user
      ? `${record.user.first_name} ${record.user.last_name}`
      : "")}
  />
);

CustomerLinkField.defaultProps = {
  source: "user",
  addLabel: true,
  sortBy: "user_first_name"
};

export default CustomerLinkField;
