// This approach directly hooks into API calls for validation then calls the AuthSaga to set state.
// Advantage is that there is no reliance on state being set or delay timers.
// Disadvantage is that it multiplies code and there is repetition on the API calls.

// TODO: Refactor with async / promise chaining

// USING THIS VERSION

import {
  AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_ERROR
} from 'react-admin';
import SecureLS from 'secure-ls';
import localforage from 'localforage';
import api from './dd_auth_module/services/api';

const ls = new SecureLS({ encodingType: 'aes' });

const getToken = (emailOrPhone, loginToken) => api.getToken(emailOrPhone, loginToken).then(response => {
  const {message} = response.data;
  const {code} = response.data.response;
  if (message !== 'Success' || code !== 200) {
    ls.removeAll();
    localStorage.clear();
    localforage.clear();
    return Promise.reject();
  }
  const authToken = response.data.auth_token;
  ls.set('token', authToken);
  ls.set('emailOrPhone', emailOrPhone);
  ls.set('pauseForTokenRefresh', 1);
  const tokenDuration = (response.data.expiry - 60) * 100;
  ls.set('tokenDuration', tokenDuration);
  const currentTime = Date.now();
  ls.set('tokenSet', currentTime);
  ls.remove('loginErrorMessage');
  return Promise.resolve();
});

const signIn = (emailOrPhone, password, remember) => {
  if (remember) {
    return api.resetPassword(emailOrPhone).then(response => {
      ls.set('loginErrorMessage', response.data.message);
      return Promise.reject();
    });
  }
  ls.removeAll();
  localStorage.clear();
  localforage.clear();
  return api.signIn(emailOrPhone, password).then(response => {
    const {
      message,
      response: res,
      login_token: loginToken,
      errors,
    } = response.data || {};
    if (message !== 'Success' || res === undefined || res.code !== 200) {
      const { email_or_phone: emailError } = errors || {};
      ls.set('loginErrorMessage', emailError || message );
      console.log(response.originalError ? response.originalError.message + ' Are all CORS Headers present? Could it be Redis?' : '');
      return Promise.reject();
    }
    ls.set('loginToken', loginToken);
    ls.set('authenticated', 'yes');
    return getToken(emailOrPhone, loginToken, password);
  });
};

const setTime = () => {
  const groundzero = Date.now();
  ls.set('lastchecked', groundzero);
};

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const { emailOrPhone, password, remember } = params;
    setTime();
    return signIn(emailOrPhone, password, remember);
  }
  if (type === AUTH_LOGOUT) {
    ls.removeAll();
    localStorage.clear();
    localforage.clear();
    return Promise.resolve();
  }
  if (type === AUTH_ERROR) {
    ls.removeAll();
    localStorage.clear();
    localforage.clear();
    return Promise.reject();
  }
  if (type === AUTH_CHECK) {
    const tokenDuration = ls.get('tokenDuration');
    const tokenSet = ls.get('tokenSet');
    const currentTime = Date.now();
    const elapsed = currentTime - tokenSet;
    if (elapsed > tokenDuration) {
      ls.set('pauseForTokenRefresh', 1000);
      const loginToken = ls.get('loginToken');
      const emailOrPhone = ls.get('emailOrPhone');
      return api.getToken(emailOrPhone, loginToken).then(response => {
        ls.set('pauseForTokenRefresh', 1);
        const {ok} = response;
        if (!ok) {
          ls.removeAll();
          localStorage.clear();
          localforage.clear();
        } else {
          const {message} = response.data;
          const {code} = response.data.response;
          if (message !== 'Success' || code !== 200) {
            ls.removeAll();
            localStorage.clear();
            localforage.clear();
          } else {
            setTime();
            const authToken = response.data.auth_token;
            ls.set('token', authToken);
            const tokenDuration = (response.data.expiry - 60) * 1000;
            ls.set('tokenDuration', tokenDuration);
            const currentTime = Date.now();
            ls.set('tokenSet', currentTime);
          }
        }
        return ls.get('token') ? Promise.resolve() : Promise.reject();
      });
    }
    return ls.get('token') ? Promise.resolve() : Promise.reject();
  }
  return Promise.reject('Unknown method');
};
