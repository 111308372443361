import immutable from "seamless-immutable";
import core from "../../core/env";

const log = (type, resource, params, response) => {
  if (
    core.api.baseUrl === "https://dev.firstviewbackend.com"
  ) {
    console.log("request ", type, resource, params);
    console.log("response", response);
  }
};

const debug = message => {
  console.log(message);
};

const extractDistrictData = currentValue => currentValue.district;

const dynamicSort = (property, order) => {
  let sortOrder = 1;
  if (order === "DESC") {
    sortOrder = -1;
  }
  return (a, b) => {
    const getResult = () => {
      if (a[property] < b[property]) return -1;
      if (a[property] > b[property]) return 1;
      return 0;
    };
    const result = getResult();
    return result * sortOrder;
  };
};

const bundle = (datastream, type, resource, params, count) => ({
  datastream,
  type,
  resource,
  params,
  count
});

const sortResponse = dataBundle => new Promise(resolve => {
  const { datastream, count } = dataBundle;
  const total = count;
  // datastream.sort(dynamicSort(field, order));
  const responseData = {
    data: datastream,
    total
  };
  resolve(responseData);
});

const sortDistrictResponse = databundle => new Promise(resolve => {
  const { datastream, params } = databundle;
  const { districts } = datastream;
  const { page, perPage } = params.pagination;
  const { field, order } = params.sort;
  // EXTRACT RELEVAT DATA FOR FRONT END
  const mappedDistrictData = districts.map(extractDistrictData);
  const mutableArray = immutable.asMutable(mappedDistrictData);
  // let mappedCopy = mappedDistrictData;
  mutableArray.sort(dynamicSort(field, order));
  // quick and easy pagination
  const splitstart = (page - 1) * 10;
  const splitend = splitstart + perPage;
  const mutableChunk = mutableArray.slice(splitstart, splitend);
  // GET COUNT
  const total = mutableArray.length;
  const responseData = {
    data: mutableChunk,
    total
  };
  resolve(responseData);
});

export {
  log,
  debug,
  extractDistrictData,
  dynamicSort,
  bundle,
  sortResponse,
  sortDistrictResponse
};
