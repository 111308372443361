import React from "react";

const styles = {
  main: {
    textAlign: "left",
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.875rem',
    fontWeight: 400,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: '1.46429em'
  }
};

const ServiceAlertOriginField = ({ record, originTypes }) => {
  const mapping = Object.values(originTypes).reduce((acc, { id, name }) => {
    acc[id] = name;
    return acc;
  }, {});

  const { origin } = record;
  const displayName = mapping[origin] || '';

  return <span style={styles.main}>{displayName}</span>;
};

ServiceAlertOriginField.defaultProps = {
  source: "origin",
  label: "Origin",
  addLabel: true
};

export default ServiceAlertOriginField;
