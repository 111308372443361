import {
  GET_LIST, // CURRENTLY DEFAULTING TO THIS
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY
} from 'react-admin';
import localforage from 'localforage';
import api from '../../dd_data_module/services/api';
import { bundle, sortResponse } from './commonProviderFunctions';

const getUsersList = (type, resource, params) => {
  const { field, order } = params.sort;
  let direction = 'ascend';
  if (order === 'DESC') {
    direction = 'descend';
  }
  if (field === 'id') {
    direction = 'ascend';
  }

  const { perPage } = params.pagination;
  let { page } = params.pagination;

  if (params.pagination.adjust !== undefined && page > 0) {
    page += 1;
  }

  return api.getDashboardUsers(null,
    null,
    page,
    perPage,
    { columnKey: field, order: direction },
    params.filter,
    false).then(response => new Promise((resolve => {
      const code = response.status;
      if (code !== 200) {
        localforage.getItem('usersdatabundle').then(databundle => {
          const { datastream, count } = databundle;
          // Pick up the new params
          const repackagedBundle = bundle(datastream, type, resource, params, count);
          const thisPromise = sortResponse(repackagedBundle);
          thisPromise.then(res => {
            resolve(res);
          });
        });
      } else {
        const users = response.data.result;
        const count = response.data.info.total_count;
        const databundle = bundle(users, type, resource, params, count);

        localforage.setItem('usersdatabundle', databundle).then(db => {
          const thisPromise = sortResponse(db);
          thisPromise.then(res => {
            resolve(res);
          });
        });
      }
    })));
};


const getUsersOne = params => api.getDashboardUser({ userId: params.id })
  .then(response => new Promise((resolve => {
    const { message } = response.data;
    const { code } = response.data.response;
    if (message !== 'Success' || code !== 200) {
      localforage.getItem('usersdatabundle').then(databundle => {
        resolve(databundle);
      });
    } else {
      const user = response.data.result;
      const databundle = { data: user };
      const date = new Date(databundle.data.created_at);
      databundle.data.created_at = date;
      localforage.setItem('usersdatabundle', databundle).then(db => {
        resolve(db);
      });
    }
  })));

const getDashboardUsersResponse = (type, resource, params) => {
  switch (type) {
    case GET_LIST: {
      return getUsersList(type, resource, params);
    }
    case GET_ONE:
      return getUsersOne(params);
    case GET_MANY:
      return {
        data: null
      };
    case GET_MANY_REFERENCE:
      return {
        data: null
      };
    case CREATE:
      return {
        data: null
      };
    case UPDATE:
      return {
        data: null
      };
    case UPDATE_MANY:
      return {
        data: null
      };
    case DELETE:
      return {
        data: null
      };
    case DELETE_MANY:
      return {
        data: null
      };
    default:
      return false;
  }
};

export default getDashboardUsersResponse;
