import {combineReducers} from 'redux';
import accessReducer from './accessReducer';
import accessUsersReducer from './accessUsersReducer';
import authReducer from './authReducer';
import authPersistentReducer from './authPersistentReducer';
import passwordReducer from './passwordReducer';

export default combineReducers({
  access: accessReducer,
  accessUsers: accessUsersReducer,
  auth: authReducer,
  authPersistent: authPersistentReducer,
  password: passwordReducer
});
