import React from "react";
import moment from "moment";

const styles = {
  main: {
    textAlign: "left",
    fontSize: "0.875rem"
  }
};

const FormattedFullDateTimeField = ({ record }) => {
  let dateTime;
  if (record.event_time_local) {
    dateTime = moment.parseZone(record.event_time_local).format("L, LTS UTCZ");
  } 
  return dateTime
    ? <span style={styles.main}>{dateTime}</span>
    : <span />;
};

FormattedFullDateTimeField.defaultProps = {
  source: "Event Time Local",
  label: "Event Time (Local)",
  addLabel: true
};

export default FormattedFullDateTimeField;
