import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { translate, Link } from 'react-admin';
import CardIcon from './CardIcon';

const styles = {
  main: {
    marginTop: 20,
    minWidth: '400px',
    marginRight: '20px'
  },
  mainRight: {
    flex: '1',
    marginTop: 20
  },
  cardOff: {
    overflow: 'inherit',
    textAlign: 'left',
    paddingTop: '70px',
    padding: 16,
    minHeight: 52,
    border: '1px solid #f1f1f1',
    background: '#f1f1f1',
    maxWidth: '370px'
  },
  cardOn: {
    overflow: 'inherit',
    textAlign: 'left',
    paddingTop: '70px',
    padding: 16,
    minHeight: 52,
    border: '1px dotted #001E61',
    background: '#f1f1f1',
    maxWidth: '370px'
  },
  cardNormal: {
    overflow: 'inherit',
    textAlign: 'left',
    paddingTop: '70px',
    padding: 16,
    minHeight: 52,
    maxWidth: '370px',
    border: '1px solid #ccc',
    margin: 0
  }
};

const larger = {
  fontSize: '1.3em'
};

const pushdown = {
  width: '100%',
  paddingBottom: '10px',
  marginBottom: '10px',
  borderBottom: '1px solid #eee'
};

const pushdownsimple = {
  width: '100%',
  paddingBottom: '10px',
  marginBottom: '10px'
};

class Notifier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false
    };
  }

  toggleHover = () => {
    this.setState({ hover: !this.state.hover });
  };

  render() {
    let {
      text,
      color,
      icon,
      student,
      stop,
      type,
      isGPSStudent,
      triggeredToday,
      triggeredPrevious,
      classes,
      link,
      todayTriggeredTime,
      previousTriggeredTime,
      routeName,
      isActivityRoute,
    } = this.props;
    let classname = classes.cardOff;
    if (this.state.hover) {
      classname = classes.cardOn;
    }
    if (triggeredToday === 'Yes') {
      triggeredToday = `${triggeredToday} ${todayTriggeredTime}`;
    }
    if (triggeredPrevious === 'Yes') {
      triggeredPrevious = `${triggeredPrevious} ${previousTriggeredTime}`;
    }
    let inner = (
      <Link to={`/${link}`}>
        <Card onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover} className={classname}>
          <div style={pushdownsimple}>
            <Typography className={classes.title} color="textSecondary">
              {text}
            </Typography>
          </div>
          <div style={pushdown}>
            <Typography variant="body2">
              Student: <span style={larger}>{student}</span>
            </Typography>
          </div>
          <div style={pushdown}>
            <Typography variant="body2">
              Stop: <span style={larger}>{stop}</span>
            </Typography>
          </div>
          <div style={pushdown}>
            <Typography variant="body2">
              Boundary: <span style={larger}>{type}</span>
            </Typography>
          </div>
          <div style={pushdown}>
            <Typography variant="body2">
              Triggered Today? <span style={larger}>{triggeredToday}</span>
            </Typography>
          </div>
          <div style={pushdown}>
            <Typography variant="body2">
              Triggered Previous? <span style={larger}>{triggeredPrevious}</span>
            </Typography>
          </div>
        </Card>
      </Link>
    );

    if (!link) {
      inner = (
        <Card className={classes.cardNormal}>
          <div style={pushdownsimple}>
            <Typography className={classes.title} color="textSecondary">
              {text}
            </Typography>
          </div>
          <div style={pushdown}>
            <Typography variant="body2">
              Student: <span style={larger}>{student}</span>
            </Typography>
          </div><div style={pushdown}>
            <Typography variant="body2">
              {isActivityRoute ? 'Activity' : 'Route'}: <span style={larger}>{routeName}</span>
            </Typography>
          </div>
          <div style={pushdown}>
            <Typography variant="body2">
              {isGPSStudent ? "Location" : "Stop"}: <span style={larger}>{stop}</span>
            </Typography>
          </div>
          <div style={pushdown}>
            <Typography variant="body2">
              Boundary: <span style={larger}>{type}</span>
            </Typography>
          </div>
          <div style={pushdown}>
            <Typography variant="body2">
              Triggered Today? <span style={larger}>{triggeredToday}</span>
            </Typography>
          </div>
          <div style={pushdown}>
            <Typography variant="body2">
              Triggered Previous? <span style={larger}>{triggeredPrevious}</span>
            </Typography>
          </div>
        </Card>
      );
    }
    return (
      <div className={classes.main}>
        <CardIcon Icon={icon} bgColor={color} />
        {inner}
      </div>
    );
  }
}

export default translate(withStyles(styles)(Notifier));
