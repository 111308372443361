import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Modal from 'react-modal';
import SecureLS from 'secure-ls';
import mainLogo from '../fv.png';
import env from '../core/env'

const ls = new SecureLS({ encodingType: 'aes' });

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

const invalidEmailStyle = {
  borderBottom: '2px solid #ec1c24'
};

const neutralEmailStyle = {
  borderBottom: '2px solid #fff'
};

const validEmailStyle = {
  borderBottom: '2px solid #8bc53e'
};

const centerStyle = {
  display: 'flex',
  justifyContent: 'center',
  marginTop: '20px'
};

const forgotEmailStyle = {
  display: 'flex',
  justifyContent: 'center',
  marginTop: '20px',
  color: '#f00',
  fontSize: '.9em',
  textDecoration: 'none'
};

const errorStyle = {
  backgroundColor: '#ee3238',
  padding: '10px',
  margin: '10px',
  color: '#fff'
};

class LoginForm extends React.Component {
  static propTypes = {
    userLogin: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      remember: false,
      modalIsOpen: true,
      email: '',
      password: '',
      loginErrorMessage: '',
      validEmail: 'neutral'
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  handleChange = name => event => {
    const {value} = event.target;
    const {checked} = event.target;
    const {length} = value;
    if (value === 'checkedB' && !checked) {
      this.setState({
        remember: false
      });
    } else {
      this.setState({
        [name]: value
      });
    }
    if (name === 'email' && length > 0) {
      if (this.validEmail(value)) {
        this.setState({ validEmail: 'valid' });
      } else {
        this.setState({ validEmail: 'invalid' });
      }
    }
  };

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  validEmail = email => {
    // eslint-disable-next-line no-control-regex, max-len
    const regex = /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
    return regex.test(email);
  };

  submit = e => {
    e.preventDefault();
    // gather your data/credentials here
    const credentials = {
      emailOrPhone: this.state.email,
      password: this.state.password,
      remember: this.state.remember
    };

    // Dispatch the userLogin action (injected by connect)
    this.props.userLogin(credentials);

    setTimeout(() => {
      const loginErrorMessage = ls.get('loginErrorMessage');
      this.setState({ loginErrorMessage });
    }, 1000);
  };

  render() {
    const {validEmail, loginErrorMessage, modalIsOpen} = this.state;
    let emailField = (
      <TextField
        required={true}
        id="required"
        label="Email"
        autofill="off"
        defaultValue=""
        margin="normal"
        style={neutralEmailStyle}
        autoFocus={true}
        fullWidth={true}
        placeholder="Email"
        onChange={this.handleChange('email')}
      />
    );

    if (validEmail === 'valid') {
      emailField = (
        <TextField
          required={true}
          id="required"
          label="Email"
          autofill="off"
          defaultValue=""
          margin="normal"
          style={validEmailStyle}
          autoFocus={true}
          fullWidth={true}
          placeholder="Email"
          onChange={this.handleChange('email')}
        />
      );
    }

    if (validEmail === 'invalid') {
      emailField = (
        <TextField
          required={true}
          id="required"
          label="Email"
          autofill="off"
          defaultValue=""
          margin="normal"
          style={invalidEmailStyle}
          autoFocus={true}
          fullWidth={true}
          placeholder="Email"
          onChange={this.handleChange('email')}
        />
      );
    }

    let errorMessage = <div style={errorStyle}>{loginErrorMessage}</div>;
    if (loginErrorMessage === null || loginErrorMessage.length === 0) {
      errorMessage = <span />;
    }
    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={this.closeModal}
        style={customStyles}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
        contentLabel="Example Modal"
      >
        <div style={centerStyle}>
          <img src={mainLogo} height="150" alt="FirstView Hub"/>
        </div>
        <form onSubmit={this.submit} noValidate autoComplete="off">
          {emailField}
          <TextField
            required={true}
            id="password-input"
            label="Password"
            type="password"
            autoComplete="current-password"
            margin="normal"
            fullWidth={true}
            onChange={this.handleChange('password')}
          />
          {errorMessage}
          <div style={centerStyle}>
            <Button type="Submit" variant="contained" color="primary">
              Submit
            </Button>
          </div>
          <div style={forgotEmailStyle}>
            <a href={env.forgottenPasswordUrl}>Forgot Password</a>
          </div>
        </form>
      </Modal>
    );
  }
}

export default LoginForm;
