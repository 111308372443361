import {
  GET_LIST, // CURRENTLY DEFAULTING TO THIS
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY
} from "react-admin";
import localforage from "localforage";
import api from "../../dd_data_module/services/api";
import { bundle, sortResponse } from "./commonProviderFunctions";

const getActivityStudentsList = (type, resource, params) => {
  const { field, order } = params.sort;
  let direction = "ascend";
  if (order === "DESC") {
    direction = "descend";
  }
  let { district } = params;
  const { school } = params;
  if (district === undefined && school === undefined) {
    district = 12;
  }
  if (field === "id") {
    direction = "ascend";
  }

  const { perPage } = params.pagination;
  let { page } = params.pagination;

  if (params.pagination.adjust !== undefined && page > 0) {
    page += 1;
  }
  return api
    .getStudents(
      district,
      school,
      page,
      perPage,
      { columnKey: field, order: direction },
      params.filter,
      true
    )
    .then(response => new Promise(((resolve, reject) => {
      const code = response.status;
      if (code !== 200) {
        localforage.getItem("studentsdatabundle").then(databundle => {
          if (databundle) {
            const { datastream, count } = databundle;
            const repackagedBundle = bundle(
              datastream,
              type,
              resource,
              params,
              count
            ); // Pick up the new params
            const thisPromise = sortResponse(repackagedBundle);
            thisPromise.then(res => {
              resolve(res);
            });
          } else {
            reject(response);
          }
        });
      } else {
        const students = response.data.result;
        const count = response.data.info.total_count;
        const databundle = bundle(students, type, resource, params, count);

        localforage
          .setItem("studentsdatabundle", databundle)
          .then(db => {
            const thisPromise = sortResponse(db);
            thisPromise.then(res => {
              resolve(res);
            });
          });
      }
    })));
};

const getActivityStudentsOne = params => api.getStudent(
  { studentId: params.id, activity_students: true }
).then(response => new Promise((resolve => {
  const { message } = response.data;
  const { code } = response.data.response;
  if (message !== "Success" || code !== 200) {
    localforage.getItem("studentsdatabundle").then(databundle => {
      resolve(databundle);
    });
  } else {
    const student = response.data.result;
    const databundle = { data: student };
    localforage
      .setItem("studentsdatabundle", databundle)
      .then(db => {
        resolve(db);
      });
  }
})));

const getActivityStudentsResponse = (type, resource, params) => {
  switch (type) {
    case GET_LIST: {
      return getActivityStudentsList(type, resource, params);
    }
    case GET_ONE:
      return getActivityStudentsOne(params);
    case GET_MANY:
      return {
        data: null
      };
    case GET_MANY_REFERENCE:
      return {
        data: null
      };
    case CREATE:
      return {
        data: null
      };
    case UPDATE:
      return {
        data: null
      };
    case UPDATE_MANY:
      return {
        data: null
      };
    case DELETE:
      return {
        data: null
      };
    case DELETE_MANY:
      return {
        data: null
      };
    default:
      return false;
  }
};

export default getActivityStudentsResponse;
