import React from 'react';

const color = '#001E61';

const Header = ({ content}) => {
  const style = {
    color,
    width: '100%',
    textAlign: 'left',
    fontSize: '.8em',
    padding: '0',
    marginBottom: '5px',
    fontWeight: '400'
  };


  return (<h2 style={style}>{content}</h2>);
};

export default Header;
