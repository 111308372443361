import React from "react";
import moment from "moment-timezone";

const styles = {
  main: {
    textAlign: "left",
    fontSize: "0.875rem"
  }
};

export const formatTimeZone = timeZone => {
  const [, outerMarker] = timeZone.split("/");
  const marker = outerMarker
    ? ` (${outerMarker.replace(/[_]/ig, " ")})`
    : '';
  return `UTC ${moment.tz(timeZone).format("Z")}${marker}`;
};

const TimeZoneField = ({ record }) => {
  let zone;

  if (record?.time_zone) {
    zone = formatTimeZone(record.time_zone);
  } else if (record?.district?.time_zone) {
    zone = formatTimeZone(record.district.time_zone);
  }
  return zone
    ? <span style={styles.main}>{zone}</span>
    : <span />;
};

TimeZoneField.defaultProps = {
  source: "Time Zone",
  label: "Time Zone",
  addLabel: true
};

export default TimeZoneField;
