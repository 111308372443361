import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import ActivityStuduentsIconWhite from '../../icons/ActivityStudentsIconWhite';

const useStyles = makeStyles(() => ({
  formControl: {
    margin: '8px 15px',
    minWidth: 120,
    "& .MuiOutlinedInput-input": {
      padding: '10px 34px 10px 14px'
    }
  },
  tableBackground: {
    marginBottom: 20,
    marginTop: 20,
    background: 'rgb(255, 255, 255)',
    width: 'auto',
    height: 'auto',
    minHeight: 40,
    paddingTop: 1,
    paddingBottom: 10,
    // eslint-disable-next-line max-len
    boxShadow: 'rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 10%) 0px 1px 1px 0px, rgb(0 0 0 / 10%) 0px 2px 1px -1px',
  },
  cardIcon: {
    backgroundColor: 'rgb(94, 111, 144)',
    float: 'left',
    margin: '-10px 20px 0 15px',
    zIndex: 100,
    borderRadius: 3,
    // eslint-disable-next-line max-len
    boxShadow: '0px 1px 3px 0px rgb(0 0 0 / 10%), 0px 1px 1px 0px rgb(0 0 0 / 10%), 0px 2px 1px -1px rgb(0 0 0 / 10%)',
    "& svg": {
      color: '#fff',
      float: 'right',
      width: 34,
      height: 34,
      padding: 14,
    }
  },
  dateSelectorRow: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'center',
    marginTop: 70,
    "& p": {
      margin: 0,
      marginLeft: 15,
    }
  },
  subtitleText: {
    margin: 0,
    marginLeft: 'auto',
    marginRight: 15,
    color: 'rgb(94, 113, 115)',
    paddingLeft: 20,
    paddingTop: 0,
    fontFamily: '"Roboto Slab", serif',
    display: 'flex',
    alignItems: 'center',
    fontSize: 24,
  },
  noResults: {
    margin: 15,
  },
  dataTable: {
    width: 'calc(100% - 30px)',
    margin: 15,
    // eslint-disable-next-line max-len
    boxShadow: '0px 1px 3px 0px rgb(0 0 0 / 10%), 0px 1px 1px 0px rgb(0 0 0 / 10%), 0px 2px 1px -1px rgb(0 0 0 / 10%)',
    "& * th, * td": {
      margin: 5,
    }
  },
  dataTableRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderLeft: '1px solid #F8F8F8',
    "& p": {
      width: '20%',
      margin: 5,
      minHeight: 30,
      fontSize: 14,
      padding: '12px 16px 4px',
      textAlign: 'left',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
      wordBreak: 'break-word',
    },
  },
  dataTableRowHeader: {
    backgroundColor: 'white',
    margin: 0,
    minHeight: 30,
    color: '#5E7173',
    fontSize: '.9em',
    borderTop: '1px solid #eee',
    textAlign: 'left',
    fontWeight: 400,
    lineHeight: 0,
  },
  dataTableRowOdd: {
    backgroundColor: 'white',
  },
  dataTableRowEven: {
    backgroundColor: '#F8F8F8',
  },
}));

const tableDateFormat = 'dddd, MMMM Do';
const createdDateFormat = 'MM/DD/YYYY';
const momentCompatibleFormat = 'YYYY-MM-DD';
const dayFormat = 'dddd';

const formatCreatedDate = date => moment.utc(date).format(createdDateFormat);
const formatDay = date => moment.utc(date).format(dayFormat);
const formatSelection = date => moment.utc(date).format(tableDateFormat);

const availableSelectDays = () => {
  const arrayOfDays = [];
  for (let i = 0; arrayOfDays.length <= 7; i++) {
    const momentDay = moment().subtract(i, 'days');
    arrayOfDays.push({
      id: momentDay.format(momentCompatibleFormat),
      name: momentDay.format(tableDateFormat),
    });
  }
  return arrayOfDays;
};

const ActivityTrackingTable = ({ data }) => {
  const [selected, updateSelected] = useState({name: moment()});
  const [availableDates, updateAvailableDates] = useState([]);
  const {
    tableBackground,
    cardIcon,
    dateSelectorRow,
    formControl,
    subtitleText,
    noResults,
    dataTable,
    dataTableRowHeader,
    dataTableRow,
    dataTableRowEven,
    dataTableRowOdd,
  } = useStyles();

  useEffect(() => {
    const dates = availableSelectDays();
    updateAvailableDates(dates);
    updateSelected(dates[0]);
    return () => {
      updateAvailableDates([]);
    };
  }, [data]);

  const handleDateChange = event => {
    updateSelected(availableDates.filter(({ name: dateName }) => {
      return event.target.value === dateName;
    })[0]);
  };

  const getFilteredData = () => data.filter(
    ({  start_datetime: startDateTime,
        end_datetime: endDateTime,
        valid_friday: validFriday,
        valid_saturday: validSaturday,
        valid_sunday: validSunday,
        valid_monday: validMonday,
        valid_tuesday: validTuesday,
        valid_wednesday: validWednesday,
        valid_thursday: validThursday,
     }) => {
        const formattedStartDate = moment(startDateTime).format(momentCompatibleFormat);
        const formattedEndDate = moment(endDateTime).format(momentCompatibleFormat);
        return selected.id &&
          eval('valid' + formatDay(selected.id)) &&
          moment(selected.id).isBetween(formattedStartDate, formattedEndDate, null, []);
     }
  );

  if (data.length < 1) {
    return <div className={tableBackground}>
      <div className={cardIcon}>
        <ActivityStuduentsIconWhite className="activity-card-icon" />
      </div>
      <div className={dateSelectorRow}>
        <h2 className={subtitleText}>Activities</h2>
      </div>
      <div className={noResults}>
        <p>Student has no activities</p>
      </div>
    </div>;
  }

  return (
    <div className={tableBackground}>
      <div className={cardIcon}>
        <ActivityStuduentsIconWhite className="activity-card-icon" />
      </div>
      <div className={dateSelectorRow}>
        <FormControl variant="outlined" className={formControl}>
          <Select
            id="activity-date-selector"
            value={selected.name}
            onChange={handleDateChange}
          >
            {availableDates.map(date => (
              <MenuItem key={date.name} value={date.name}>{date.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <h2 className={subtitleText}>Activities</h2>
      </div>
      <div className={dataTable}>
        <div className={`${dataTableRow} ${dataTableRowHeader}`}>
          <p>Run Date</p>
          <p>Disptach Type</p>
          <p>End Date</p>
          <p>Activity Name</p>
          <p>Time Zone</p>
          <p>Created Date</p>
        </div>
        {getFilteredData().map((route, idx) => {
          const {
            route: name,
            dispatch_type: dispatchType,
            stop,
            end_datetime: endDateTime,
            created_at: createdAt,
            time_zone: timeZone,
          } = route;
          const {
            id,
          } = stop || {};
          const otherCls = idx % 2 === 0
            ? dataTableRowEven
            : dataTableRowOdd;
          return <div key={id} className={`${dataTableRow} ${otherCls}`}>
            <p>{formatSelection(selected.id)}</p>
            <p>{formatSelection(endDateTime)}</p>
            <p>{name}</p>
            <p>{dispatchType}</p>
            <p>{timeZone}</p>
            <p>{createdAt ? formatCreatedDate(createdAt) : null}</p>
          </div>;
        })}
      </div>
    </div>
  );
};

ActivityTrackingTable.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ActivityTrackingTable;
