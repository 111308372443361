import immutable from 'seamless-immutable';
import {createReducer} from 'reduxsauce';
import {
  isEmpty, values, forIn, camelCase
} from 'lodash';
import types from '../actions/types';

export const INITIAL_STATE = immutable({
  authToken: null,
  loginSubmitting: false,
  loginErrorCode: null,
  loginErrors: null,
  loginErrorMessage: null,
  tokenFetching: false,
  tokenErrorCode: null
});

const getTokenRequest = state => state.merge({
  tokenFetching: true,
  tokenErrorCode: null
});

const getTokenSuccess = (state, action) => state.merge({
  authToken: action.authToken,
  tokenFetching: false,
  tokenErrorCode: null
});

const getTokenFailure = (state, action) => state.merge({
  authToken: null,
  tokenFetching: false,
  tokenErrorCode: action.errorCode
});

const loginRequest = state => state.merge({
  loginSubmitting: true,
  loginErrorCode: null,
  loginErrors: null,
  loginErrorMessage: null
});

const loginSuccess = state => state.merge({
  tokenFetching: true,
  loginSubmitting: false,
  loginErrorCode: null,
  loginErrors: null,
  loginErrorMessage: null
});

const loginFailure = (state, action) => {
  const camelCasedErrors = {};
  forIn(action.errors, (value, key) => {
    camelCasedErrors[camelCase(key)] = value;
  });

  return state.merge({
    authToken: null,
    loginSubmitting: false,
    loginErrorCode: action.errorCode,
    loginErrors: !isEmpty(camelCasedErrors) ? camelCasedErrors : null,
    loginErrorMessage: !isEmpty(action.errors) ? `${values(action.errors).join('. ')}.` : action.message
  });
};

const setInitialPath = (state, action) => state.merge({
  initialPath: action.data
});

const removeInitialPath = state => state.merge({
  initialPath: null
});

const logout = () => INITIAL_STATE;

const ACTION_HANDLERS = {
  [types.GET_TOKEN]: getTokenRequest,
  [types.GET_TOKEN_SUCCESS]: getTokenSuccess,
  [types.GET_TOKEN_FAILURE]: getTokenFailure,
  [types.LOGIN]: loginRequest,
  [types.LOGIN_SUCCESS]: loginSuccess,
  [types.LOGIN_FAILURE]: loginFailure,
  [types.SET_INITIAL_PATH]: setInitialPath,
  [types.REMOVE_INITIAL_PATH]: removeInitialPath,
  [types.LOGOUT]: logout
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
