import React, { Component } from 'react';
import {
  Map, InfoWindow, Marker, GoogleApiWrapper
} from 'google-maps-react';
import PropTypes from 'prop-types';
import env from '../../core/env';
import {
  getStyle,
  findMapCenter,
  convertToArray,
  AmericaCenter,
} from './mapUtils';
import './map.css';

class HubGoogleMap extends Component {
  static MAP_ZOOM_CONFIG = {
    minUseMapZoom: 3,
    defaultMapZoom: 2,
  };

  constructor(props) {
    super(props);
    this.state = {
      center: {
        lat: AmericaCenter[0],
        lng: AmericaCenter[1],
      },
      showingInfoWindow: false,
      activeMarker: {},
    };

    this.addDistrictOrStateMarkers = this.addDistrictOrStateMarkers.bind(this);
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.onMapClick = this.onMapClick.bind(this);
  }

  componentDidMount() {
    this.setMapCenter();
  }

  componentDidUpdate(prevProps) {
    const { visible, multiMap } = this.props;
    if (prevProps.visible !== visible && multiMap) {
      this.setMapCenter();
    }
  }

  setMapCenter() {
    // Set the zoom and center
    const {
      states, students, schools, singleSchool
    } = this.props;
    let centerVector = AmericaCenter;

    if (states) {
      const center = findMapCenter(states);
      centerVector = [center.location.lat, center.location.lng];
    } else if (schools) {
      const center = findMapCenter(schools);
      centerVector = center.location
        ? [center.location.lat, center.location.lng]
        : [center.school.lat, center.school.lng];
    } else if (students) {
      const center = findMapCenter(students);
      if (center.stops.length > 0) {
        centerVector = [center.stops[0].lat, center.stops[0].lng];
      } else if (center.school) {
        centerVector = [center.school.lat, center.school.lng];
      }
    } else if (singleSchool) {
      const center = findMapCenter(singleSchool);
      if (center.location !== undefined) {
        centerVector = [center.location.lat, center.location.lng];
      } else {
        centerVector = [center.lat, center.lng];
      }
    }

    centerVector = {
      lat: centerVector[0],
      lng: centerVector[1],
    };

    this.setState({ center: { ...centerVector } });
  }

  onMarkerClick = (props, marker) => {
    this.setState({
      activeMarker: marker,
      showingInfoWindow: true,
    });
  }

  onMapClick = () => {
    if (this.state.showingInfoWindow) {
      this.setState({
        activeMarker: null,
        showingInfoWindow: false,
      });
    }
  }

  addStudentMarkers() {
    const { students } = this.props;
    if (students) {
      const studentsArr = convertToArray(students);
      return studentsArr.map(student => {
        const { stops, school, id } = student;
        if (stops.length > 0) {
          return stops.map(stop => {
            const {
              lat, lng, name, id: stopId
            } = stop;
            if (lat && lng) {
              return (
                <Marker
                  onClick={this.onMarkerClick}
                  position={{ lat, lng }}
                  title={name}
                  key={`student_${stopId}_${lat}_${lng}`}
                />
              );
            }
            return null;
          });
        }
        if (!school) { return null; }
        const { lat, lng, name } = school;
        if (lat && lng) {
          return (
            <Marker
              onClick={this.onMarkerClick}
              position={{ lat, lng }}
              title={name}
              key={`student_${id}_${lat}_${lng}`}
            />
          );
        }
        return null;
      });
    }
    return null;
  }

  addSingleSchoolMarkers() {
    const { singleSchool } = this.props;
    if (singleSchool) {
      const singleSchoolArr = convertToArray(singleSchool);
      return singleSchoolArr.map(school => {
        const {
          name, id, lat, lng
        } = school;
        const link = `/schools/${id}/show`;
        if (lat && lng) {
          return (
            <Marker
              onClick={this.onMarkerClick}
              position={{ lat, lng }}
              title={name}
              link={link}
              key={`single_school_${id}_${lat}_${lng}`}
            />
          );
        }
        return null;
      });
    }
    return null;
  }

  addSchoolMarkers() {
    const { schools } = this.props;
    if (schools) {
      const schoolsArr = convertToArray(schools);
      return schoolsArr.map(school => {
        const { lat, lng } = school.location || school.school;
        const name = school.name || school.school.name;
        const id = school.id || school.school.id;
        const link = `/schools/${id}/show`;
        if (lat && lng) {
          return (
            <Marker
              onClick={this.onMarkerClick}
              position={{ lat, lng }}
              title={name}
              link={link}
              key={`school_${id}_${lat}_${lng}`}
            />
          );
        }
        return null;
      });
    }
    return null;
  }

  addDistrictOrStateMarkers() {
    const { districts, states } = this.props;
    if (districts || states) {
      const data = districts || states;
      const dataArr = convertToArray(data);
      return dataArr.map(district => {
        const { id, name, location } = district;
        const { lat, lng } = location;
        const link = `/districts/${id}/show`;
        if (lat && lng) {
          return (
            <Marker
              onClick={this.onMarkerClick}
              position={{ lat, lng }}
              title={name}
              link={link}
              key={`${districts ? 'district_' : 'state_'}${id}_${lat}_${lng}`}
            />
          );
        }
        return null;
      });
    }
    return null;
  }

  getInfoWindowContent() {
    if (this.state.activeMarker.link) {
      return (
        <p className="infoWindow">
          <a href={this.state.activeMarker.link}>
            {this.state.activeMarker.title}
          </a>
        </p>
      );
    }
    return <p className="infoWindow">{this.state.activeMarker.title}</p>;
  }

  getInfoWindow() {
    return (
      <InfoWindow
        marker={this.state.activeMarker}
        visible={this.state.showingInfoWindow}
      >
        {this.state.activeMarker ? this.getInfoWindowContent() : <div></div>}
      </InfoWindow>
    );
  }

  render() {
    const {
      height, width, zoom, districts, visible
    } = this.props;
    const showMapControls = districts === undefined || !visible;
    return (
      <div style={getStyle(width, height)}>
        <Map
          key={`map${showMapControls ? '1' : '2'}`}
          google={this.props.google}
          center={this.state.center}
          zoom={zoom}
          minZoom={showMapControls
            ? HubGoogleMap.MAP_ZOOM_CONFIG.minUseMapZoom
            : HubGoogleMap.MAP_ZOOM_CONFIG.defaultMapZoom
          }
          onClick={this.onMapClick}
          gestureHandling={showMapControls ? 'all' : 'none'}
          zoomControl={showMapControls}
          streetViewControl={showMapControls}
          fullscreenControl={showMapControls}
        >
          {this.addDistrictOrStateMarkers()}
          {this.addSchoolMarkers()}
          {this.addStudentMarkers()}
          {this.addSingleSchoolMarkers()}
          {this.getInfoWindow()}
        </Map>
      </div>
    );
  }
}

HubGoogleMap.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  zoom: PropTypes.number,
  visible: PropTypes.bool,
  districts: PropTypes.array,
  states: PropTypes.array,
  schools: PropTypes.array,
  students: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  singleSchool: PropTypes.array,
  google: PropTypes.object,
  multiMap: PropTypes.bool,
};

HubGoogleMap.defaultProps = {
  zoom: HubGoogleMap.MAP_ZOOM_CONFIG.defaultMapZoom,
  visible: true,
  multiMap: false,
};

export default GoogleApiWrapper({
  apiKey: env.googleMaps.apiKey,
})(HubGoogleMap);
