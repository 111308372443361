import Brightness1 from "@material-ui/icons/Brightness4";
import Brightness2 from "@material-ui/icons/Brightness5";
import studentsIcon from "@material-ui/icons/LocalLibrary";
import ServiceAlertsIcon from "@material-ui/icons/NotificationsActive";
import usersIcon from "@material-ui/icons/People";
import parentIcon from "@material-ui/icons/Person";
import adminIcon from "@material-ui/icons/PersonAdd";
import allDistrictsIcon from "@material-ui/icons/Public";
import schoolsIcon from "@material-ui/icons/School";
import localforage from "localforage";
import React, { Component } from "react";
import {
  AUTH_LOGOUT,
  GET_LIST,
  GET_MANY,
  GET_ONE,
  Responsive,
  userLogout
} from "react-admin";
import { connect } from "react-redux";
import SecureLS from "secure-ls";

import extractProfile from "../analytics_config/extractProfile";
import { initAnalytic, pageAnalytic } from "../analytics_config/plugins/setAnalytic";
import authProvider from "../authProviderDirectCallVersion";
import config from "../conf/common";
import dataLink from "../dataStores/ddData/dataLink";
import api from "../dd_data_module/services/api";
import AuthenticationError from "./AuthenticationError";
import Header from "./Header";
import HubGoogleMap from "./google_maps/HubGoogleMap";
import SearchContainer from "./SearchContainer";
import Spinner from "./Spinner";
import SunriseSunsetOverlay from "./SunriseSunsetOverlay";
import Total from "./Total";

const ls = new SecureLS({ encodingType: "aes" });

const styles = {
  main: {
    flex: "1",
    marginRight: "1em",
    marginTop: 20
  },
  flexSimple: { display: "flex" },
  flex: { display: "flex", paddingLeft: "20px" },
  flexBase: { display: "flex", marginTop: "30px" },
  flexColumn: { display: "flex", flexDirection: "column" },
  flexDeepColumn: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "30px"
  },
  flexRow: { display: "flex", direction: "row" },
  flexDeepRow: { display: "flex", direction: "row", marginBottom: "30px" },
  leftCol: { flex: 1, marginRight: "1em" },
  rightCol: { flex: 1, marginLeft: "1em" },
  leftColMainThinner: { flex: 1, marginRight: "1em", flexDirection: "column" },
  rightColMainWider: { flex: 2, marginLeft: "1em", flexDirection: "column" },
  singleCol: { marginTop: "2em", marginBottom: "2em", padding: "20px" },
  singleColSmall: { marginTop: "2em", marginBottom: "2em", width: "80%" },
  mapCard: {
    background: "#ffffff",
    width: "auto",
    height: "auto",
    paddingTop: "10px",
    paddingBottom: "10px",
    marginTop: "0px",
    boxShadow:
      "0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)"
  },
  mapCol: {
    position: "relative",
    height: "406px",
    width: "auto",
    border: "1px solid #fff",
    paddingTop: "1px",
    margin: "20px",
    zIndex: "2"
  },
  mapOverlay: {
    position: "relative",
    marginTop: "-400px",
    left: "0",
    height: "400px",
    background: "linear-gradient(to bottom, black, transparent)",
    zIndex: "5",
    pointerEvents: "none"
  },
  pushIn: { marginLeft: "20px" }
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      district: 12,
      districts: [],
      districtName: "",
      schools: [],
      schoolsCount: 0,
      stats: {},
      authenticated: true,
      clicked: false
    };

    this.changeMap = this.changeMap.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.initialiser = this.initialiser.bind(this);
  }

  componentDidMount() {
    let pauseForTokenRefresh = ls.get("pauseForTokenRefresh");
    if (pauseForTokenRefresh === "undefined" || pauseForTokenRefresh === null) {
      pauseForTokenRefresh = 1;
    }
    this.timer = setTimeout(this.initialiser, pauseForTokenRefresh);
  }

  UNSAFE_componentWillMount() {
    clearTimeout(this.timer);
  }

  changeMap(districtId) {
    const { district, districts } = this.state;
    localforage.setItem("universalDistrictId", districtId).then(() => {
      const result = districts.filter(obj => {
        const dId = parseInt(districtId, 10);
        const id = parseInt(obj.id, 10);
        return id === dId;
      });
      if (result[0] !== undefined) {
        const districtName = result[0].name;
        this.setState({ district: districtId });
        this.setState({ districtName });
        dataLink(
          GET_LIST,
          "schools",
          {
            pagination: { page: 1, perPage: 100 },
            sort: { field: "id", direction: "ASC" },
            filter: { district_id: districtId }
          },
          district
        ).then(response => {
          localforage.setItem("schoolsCount", response.total).then(() => {
            localforage.setItem("schools", response.data).then(() => {
              localforage.setItem("districtName", districtName).then(() => {
                this.setState({ schoolsCount: response.total });
                this.setState({ schools: response.data });
              });
            });
          });
        });
      }
    });
  }

  handleClick(e) {
    const { clicked } = this.state;
    e.preventDefault();
    const newclicked = !clicked;
    this.setState({ clicked: newclicked });
  }

  initialiser() {
    // Profile
    api.getProfile().then(response => {
      const profile = response.data.result;

      const { status } = response;
      if (status !== undefined) {
        if (status !== 200) {
          if (status === 403) {
            this.setState({ authenticated: false });
          }
        }
      }

      if (profile !== undefined) {
        ls.set("first_name", profile.first_name);
        ls.set("last_name", profile.last_name);
        ls.set("email", profile.email);
        ls.set("phone", profile.phone);
        ls.set("user_id", profile.user_id);
      }
    });

    dataLink(GET_ONE, "stats", {}).then(response => {
      if (response === "Not authenticated") {
        this.setState({ authenticated: false });
        ls.set("authenticated", "no");
      } else {
        const { status } = response;
        if (status !== undefined) {
          if (status !== 200) {
            if (status === 403) {
              this.setState({ authenticated: false });
            }
          }
        }
        if (response.data === undefined) {
          authProvider(AUTH_LOGOUT, {});
          window.location.reload();
        } else {
          const stats = response.data;
          this.setState({ stats });
        }
      }
    });

    dataLink(GET_MANY, "districts", {}).then(response => {
      if (response === "Not authenticated") {
        this.setState({ authenticated: false });
        ls.set("authenticated", "no");
      } else {
        const { status } = response;
        if (status !== undefined) {
          if (status !== 200) {
            if (status === 403) {
              this.setState({ authenticated: false });
            }
          }
        }
        if (response.data !== undefined) {
          const districts = response.data;
          this.setState({ districts });
        }
      }
    });

    // Schools
    dataLink(GET_LIST, "schools", {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "id", direction: "ASC" },
      filter: {}
    }).then(response => {
      if (response === "Not authenticated") {
        this.setState({ authenticated: false });
      } else {
        const { status } = response;
        if (status !== undefined) {
          if (status !== 200) {
            if (status === 403) {
              this.setState({ authenticated: false });
            }
          }
        } else if (response.total === undefined) {
          this.setState({ schoolsCount: 0 });
          localforage.getItem("schoolsCount").then(() => {
            // In this case we're on a delayed async call
          });
        } else {
          localforage.setItem("schoolsCount", response.total).then(() => {
            this.setState({ schoolsCount: response.total });
          });
        }
      }
    });
  }

  render() {
    const {
      authenticated,
      clicked,
      districts,
      districtName,
      stats,
      schools
    } = this.state;
    if (!authenticated) {
      ls.set("authenticated", "no");
      return <AuthenticationError />;
    }

    let mapCard = {
      background: "#FFFFFF",
      width: "auto",
      height: "auto",
      paddingTop: "10px",
      paddingBottom: "10px",
      marginTop: "0px",
      boxShadow:
        "0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)"
    };

    const ToggleButton = {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      background: "transparent",
      width: "100%",
      height: "auto",
      padding: "0",
      marginTop: "3px"
    };

    const TextOrientation = {
      marginLeft: "5px",
      paddingTop: "5px",
      cursor: 'pointer'
    };

    const usingIE = ls.get("usingIE");

    if (!usingIE) {
      mapCard = {
        background: "#ffffff",
        width: "845px",
        height: "auto",
        paddingTop: "10px",
        paddingBottom: "10px",
        marginTop: "0px",
        boxShadow:
          "0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)"
      };
    }

    const { schoolsCount } = this.state;
    if (schoolsCount === 0) {
      return <Spinner />;
    }

    if (districts === null) {
      return <Spinner />;
    }

    if (districts.length === 0) {
      return <Spinner />;
    }

    let headerContent = `Schools for ${districtName}`;
    headerContent = "All Districts";
    const parents = stats.users_count - stats.district_dashboard_users_count;

    const newProfile = extractProfile();
    if (
      newProfile
      && districts.length > 0
      && schools.length > 0
      && schoolsCount > 0
    ) {
      initAnalytic(newProfile);
      pageAnalytic(newProfile, { title: 'Dashboard' });
    }

    let message = "Use Map";
    let brightButton = (
      <Brightness2 style={{ fontSize: 30, color: "#F7C969" }} />
    );
    if (clicked) {
      brightButton = <Brightness1 style={{ fontSize: 30, color: "#353F71" }} />;
      message = "Show Sunrise";
    }

    return (
      <Responsive
        small={
          <React.Fragment>
            <div style={styles.singleColSmall}>
              <SearchContainer type="users" parameters={['email', 'name', 'phone']} marginBottom={40} />
            </div>
            <div style={styles.singleColSmall}>
              <Total
                text="pos.dashboard.total_districts"
                color={config.colors.backgroundBlue}
                icon={allDistrictsIcon}
                value={stats.districts_count}
                link="districts"
              />
            </div>
            <div style={styles.singleColSmall}>
              <Total
                text="pos.dashboard.total_dashboard_users"
                color={config.colors.backgroundGreen}
                icon={adminIcon}
                value={stats.district_dashboard_users_count}
                link="users"
              />
            </div>
            <div style={styles.singleColSmall}>
              <Total
                text="pos.dashboard.total_parents"
                color={config.colors.backgroundGreen}
                icon={parentIcon}
                value={parents}
                link="users"
              />
            </div>
            <div style={styles.singleColSmall}>
              <Total
                text="pos.dashboard.total_schools"
                color={config.colors.backgroundOrange}
                icon={usersIcon}
                value={stats.schools_count}
                link="schools"
              />
            </div>
            <div style={styles.singleColSmall}>
              <Total
                text="pos.dashboard.total_students"
                color={config.colors.backgroundOrange}
                icon={studentsIcon}
                value={stats.students_count}
                link="students"
              />
            </div>
            <div style={styles.singleColSmall}>
              <Total
                text="pos.dashboard.total_service_alerts"
                color={config.colors.backgroundRed}
                icon={ServiceAlertsIcon}
                value={stats.service_alerts_count}
                link="servicealerts"
              />
            </div>
            <div style={styles.singleColSmall}>
              <Total
                text="pos.dashboard.total_activity_users"
                color={config.colors.backgroundRed}
                icon={usersIcon}
                value={stats.users_following_activity_students_count}
                link="activitystudents"
              />
            </div>
            <div style={styles.singleColSmall}>
              <Header content={headerContent} orientation="left" />
              <HubGoogleMap districts={districts} />
            </div>
          </React.Fragment>
        }
        medium={
          <div style={styles.flex}>
            <div style={styles.leftColMainThinner}>
              <SearchContainer type="users" parameters={['email', 'name', 'phone']} marginBottom={40} />
              <div style={styles.flexDeepRow}>
                <Total
                  text="pos.dashboard.total_districts"
                  color={config.colors.backgroundBlue}
                  icon={allDistrictsIcon}
                  value={stats.districts_count}
                  link="districts"
                  align="left"
                />
                <Total
                  text="pos.dashboard.total_dashboard_users"
                  color={config.colors.backgroundGreen}
                  icon={adminIcon}
                  value={stats.district_dashboard_users_count}
                  link="users"
                  align="right"
                />
              </div>
              <div style={styles.flexDeepRow}>
                <Total
                  text="pos.dashboard.total_parents"
                  color={config.colors.backgroundGreen}
                  icon={parentIcon}
                  value={parents}
                  link="users"
                  align="left"
                />
                <Total
                  text="pos.dashboard.total_schools"
                  color={config.colors.backgroundOrange}
                  icon={schoolsIcon}
                  value={stats.schools_count}
                  link="schools"
                  align="right"
                />
              </div>
              <div style={styles.flexDeepRow}>
                <Total
                  text="pos.dashboard.total_students"
                  color={config.colors.backgroundOrange}
                  icon={studentsIcon}
                  value={stats.students_count}
                  link="students"
                  align="left"
                />
                <Total
                  text="pos.dashboard.total_service_alerts"
                  color={config.colors.backgroundRed}
                  icon={ServiceAlertsIcon}
                  value={stats.service_alerts_count}
                  link="servicealerts"
                  align="right"
                />
              </div>
              <div style={styles.flexDeepRow}>
                <Total
                  text="pos.dashboard.total_activity_users"
                  color={config.colors.backgroundOrange}
                  icon={usersIcon}
                  value={stats.users_following_activity_students_count}
                  link="activitystudents"
                  align="left"
                />
                <Total empty />
              </div>
            </div>
            <div style={styles.rightColMainWider}>
              <div style={mapCard}>
                <Header
                  content={headerContent}
                  orientation="left"
                  pushdown="5px"
                />
                <div style={styles.mapCol}>
                  <HubGoogleMap
                    districts={districts}
                    visible={!clicked}
                    multiMap={true}
                  />
                  <SunriseSunsetOverlay clicked={clicked} visible={!clicked} />
                  <div
                    style={ToggleButton}
                    onClick={event => this.handleClick(event)}
                  >
                    <div>{brightButton}</div>
                    <div style={TextOrientation}>{message}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
    );
  }
}

const ConnectedDashboard = connect(
  undefined,
  { userLogout: userLogout() }
)(Dashboard);

export default ConnectedDashboard;
