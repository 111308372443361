import React from 'react';
import Card from '@material-ui/core/Card';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
  card: {
    float: 'left',
    margin: '-10px 20px 0 15px',
    zIndex: 100,
    borderRadius: 3
  },
  cardPushdown: {
    float: 'left',
    margin: '-5px 20px 0 15px',
    zIndex: 100,
    borderRadius: 3
  },
  cardLeft: {
    float: 'left',
    margin: '5px 20px 00px 0px',
    zIndex: 100,
    borderRadius: 3
  },
  icon: {
    float: 'right',
    width: 34,
    height: 34,
    padding: 14,
    color: '#fff'
  }
};

const CardIcon = ({
  Icon, classes, bgColor, orientation, fillColor,
}) => {
  if (orientation === 'down') {
    return (
      <Card className={classes.cardPushdown} style={{ backgroundColor: bgColor }}>
        <Icon className={classes.icon} fill={fillColor} />
      </Card>
    );
  } if (orientation === 'left') {
    return (
      <Card className={classes.cardLeft} style={{ backgroundColor: bgColor }}>
        <Icon className={classes.icon} fill={fillColor} />
      </Card>
    );
  }
  return (
    <Card className={classes.card} style={{ backgroundColor: bgColor }}>
      <Icon className={classes.icon} fill={fillColor} />
    </Card>
  );
};

export default withStyles(styles)(CardIcon);
