import PropTypes from 'prop-types';
import { stringify } from 'qs';
import React from 'react';
import { Link } from 'react-admin';

function FilterLink({
  to, page, perPage, filter, children
}) {
  const queryParams = {
    page,
    perPage
  };

  if (filter) {
    queryParams.filter = JSON.stringify(filter);
  }

  const toObj = {
    pathname: to,
    search: stringify(queryParams)
  };

  return (
    <Link
      to={toObj}
    >
      {children}
    </Link>
  );
}

FilterLink.defaultProps = {
  page: 1,
  perPage: 10,
  filter: null
};

FilterLink.propTypes = {
  to: PropTypes.string.isRequired,
  page: PropTypes.number,
  perPage: PropTypes.number,
  filter: PropTypes.shape()
};

export default FilterLink;
