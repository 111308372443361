import Card from "@material-ui/core/Card";
import React from "react";
import {
  DateField, Show, SimpleShowLayout, TextField, BooleanField
} from "react-admin";
import withStyles from '@material-ui/core/styles/withStyles';
import TransformScheduleIcon from '@material-ui/icons/EventNote';
import extractProfile from "../analytics_config/extractProfile";
import { pageAnalytic } from "../analytics_config/plugins/setAnalytic";
import CardIcon from "../components/CardIconTwo";
import Header from "../components/Header";
import config from "../conf/common";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  main: {
    flex: "1",
    marginRight: "1em",
    marginTop: 20
  },
  card: {
    overflow: "inherit",
    textAlign: "left",
    padding: 16,
    minHeight: 52
  },
  noActions: {
    marginTop: '60px',
    marginLeft: '15px'
  }
});

const TransformScheduleView = props => {
  const headerContent = "Transform Schedule Details";

  const newProfile = extractProfile();
  if (newProfile) {
    pageAnalytic(newProfile, { title: 'Transform Schedule View' });
  }

  return (
    <Card className={styles.card}>
      <CardIcon
        Icon={TransformScheduleIcon}
        bgColor={config.colors.backgroundRed}
      />
      <Header content={headerContent} orientation="left" />
      <Show title="Transform Schedule Details" {...props}>
        <SimpleShowLayout>
          <DateField source="created_at" label="Date Created" />
          <DateField source="schedule_date" label="Scheduled Date" sortable={true} />
          <TextField source="external_id" label="External ID" />
          <TextField source="route_name" label="Route Name" />
          <TextField source="run_code" label="Run Code" />
          <TextField source="dispatch_type" label="Dispatch Type" />
          <TextField source="district.name" label="District" sortBy="district_name" />
          <BooleanField source="accepted" label="Accepted" />
          <BooleanField source="processed" label="Processed" />
          <TextField source="original_payload.action_code" label="Action Code" />
          <TextField source="original_payload.employeeId" label="Employee ID" />
          <TextField source="original_payload.businessUnitName" label="Botsford" />
          <TextField source="original_payload.orig_expected_start_time" label="Original Expected Start Time" />
          <TextField source="original_payload.orig_expected_end_time" label="Original Expected End Time" />
          <TextField source="original_payload.previous_expected_start_time" label="Previous Expected Start Time" />
          <TextField source="original_payload.previous_expected_end_time" label="Previous Expected End Time" />
          <TextField source="original_payload.expected_start_time" label="Expected Start Time" />
          <TextField source="original_payload.expected_end_time" label="Expected End Time" />
          <TextField source="original_payload.time_offset" label="Time Offset" />
          <TextField source="original_payload.scheduleSource" label="Schedule Source" />
          <TextField source="original_payload.vehicleAssetNumber" label="Vehicle Asst Number" />
          <TextField source="original_payload" label="Original Payload" />
          <TextField source="error_message" label="Error Message" />
        </SimpleShowLayout>
      </Show>
    </Card>
  );
};

export default withStyles(styles)(TransformScheduleView);
