import Card from "@material-ui/core/Card";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { translate } from "react-admin";
import NumberFormat from "react-number-format";

import CardIcon from "./CardIcon";
import FilterLink from "./FilterLink";

const styles = {
  main: {
    flex: "1",
    marginRight: ".5em",
    marginTop: 20,
    minWidth: "300px"
  },
  mainNoMargin: {
    flex: "1",
    marginTop: 20,
    minWidth: "300px"
  },
  mainRight: {
    flex: "1",
    marginLeft: ".5em",
    marginTop: 20,
    minWidth: "300px"
  },
  cardOff: {
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
    minHeight: 52,
    border: "1px solid #f1f1f1",
    background: "#f8f8f8"
  },
  cardOn: {
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
    minHeight: 52,
    border: "1px dotted #001E61",
    background: "#f1f1f1"
  },
  cardEmpty: {
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
    minHeight: 52,
    border: "none",
    background: "transparent"
  },
  cardNormal: {
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
    minHeight: 52
  }
};
class Total extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false
    };
  }

  toggleHover = () => {
    this.setState(({ hover }) => ({ hover: !hover }));
  };

  render() {
    const { hover } = this.state;
    const {
      align,
      classes,
      color,
      empty,
      filter,
      icon,
      link,
      orientation,
      text,
      textoutput,
      translate: transl,
      value
    } = this.props;
    let classname = classes.cardOff;
    if (hover) {
      classname = classes.cardOn;
    }

    if (empty) {
      return (
        <div className={classes.mainRight}>
          <div className={classes.cardEmpty} />
        </div>
      );
    }

    let inner = (
      <FilterLink to={`/${link}`} filter={filter}>
        <Card
          onMouseEnter={this.toggleHover}
          onMouseLeave={this.toggleHover}
          className={classname}
        >
          <Typography className={classes.title} color="textSecondary">
            {transl(text)}
          </Typography>
          <Typography variant="h5">
            <NumberFormat value={value} displayType="text" thousandSeparator />
          </Typography>
        </Card>
      </FilterLink>
    );

    if (!link) {
      inner = (
        <Card className={classes.cardNormal}>
          <Typography className={classes.title} color="textSecondary">
            {transl(text)}
          </Typography>
          <Typography variant="h5">
            <NumberFormat value={value} displayType="text" thousandSeparator />
          </Typography>
        </Card>
      );
    }

    if (textoutput) {
      inner = (
        <Card className={classes.cardNormal}>
          <Typography className={classes.title} color="textSecondary">
            {transl(text)}
          </Typography>
          <Typography variant="h5">
            {value}
          </Typography>
        </Card>
      );
    }

    const classNames = {
      right: classes.mainRight,
      noMargin: classes.mainNoMargin
    };

    return (
      <div className={classNames[align] || classes.main}>
        <CardIcon Icon={icon} bgColor={color} orientation={orientation} />
        {inner}
      </div>
    );
  }
}

Total.defaultProps = {
  filter: null
};

Total.propTypes = {
  filter: PropTypes.shape()
};

export default translate(withStyles(styles)(Total));
