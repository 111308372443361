import React from "react";
import ReactDOM from "react-dom";
import HttpsRedirect from "react-https-redirect";

import "./index.css";
import App from "./App";
import { unregister } from "./registerServiceWorker";

const render = () => ReactDOM.render(
  <HttpsRedirect>
    <App />
  </HttpsRedirect>,
  document.getElementById("root")
);

render(App);

if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default;
    render(NextApp);
  });
}

unregister();
