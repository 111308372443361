import React from 'react';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import { translate } from 'react-admin';
import androidLogo from "../../icon-android.png";
import iosLogo from "../../icon-ios.png";
import {
  groupBy
} from 'lodash';

const styles = {
  subHead: {
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.00938em'
  },
  onesignalIds: {
    fontSize: '0.9rem',
    marginBottom: 34,
    marginLeft: 50
  }
}

const PushNotificationCredentials = (props) => {

  const { notificationCredentials, onesignalActive, onesignalId, externalId } = props;

  const GetRecipientsList = ({ notificationCredentials }) => {
    const split = groupBy(notificationCredentials, 'active');
    return (
      <div>
        {
          split.true && split.true.length > 0 && (
            <div>
              <h1 style={styles.subHead}>Active Push Notifications Credentials</h1>
              {split.true.map((credential) => (
                <div className='platformListItem' key={credential.registration_id}>
                  <img src={credential.platform === 'IOS' ? iosLogo : androidLogo} className='platformIcon' />
                  {credential.registration_id}
                </div>
              ))}
            </div>
          )
        }
        {
          split.false && split.false.length > 0 && (
            <div>
              <h1 style={styles.subHead}>Inactive Push Notifications Credentials</h1>
              {split.false.map((credential) => (
                <div className='platformListItem' key={credential.registration_id}>
                  <img src={credential.platform === 'ios' ? iosLogo : androidLogo} className='platformIcon' />
                  {credential.registration_id}
                </div>
              ))}
            </div>
          )
        }
      </div>
    )
  }

  if (!onesignalActive) {
    return <h1 style={styles.subHead}>Could not fetch data from OneSignal</h1>
  }

  return (
    <List dense>
      {externalId && <div>
        <h1 style={styles.subHead}>External ID</h1>
        <p style={styles.onesignalIds}>{externalId}</p>
      </div>}
      {onesignalId && <div>
        <h1 style={styles.subHead}>OneSignal ID</h1>
        <p style={styles.onesignalIds}>{onesignalId}</p>
      </div>}
      {Array.isArray(notificationCredentials) && notificationCredentials.length > 0 ? (
        <GetRecipientsList notificationCredentials={notificationCredentials} />
      ) : (
        <Typography>No push notification credentials configured.</Typography>
      )}
    </List>
  )
}

export default translate(PushNotificationCredentials)