
import React from 'react'
import { RadioOutlined } from '@material-ui/icons'
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { translate } from 'react-admin';
import FormControlLabel from "@material-ui/core/FormControlLabel";


const options = ["see_map", "eta", "eta_messaging"]

const styles = () => ({
    appEtaDisplayGroup: {
        marginLeft: 14,
    },
});


const CustomRadioGroup = ({ translate, name, input }) => {
    return (
        <div style={styles.appEtaDisplayGroup}>
            <RadioGroup
                aria-label={name}
                name={name}
                value={input.value}
                onChange={input.onChange}
            >
                {options.map(option =>
                    <FormControlLabel
                        value={option}
                        control={
                            <Radio
                                disableRipple
                                color={'primary'}
                            />
                        }
                        label={translate(`resources.districts.field_labels.${option}`)}
                    />
                )}
            </RadioGroup>
            <hr />
        </div>
    )
}

export default translate(CustomRadioGroup)
