import Card from "@material-ui/core/Card";
import CircularProgress from "@material-ui/core/CircularProgress";
import studentsIcon from "@material-ui/icons/LocalLibrary";
import usersIcon from "@material-ui/icons/People";
import Typography from "@material-ui/core/Typography";
import localforage from "localforage";
import React, { Component } from "react";
import PropTypes from 'prop-types';
import {
  BooleanField, GET_LIST, GET_ONE, Responsive, Show, SimpleShowLayout, TextField
} from "react-admin";
import ReactResizeDetector from "react-resize-detector";
import SecureLS from "secure-ls";

import extractProfile from "../analytics_config/extractProfile";
import { pageAnalytic } from "../analytics_config/plugins/setAnalytic";
import Header from "../components/Header";
import HubGoogleMap from "../components/google_maps/HubGoogleMap";
import Spinner from "../components/Spinner";
import SunriseSunsetMarker from "../components/SunriseSunsetMarker";
import Total from "../components/Total";
import config from "../conf/common";
import dataLink from "../dataStores/ddData/dataLink";
import AdminPaginationTable from "./Layouts/AdminPaginationTable";
import StudentPaginationTable from "./Layouts/StudentPaginationTable";
import TimeZoneField from "../lists/utilities/TimeZoneField";

const ls = new SecureLS({ encodingType: "aes" });

const styles = {
  flexSimple: { display: "flex" },
  flex: { display: "flex", paddingLeft: "20px", marginTop: "10px" },
  flexBase: { display: "flex", marginTop: "30px" },
  flexColumn: { display: "flex", flexDirection: "column" },
  flexRow: { display: "flex", direction: "row", marginBottom: "10px" },
  flexDeepRow: { display: "flex", direction: "row", marginBottom: "30px" },
  deepRow: { marginBottom: "30px" },
  shallowRow: { marginBottom: "10px" },
  leftCol: { flex: 1, marginRight: "1em" },
  rightCol: { flex: 1, marginLeft: "1em" },
  singleCol: { marginTop: "2em", marginBottom: "2em", padding: "20px" },
  singleColSmall: { marginTop: "2em", marginBottom: "2em", width: "80%" },
  leftColMainThinner: { flex: 1, marginRight: "1em", flexDirection: "column" },
  rightColMainWider: {
    flex: 2, marginLeft: "1em", flexDirection: "column", marginRight: "1em"
  },
  card: {
    overflow: "inherit",
    textAlign: "left",
    padding: 10,
    minHeight: 32,
    marginRight: 5
  },
  main: {
    flex: "1",
    marginRight: "1em"
  },
  mapCard: {
    marginTop: "20px",
    marginBottom: "20px",
    background: "#ffffff",
    width: "auto",
    height: "auto",
    paddingTop: "10px",
    paddingBottom: "10px",
    boxShadow:
      "0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)"
  },
  mapCol: {
    height: "400px",
    width: "auto",
    border: "1px solid #fff",
    margin: "20px"
  },
  pushIn: { marginLeft: "20px" },
  fixHeight: { minHeight: "40px" },
  fixHeightAndWidth: { minHeight: "40px", width: "100%" },
  sunriseSunsetContainer: { marginBottom: 30, padding: 15 },
  settingsNote: {
    color: "rgba(0, 0, 0, 0.54)",
    padding: 0,
    fontSize: "0.9rem",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    lineHeight: 1,
    fontStyle: 'italic',
    marginBottom: "1.05em"
  }
};

class AllSchoolsView extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      students: undefined,
      allStudents: undefined,
      allUsers: undefined,
      page: 1,
      rowsPerPage: 10,
      singleSchool: null,
      resizing: false
    };

    this.initialiser = this.initialiser.bind(this);

    this.resize = () => {
      const { resizing } = this.state;
      if (!resizing) {
        this.setState({ resizing: true }, () => setTimeout(() => {
          this.setState({ resizing: false });
        }, 1400));
      }
    };
  }

  componentDidMount() {
    let pauseForTokenRefresh = ls.get("pauseForTokenRefresh");
    if (pauseForTokenRefresh === "undefined" || pauseForTokenRefresh === null) {
      pauseForTokenRefresh = 1;
    }
    this.timer = setTimeout(this.initialiser, pauseForTokenRefresh);
    window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    window.removeEventListener("resize", this.resize);
  }

  initialiser() {
    const { id } = this.props;

    dataLink(GET_ONE, "schools", {
      id
    }).then(response => {
      if (response.error !== undefined) {
        if (response.error === "Unauthorized") {
          localforage.getItem("singleSchool").then(value => {
            this.setState({ singleSchool: value });
          });
        }
      } else {
        localforage.setItem("singleSchool", response).then(() => {
          this.setState({ singleSchool: response.data });
        });
      }
    });

    const { page, rowsPerPage } = this.state;
    dataLink(GET_LIST, "students", {
      // Data for table
      pagination: { page, perPage: rowsPerPage },
      sort: { field: "id", direction: "ASC" },
      filter: { school_id: id }
    }).then(response => {
      if (response.error !== undefined) {
        if (response.error === "Unauthorized") {
          localforage.getItem("SchoolStudentsCount").then(value => {
            this.setState({ students: value });
          });
        }
      } else if (response.total === undefined) {
        localforage.getItem("SchoolStudentsCount").then(value => {
          this.setState({ students: value });
        });
      } else {
        localforage.setItem("SchoolStudentsCount", response).then(() => {
          this.setState({ students: response });
        });
      }
    });

    dataLink(GET_LIST, "students", {
      // Data for map
      pagination: { page: 1, perPage: 500 },
      sort: { field: "id", direction: "ASC" },
      filter: { school_id: id }
    }).then(response => {
      if (response.error !== undefined) {
        if (response.error === "Unauthorized") {
          localforage.getItem("districtAllStudentsCount").then(value => {
            this.setState({ allStudents: value });
          });
        }
      } else if (response.total === undefined) {
        localforage.getItem("districtAllStudentsCount").then(value => {
          this.setState({ allStudents: value });
        });
      } else {
        localforage.setItem("districtAllStudentsCount", response).then(() => {
          this.setState({ allStudents: response });
        });
      }
    });

    dataLink(GET_LIST, "users", {
      // Data for map
      pagination: { page: 1, perPage: 50 },
      sort: { field: "id", direction: "ASC" },
      filter: { school_id: id }
    }).then(response => {
      if (response.error !== undefined) {
        if (response.error === "Unauthorized") {
          localforage.getItem("districtAllUsersCount").then(value => {
            this.setState({ allUsers: value });
          });
        }
      } else if (response.total === undefined) {
        localforage.getItem("districtAllUsersCount").then(value => {
          this.setState({ allUsers: value });
        });
      } else {
        localforage.setItem("districtAllUsersCount", response).then(() => {
          this.setState({ allUsers: response });
        });
      }
    });
  }

  handleChangePage(event, page) {
    this.setState({ page });
  }

  handleChangeRowsPerPage(event) {
    this.setState({ rowsPerPage: event.target.value });
  }

  renderSunriseSunset() {
    const { resizing, singleSchool = {} } = this.state;
    let { lat, lng } = singleSchool.location
      ? singleSchool.location
      : singleSchool;
    lat = parseFloat(lat);
    lng = parseFloat(lng);

    if (resizing) {
      return (
        <div
          style={{
            display: "flex",
            marginBottom: 20,
            justifyContent: "center"
          }}
        >
          <CircularProgress />
        </div>
      );
    }
    return (
      <Card style={styles.sunriseSunsetContainer}>
        <ReactResizeDetector handleWidth handleHeight>
          {({ width }) => (
            <SunriseSunsetMarker lat={lat} lng={lng} width={width} />
          )}
        </ReactResizeDetector>
      </Card>
    );
  }

  render() {
    const {
      allStudents, allUsers, students, singleSchool
    } = this.state;
    if (
      allStudents !== undefined
      && allUsers !== undefined
      && students !== undefined
      && singleSchool !== null
      && singleSchool !== undefined
    ) {
      let headerContent = `Stops for ${singleSchool.name}`;
      const newProfile = extractProfile();
      if (newProfile) {
        pageAnalytic(newProfile, { title: 'Schools View' });
      }

      let mapOutput = <HubGoogleMap zoom={13} students={allStudents.data} />;

      if (students.total === 0) {
        const wrappedSchoool = [singleSchool];
        mapOutput = (
          <HubGoogleMap zoom={13} singleSchool={wrappedSchoool} />
        );
        headerContent = `${singleSchool.name}`;
      }

      const { id } = this.props;

      return (
        <Responsive
          small={
            <div style={styles.singleColSmall}>
              <div style={styles.flexColumn}>
                <div style={styles.deepRow}>
                  <Header
                    content={singleSchool.name}
                    orientation="left"
                    pushleft="0px"
                  />
                </div>
                <div style={styles.deepRow}>
                  <Total
                    text="pos.dashboard.total_parents"
                    color={config.colors.backgroundGreen}
                    icon={usersIcon}
                    value={allUsers.total}
                    link={false}
                  />
                </div>
                <div style={styles.deepRow}>
                  <Total
                    text="pos.dashboard.total_students"
                    color={config.colors.backgroundOrange}
                    icon={studentsIcon}
                    value={allStudents.total}
                    link="students"
                  />
                </div>
                <div style={styles.deepRow}>
                  <Show title="Schools" {...this.props}>
                    <SimpleShowLayout>
                      <TextField source="district.name" label="District" />
                      <TextField source="name" label="School" />
                      <TextField source="security_code" label="School Code" />
                      <TimeZoneField />
                      <BooleanField
                        source="district.settings.streetz"
                        label="Streets District?"
                      />
                      <BooleanField
                        source="district.settings.security_code_required"
                        label="Pin Code Security Enabled On District?"
                      />
                      <TextField source="lat" label="Latitude" />
                      <TextField source="lng" label="Longitude" />
                    </SimpleShowLayout>
                  </Show>
                </div>
                <div style={styles.deepRow}>
                  <div style={styles.mapCard}>
                    <Header content={headerContent} orientation="left" />
                    <div style={styles.mapCol}>{mapOutput}</div>
                  </div>
                </div>
                <div style={styles.deepRow}>
                  <StudentPaginationTable id={id} />
                </div>
                <div style={styles.deepRow}>
                  <AdminPaginationTable parent="schooladmin" id={id} />
                </div>
                <div style={styles.deepRow}>
                  <AdminPaginationTable parent="schooluser" id={id} />
                </div>
              </div>
            </div>
          }
          medium={
            <div>
              <div style={{ marginRight: '1em' }}>
                <Header
                  content={singleSchool.name}
                  orientation="left"
                  pushleft="0px"
                />
                <div
                  style={{
                    ...styles.mapCard,
                    paddingTop: 1,
                  }}
                >
                  <Header content={headerContent} orientation="left" />
                  <div style={{ ...styles.mapCol, height: 300 }}>
                    {React.cloneElement(mapOutput, { height: 300 })}
                  </div>
                </div>
                {this.renderSunriseSunset()}
              </div>

              <div>
                <div style={styles.leftColMainThinner}>
                  <div
                    style={{
                      ...styles.flexDeepRow,
                      justifyContent: 'space-between',
                    }}
                  >
                    <Total
                      text="pos.dashboard.total_parents"
                      color={config.colors.backgroundGreen}
                      icon={usersIcon}
                      value={allUsers.total}
                      link={false}
                    />
                    <Total
                      text="pos.dashboard.total_students"
                      color={config.colors.backgroundOrange}
                      icon={studentsIcon}
                      value={allStudents.total}
                      link={false}
                      align="noMargin"
                    />
                  </div>
                  <div style={styles.flexColumn}>
                    <div style={styles.deepRow}>
                      <StudentPaginationTable id={id} />
                    </div>
                    <div style={styles.flexRow}>
                      <div style={styles.leftCol}>
                        <Show title="School" {...this.props}>
                          <SimpleShowLayout>
                            <TextField
                              source="id"
                              label="ID"
                              style={styles.fixHeight}
                            />
                            <TextField
                              source="district.name"
                              label="District"
                              style={styles.fixHeight}
                            />
                            <TextField source="security_code" label="School Code" />
                            <TimeZoneField />
                          </SimpleShowLayout>
                        </Show>
                      </div>
                      <div style={styles.rightCol}>
                        <Show title=" " {...this.props}>
                          <SimpleShowLayout style={styles.fixHeight}>
                            <BooleanField
                              source="district.settings.security_code_required"
                              label="Security Code Required?"
                              style={styles.fixHeight}
                            />
                            <BooleanField
                              source="district.settings.dashboard_eta_access"
                              label="Show ETA in Dash?"
                              style={styles.fixHeight}
                            />
                            <BooleanField
                              source="district.settings.student_adding_permitted"
                              label="Add Students?"
                              style={styles.fixHeight}
                            />
                            <p
                              style={styles.settingsNote}
                            >
                              Please note, settings are set at the district
                              level.
                            </p>
                          </SimpleShowLayout>
                        </Show>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    ...styles.rightColMainWider,
                    marginLeft: 0,
                    marginRight: '1em',
                  }}
                >
                  <div style={styles.flexColumn}>
                    <div style={styles.shallowRow}>
                      <AdminPaginationTable parent="schooladmin" id={id} />
                    </div>
                    <div style={styles.shallowRow}>
                      <AdminPaginationTable parent="schooluser" id={id} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          large={
            <div>
              <div style={styles.flex}>
                <div style={styles.leftCol}>
                  <Header
                    content={singleSchool.name}
                    orientation="left"
                    pushleft="0px"
                  />
                </div>
              </div>

              <div style={styles.flex}>
                <div style={styles.leftColMainThinner}>
                  <div style={styles.flexDeepRow}>
                    <Total
                      text="pos.dashboard.total_parents"
                      color={config.colors.backgroundGreen}
                      icon={usersIcon}
                      value={allUsers.total}
                      link={false}
                    />
                    <Total
                      text="pos.dashboard.total_students"
                      color={config.colors.backgroundOrange}
                      icon={studentsIcon}
                      value={allStudents.total}
                      link={false}
                      align="right"
                    />
                  </div>
                  <div style={styles.flexColumn}>
                    <div style={styles.deepRow}>
                      <StudentPaginationTable id={id} />
                    </div>
                    <div style={styles.flexRow}>
                      <div style={styles.leftCol}>
                        <Show title="School" {...this.props}>
                          <SimpleShowLayout>
                            <TextField
                              source="id"
                              label="ID"
                              style={styles.fixHeight}
                            />
                            <TextField
                              source="district.name"
                              label="District"
                              style={styles.fixHeight}
                            />
                            <TextField source="security_code" label="School Code" />
                            <TimeZoneField />
                          </SimpleShowLayout>
                        </Show>
                      </div>
                      <div style={styles.rightCol}>
                        <Show title=" " {...this.props}>
                          <SimpleShowLayout style={styles.fixHeight}>
                            <BooleanField
                              source="district.settings.security_code_required"
                              label="Security Code Required?"
                              style={styles.fixHeight}
                            />
                            <BooleanField
                              source="district.settings.dashboard_eta_access"
                              label="Show ETA in Dash?"
                              style={styles.fixHeight}
                            />
                            <BooleanField
                              source="district.settings.student_adding_permitted"
                              label="Add Students?"
                              style={styles.fixHeight}
                            />
                            <p
                              style={styles.settingsNote}
                            >
                              Please note, settings are set at the district
                              level.
                            </p>
                          </SimpleShowLayout>
                        </Show>
                      </div>
                    </div>
                  </div>
                </div>

                <div style={styles.rightColMainWider}>
                  <div style={styles.flexColumn}>
                    <div style={styles.shallowRow}>
                      <div style={styles.mapCard}>
                        <Header content={headerContent} orientation="left" />
                        <div style={styles.mapCol}>{mapOutput}</div>
                      </div>
                    </div>
                    {this.renderSunriseSunset()}
                    <div style={styles.shallowRow}>
                      <AdminPaginationTable parent="schooladmin" id={id} />
                    </div>
                    <div style={styles.shallowRow}>
                      <AdminPaginationTable parent="schooluser" id={id} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      )
    }
    return <Spinner />;
  }
}

export default AllSchoolsView;
