import { createTypes } from "reduxsauce";

export default createTypes(`
  ADD_USER_ACCESS
  ADD_USER_ACCESS_SUCCESS
  ADD_USER_ACCESS_FAILURE

  REMOVE_USER_ACCESS
  REMOVE_USER_ACCESS_SUCCESS
  REMOVE_USER_ACCESS_FAILURE

  GET_ACCESS
  GET_ACCESS_SUCCESS
  GET_ACCESS_FAILURE

  GET_ACCESS_USERS
  GET_ACCESS_USERS_SUCCESS
  GET_ACCESS_USERS_FAILURE

  GET_USERS
  GET_USERS_SUCCESS
  GET_USERS_FAILURE

  GET_TOKEN
  GET_TOKEN_SUCCESS
  GET_TOKEN_FAILURE

  LOGIN
  LOGIN_SUCCESS
  LOGIN_FAILURE

  LOGOUT

  RESET_PASSWORD
  RESET_PASSWORD_SUCCESS
  RESET_PASSWORD_FAILURE

  USE_PERMANENT_PERSISTOR
  USE_SESSION_PERSISTOR

  SET_INITIAL_PATH
  REMOVE_INITIAL_PATH
`);
