import Card from "@material-ui/core/Card";
import withStyles from "@material-ui/core/styles/withStyles";
import studentsIcon from "@material-ui/icons/LocalLibrary";
import React from "react";
import {
  Datagrid, Filter, List, ReferenceInput, SelectInput, TextField, TextInput
} from "react-admin";
import SecureLS from "secure-ls";

import extractProfile from "../analytics_config/extractProfile";
import { pageAnalytic } from "../analytics_config/plugins/setAnalytic";
import AuthenticationError from "../components/AuthenticationError";
import CardIcon from "../components/CardIcon";
import Header from "../components/Header";
import ListActions from "../components/ListActions";
import config from "../conf/common";
import DynamicFilterField from "./utilities/DynamicFilterField";

const ls = new SecureLS({ encodingType: "aes" });

const styles = theme => ({
  rowEven: {
    backgroundColor: "#F8F8F8"
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  table: {
    minWidth: 500
  },
  tableWrapper: {
    overflowX: "auto"
  },
  main: {
    flex: "1",
    marginRight: "1em",
    marginTop: 20
  },
  card: {
    overflow: "inherit",
    textAlign: "left",
    padding: 16,
    minHeight: 52,
    marginRight: '1em'
  }
});

const StudentFilter = props => (
  <Filter {...props}>
    <TextInput
      label="Search Student Number"
      source="student_number"
      defaultValue=""
      style={{ width: 200 }}
      alwayson="true"
    />
    <TextInput
      label="Search First Name"
      source="first_name"
      defaultValue=""
      alwayson="true"
    />
    <TextInput
      label="Search Last Name"
      source="last_name"
      defaultValue=""
      alwayson="true"
    />
    <TextInput
      label="Search School"
      source="school.name"
      defaultValue=""
      alwayson="true"
    />
    <ReferenceInput
      label="Filter By District"
      source="district.id"
      reference="districts"
      allowEmpty
      perPage={1000000}
      alwayson="true"
      filter={{ simple: true }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <DynamicFilterField style={{ width: 235 }} label="Filter By Number Of Stops" entity="students" source="stops_count" alwayson="true" translate={false}/>
    <ReferenceInput
      label="Filter By State"
      source="state.id"
      reference="states"
      allowEmpty
      perPage={1000000}
      alwayson="true"
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const StudentsList = withStyles(styles)(({ classes, ...props }) => {
  const headerContent = "All Students";

  const newProfile = extractProfile();
  if (newProfile) {
    pageAnalytic(newProfile, { title: 'Students Listing' });
  }

  const authenticated = ls.get("authenticated");

  if (authenticated === "yes") {
    return (
      <React.Fragment>
        <Card className={classes.card}>
          <CardIcon
            Icon={studentsIcon}
            bgColor={config.colors.backgroundOrange}
            orientation="left"
          />
          <Header content={headerContent} orientation="left" />
          <List
            title="All Students"
            {...props}
            bulkActionButtons={false}
            filters={<StudentFilter />}
            actions={<ListActions path="users" type="students" csvDownloadEnabled={false} />}
          >
            <Datagrid rowClick="show" classes={{ rowEven: classes.rowEven }}>
              <TextField
                source="student_number"
                label="Student Number"
                sortable={false}
              />
              <TextField source="first_name" label="First Name" />
              <TextField source="last_name" label="Last Name" />
              <TextField source="school.name" label="School" />
              <TextField source="district.name" label="District" />
              <TextField source="state.name" label="State" />
              <TextField
                source="stops_count"
                label="Number Of Stops"
                sortable={false}
              />
            </Datagrid>
          </List>
        </Card>
      </React.Fragment>
    );
  }
  return <AuthenticationError />;
});

export default StudentsList;
