import React from "react";
import Icon from "@material-ui/icons/Notifications";

const flex = {
  display: "flex",
  marginBottom: "5px",
  width: "100%",
  flexWrap: "wrap",
  justifyContent: "flex-start",
  fontSize: "1.1em"
};

const iconStyle = {
  minWidth: "25px",
  minHeight: "25px",
  color: "#EC1C23"
};

const headerStyle = {
  fontFamily: "'Roboto Slab', serif",
  marginLeft: "5px"
};

const numberStyle = {
  padding: "7px, 5px 5px 5px",
  background: "#FFFFFF",
  border: "1px solid #EC1C23",
  marginLeft: "10px",
  minWidth: "45px",
  maxHeight: "22px",
  textAlign: "center",
  borderRadius: "10px"
};

const ShowNotificationsCount = ({ amount }) => {
  if (amount > 0) {
    return (
      <div style={flex}>
        <div style={iconStyle}>
          <Icon />
        </div>
        <div style={headerStyle}>Notifications</div>
        <div style={numberStyle}>{amount}</div>
      </div>
    );
  }
  return (
    <div style={flex}>
      <div style={headerStyle}>No Notifications</div>
    </div>
  );
};

export default ShowNotificationsCount;
