import React, { useState } from 'react'
import Button from '@material-ui/core/Button';
import DialogMessage from "./DialogMessage";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Snackbar } from '@material-ui/core';
import {
    UPDATE, SaveButton, translate
} from 'react-admin';
import dataLink from '../dataStores/ddData/dataLink';

const divStyle = { marginBottom: "25px", marginTop: "25px", display: 'flex', alignItems: 'center' };


const AssumeUser = ({ userId, translate }) => {

    const [dialogOpen, setDialogOpen] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [userAssumed, setUserAssumed] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const AssumeUser = () => {
        setDialogOpen(false);
        setIsProcessing(true);
        dataLink(UPDATE, "users", {
            id: userId
        }).then(response => {
            if (response.status !== 200) {
                setError(true);
                setErrorMessage(response.data.message);
            } else {
                setUserAssumed(true);
            }
            setIsProcessing(false)
        }).catch(() => {
            setIsProcessing(false);
            setErrorMessage(translate('resources.users.assume.error'));
            setError(true);
        });
    }

    const snackBarClose = () => {
        setUserAssumed(false);
    }

    const errorSnackBarClose = () => {
        setError(false);
        setErrorMessage('');
    }

    return (
        <div>
            <div style={divStyle}>
                <Button
                    classes={{ root: 'delete-button' }}
                    variant="contained"
                    onClick={() => setDialogOpen(true)}
                    disabled={isProcessing}
                >
                    {translate('resources.users.assume.buttonText')}
                </Button>
                {isProcessing &&
                    <CircularProgress
                        size={20}
                        thickness={4}
                        isLoading={true}
                    />}
            </div>
            <DialogMessage
                dialogOpen={dialogOpen}
                titleText={translate('resources.users.assume.dialog.title')}
                contentText={translate('resources.users.assume.dialog.content')}
                closeFunction={() => setDialogOpen(false)}
                closeButtonText={translate('resources.users.assume.dialog.cancel')}
                saveButton={(
                    <SaveButton
                        label={translate('resources.users.assume.dialog.confirm')}
                        handleSubmitWithRedirect={AssumeUser}
                        variant={null}
                        icon={<span />}
                    />
                )}
            />
            <Snackbar
                open={userAssumed}
                onClose={() => snackBarClose()}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                autoHideDuration={1500}
                message={<span id="message-id">{translate('resources.users.assume.success')}</span>}
            />
            <Snackbar
                open={error}
                onClose={() => errorSnackBarClose()}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                autoHideDuration={2000}
                message={<span id="message-id">{errorMessage}</span>}
                ContentProps={{
                    classes: {
                        root: 'snackbar-error'
                    }
                }}
            />
        </div>
    )
}

export default translate(AssumeUser)