import React from 'react';
import {
  Datagrid,
  DateField,
  Filter,
  List,
  TextField,
  TextInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import SecureLS from 'secure-ls';

import Card from '@material-ui/core/Card';
import RidershipIcon from '../icons/RidershipIcon';
import withStyles from '@material-ui/core/styles/withStyles';
import FormattedFullDateTimeField from './utilities/FormattedFullDateTimeField';
import extractProfile from '../analytics_config/extractProfile';
import { pageAnalytic } from '../analytics_config/plugins/setAnalytic';
import AuthenticationError from '../components/AuthenticationError';
import CardIcon from '../components/CardIcon';
import Header from '../components/Header';
import config from '../conf/common';
import ListActions from '../components/ListActions';

const ls = new SecureLS({ encodingType: 'aes' });
const moment = require('moment');

const styles = (theme) => ({
  rowEven: {
    backgroundColor: '#F8F8F8',
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  main: {
    flex: '1',
    marginRight: '1em',
    marginTop: 20,
  },
  card: {
    overflow: 'inherit',
    textAlign: 'left',
    padding: 16,
    minHeight: 52,
    marginRight: '1em',
  },
});

const workingDays = () => {
  const arrayOfDays = [];
  //TODO: Make the number of days a config variable
  for (let i = 0; arrayOfDays.length <= 7; i++) {
    const momentDay = moment().subtract(i, 'days');
    if (momentDay.day() === 6 || momentDay.day() === 0) {
      continue;
    }
    arrayOfDays.push({
      id: momentDay.format('YYYY-MM-DD'),
      name: momentDay.format('dddd, MMMM Do'),
    });
  }
  return arrayOfDays;
};

const RidershipFilter = (props) => (
  <Filter {...props}>
    <TextInput
      label="Search Student Number"
      source="student_number"
      style={{ width: 200 }}
      alwayson="true"
    />

    <ReferenceInput
      label="Filter By District"
      source="district.id"
      reference="districts"
      allowEmpty
      perPage={1000000}
      alwayson="true"
      filter={{ simple: true }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <SelectInput
      style={{ width: 200, textAlign: 'center' }}
      label="Filter By Date"
      source="date"
      choices={workingDays()}
      alwayson="true"
    />
    <SelectInput
      style={{ width: 200, textAlign: 'center' }}
      label="Filter By Event Type"
      source="event_type"
      choices={[
        { id: 'Onboard', name: 'Onboard' },
        { id: 'Offboard', name: 'Offboard' },
      ]}
      alwayson="true"
    />
  </Filter>
);

const RidershipList = withStyles(styles)(({ classes, ...props }) => {
  const headerContent = 'Ridership';

  const newProfile = extractProfile();
  if (newProfile) {
    pageAnalytic(newProfile, { title: 'Ridership Listing' });
  }

  const authenticated = ls.get('authenticated');

  if (authenticated === 'yes') {
    return (
      <React.Fragment>
        <Card className={classes.card}>
          <CardIcon
            Icon={RidershipIcon}
            bgColor={config.colors.backgroundSeaLight}
            orientation="left"
          />
          <Header content={headerContent} orientation="left" />
          <List
            title="Ridership"
            {...props}
            bulkActionButtons={false}
            filters={<RidershipFilter />}
            actions={<ListActions type="ridership_events" />}
          >
            <Datagrid rowClick="show" classes={{ rowEven: classes.rowEven }}>
              <TextField
                source="student_number"
                label="Student Number"
                sortable={false}
              />
              <TextField source="tag_id" label="RFID" sortable={false} />
              <TextField
                source="student_last_name"
                label="Student Last Name"
                sortable={false}
              />
              <TextField
                source="event_type"
                label="Event Type"
                sortable={false}
              />
              <TextField
                source="district.name"
                label="District"
                sortBy="district_name"
              />
              <TextField source="run_name" label="Run Name" sortable={false} />
              <TextField
                source="vehicle_id"
                label="Vehicle ID"
                sortable={false}
              />
              <FormattedFullDateTimeField
                source="event_time_local"
                label="Event Time (Local)"
                sortable={false}
              />
              <DateField
                source="created_at"
                label="Created Time"
                locales="en-US"
                options={{ timeZone: 'UTC' }}
                sortable={false}
                showTime
              />
            </Datagrid>
          </List>
        </Card>
      </React.Fragment>
    );
  }
  return <AuthenticationError />;
});

export default RidershipList;
