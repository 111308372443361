import localforage from "localforage";
import {
    CREATE,
    DELETE,
    DELETE_MANY,
    GET_LIST,
    GET_MANY,
    GET_MANY_REFERENCE,
    GET_ONE,
    UPDATE,
    UPDATE_MANY
} from "react-admin";
import { bundle, sortResponse } from "./commonProviderFunctions";

import api from "../../dd_data_module/services/api";

const getTransformScheduleList = (type, resource, params) => {
    const { field, order } = params.sort;

    let direction = "ascend";
    if (order === "DESC") {
        direction = "descend";
    }

    if (field === "id") {
        direction = "ascend";
    }

    const { page, perPage } = params.pagination;
    return api
        .getTransformScheduleList(
            page,
            perPage,
            { columnKey: field, order: direction },
            params.filter,
        ).then(response => new Promise(resolve => {
            const code = response.status;
            if (code !== 200) {
                // grab from localForage then run function
                localforage.getItem("xtransformscheduledatabundle").then(databundle => {
                    const { datastream, count } = databundle;
                    const repackagedBundle = bundle(
                        datastream,
                        type,
                        resource,
                        params,
                        count
                    ); // Pick up the new params
                    const thisPromise = sortResponse(repackagedBundle);
                    thisPromise.then(resp => {
                        resolve(resp);
                    });
                });
            } else {
                const transformschedules = response.data.result;
                const count = response.data.info.total_count;
                // set to localforage, then run function
                const databundle = bundle(
                    transformschedules,
                    type,
                    resource,
                    params,
                    count
                );

                localforage
                    .setItem("xtransformscheduledatabundle", databundle)
                    .then(savedDatabundle => {
                        const thisPromise = sortResponse(savedDatabundle);
                        thisPromise.then(resp => {
                            resolve(resp);
                        });
                    });
            }
        }));
};

const getTransformScheduleOne = params => {
    return api
        .getTransformSchedule({ transformScheduleId: params.id })
        .then(response => new Promise(resolve => {
            const { message } = response.data;
            const { code } = response.data.response;
            if (message !== "Success" || code !== 200) {
                localforage.getItem("transformschedulebundle").then(databundle => {
                    resolve(databundle);
                });
            } else {
                const feedback = response.data.result;
                const databundle = { data: feedback };
                localforage
                    .setItem("transformschedulebundle", databundle)
                    .then(() => {
                        resolve(databundle);
                    });
            }
        }));
};


const getTransformSchedulesResponse = (type, resource, params, districtId) => {
    switch (type) {
        case GET_LIST: {
            return getTransformScheduleList(type, resource, params);
        }
        case GET_ONE:
            return getTransformScheduleOne(params);
        case GET_MANY:
            return {
                data: null
            };
        case GET_MANY_REFERENCE:
            return {
                data: null
            };
        case CREATE:
            return {
                data: null
            };
        case UPDATE:
            return {
                data: null
            };
        case UPDATE_MANY:
            return {
                data: null
            };
        case DELETE:
            return {
                data: null
            };
        case DELETE_MANY:
            return {
                data: null
            };
        default:
            return false;
    }
};

export default getTransformSchedulesResponse;
