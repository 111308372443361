import immutable from 'seamless-immutable';
import {createReducer} from 'reduxsauce';
import {
  isEmpty, values, forIn, camelCase
} from 'lodash';
import types from '../actions/types';

export const INITIAL_STATE = immutable({
  resetPasswordSubmitting: false,
  resetPasswordErrorCode: null,
  resetPasswordErrors: null,
  resetPasswordErrorMessage: null
});

const resetPasswordRequest = state => state.merge({
  resetPasswordSubmitting: true,
  resetPasswordErrorCode: null,
  resetPasswordErrors: null,
  resetPasswordErrorMessage: null
});

const resetPasswordSuccess = state => state.merge({
  resetPasswordSubmitting: false,
  resetPasswordErrorCode: null,
  resetPasswordErrors: null,
  resetPasswordErrorMessage: null
});

const resetPasswordFailure = (state, action) => {
  const camelCasedErrors = {};
  forIn(action.errors, (value, key) => {
    camelCasedErrors[camelCase(key)] = value;
  });

  return state.merge({
    resetPasswordSubmitting: false,
    resetPasswordErrorCode: action.errorCode,
    resetPasswordErrors: !isEmpty(camelCasedErrors) ? camelCasedErrors : null,
    resetPasswordErrorMessage: !isEmpty(action.errors) ? `${values(action.errors).join('. ')}.` : action.message
  });
};

const logout = () => INITIAL_STATE;

const ACTION_HANDLERS = {
  [types.RESET_PASSWORD]: resetPasswordRequest,
  [types.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
  [types.RESET_PASSWORD_FAILURE]: resetPasswordFailure,
  [types.LOGOUT]: logout
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
