import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { translate } from 'react-admin';
// import env from '../core/env.js';
import posed from 'react-pose';
import SecureLS from 'secure-ls';
const ls = new SecureLS({ encodingType: 'aes' });
const SunCalc = require('suncalc');

const animatiomTime = 700;

const Box = posed.div({
  hidden: { opacity: 0 },
  visible: { opacity: 1 }
});

const styles = {
  main: {
    flex: '1',
    marginRight: '1em',
    marginBottom: 30
  },
  card: {
    background: '#ffffff',
    width: 'auto',
    height: 'auto',
    padding: '10px 3px',
    marginBottom: '25px',
    marginTop: '5px',
    boxShadow:
      '0px 1px 3px 0px rgba(0, 0, 0, 0.1),0px 1px 1px 0px rgba(0, 0, 0, 0.1),0px 2px 1px -1px rgba(0, 0, 0, 0.1)'
  },
  inputtext: {
    background: '#f3f4f9',
    width: '99%',
    height: 50,
    fontSize: '1.2em',
    marginTop: '10px',
    padding: '5px',
    borderRadius: '5px'
  },
  listings: {
    background: '#fafafa',
    borderTop: '1px solid #aaa',
    borderBottom: '1px solid #fff'
  },
  mapOverlay: {
    position: 'relative',
    marginTop: '-400px',
    left: '0',
    height: '400px',
    // background: 'linear-gradient(to bottom, black, transparent)',
    zIndex: '5',
    pointerEvents: 'none',
    overflow: 'none',
    width: '801px',
    maxWidth: '801px',
    minWidth: '801px',
    maxHeight: '401px',
    minHeight: '401px'
  },
  svg: {
    maxWidth: '100%',
    maxHeight: '100%',
    display: 'block',
    margin: 'auto',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  }
};

class SunriseSunsetOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false,
      isVisible: false,
      wave: {}
    };
  }

  initialiseSvg = wave => {
    const xStart = 0.1;

    function makePath(points, wave) {
      const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
      path.setAttribute('stroke', 'black');
      path.setAttribute('stroke-width', '.2');
      path.setAttribute('fill', '#000044');
      path.setAttribute('d', `M 0,0 ${points.join(' ')} 100,0 L0 0`);
      path.setAttribute('transform', 'translate(0,0)');
      wave.appendChild(path);
    }

    // Roughly Mid America
    const lat = 37.0902;
    const lng = -95.7129;
    const tod = new Date();

    // get sunrise/sunset times
    const times = SunCalc.getTimes(tod, lat, lng);

    // get solarNoon for today
    const { dawn, dusk } = times;

    const timenow = new Date().getTime() / 1000;

    // let sixAM = new Date(2019, 1, 1, 6, 0, 0, 0).getTime() / 1000;
    // let eightAM = new Date(2019, 0, 25, 8, 0, 0, 0).getTime() / 1000;
    // let tenAM = new Date(2019, 0, 25, 10, 0, 0, 0).getTime() / 1000;
    // let elevenAM = new Date(2019, 0, 25, 11, 0, 0, 0).getTime() / 1000;
    // let twelveAM = new Date(2019, 0, 25, 12, 0, 0, 0).getTime() / 1000;
    // let onePM = new Date(2019, 0, 25, 13, 0, 0, 0).getTime() / 1000;
    // let twoPM = new Date(2019, 0, 25, 14, 0, 0, 0).getTime() / 1000;
    // let threePM = new Date(2019, 0, 25, 15, 0, 0, 0).getTime() / 1000;
    // let fivePM = new Date(2019, 0, 25, 17, 0, 0, 0).getTime() / 1000;
    // let fivePM = new Date(2019, 1, 5, 2, 59, 0, 0).getTime() / 1000;


    // timenow = fivePM;

    const noonix = new Date(dawn).getTime() / 1000;
    const dusknix = new Date(dusk).getTime() / 1000;

    const dayWidth = dusknix - noonix;

    let width = Math.round(dayWidth / 105);

    let weighting = 0;

    let triggerPoint = 0;
    let diffnix = 0;

    const speed = 600;
    weighting = +7;
    diffnix = noonix - timenow;
    triggerPoint = diffnix / speed + weighting;
    if (triggerPoint < -50) {
      width = Math.round(width + (50 + triggerPoint) * 7.89);
      triggerPoint = -50;
      if (width <= 0) {
        triggerPoint = (diffnix / speed + weighting) - 50;
        width = 0;
      }
    }
    if (triggerPoint > 80) {
      triggerPoint = 80;
    }


    const step = 0.1;
    const innerLimit = 100;
    const outerLimit = 200;
    const shapeAPoints = [];
    let widthcounter = 0;
    let yAxis = 50;
    let pyAxis = 50;
    let turned = false;
    let plot = 0;

    for (let i = xStart; i < outerLimit; i += step) {
      let iReadjust = i;
      if (iReadjust > triggerPoint) {
        const factor = triggerPoint;
        const xAdjusted = i - factor;
        iReadjust = xAdjusted;
        if (widthcounter === width) {
          iReadjust = xAdjusted - width * step;
        }
        if (!turned || widthcounter === width) {
          yAxis = 50 - 50 * plot;
          plot = this.plotOnBell(iReadjust / innerLimit);
          shapeAPoints.push([i, 50 - 50 * plot]);
          if (yAxis > pyAxis) {
            turned = true;
          }
          pyAxis = yAxis;
        }
        if (turned && widthcounter < width) {
          shapeAPoints.push([iReadjust, 0]);
          widthcounter++;
        }
      } else {
        shapeAPoints.push(i, 50);
      }

      // FOR LOOP COLLECTION END
    }

    makePath(shapeAPoints, wave);
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isVisible: true });
    }, animatiomTime);
    const usingIE = ls.get('usingIE');
    const wave = document.getElementById('wave');
    if (!usingIE) {
      this.initialiseSvg(wave);
    }
  }

  plotOnBell = (x, scale) => {
    // This is the real workhorse of this algorithm.
    // It returns values along a bell curve from 0 - 1 - 0 with an input of 0 - 1.
    scale = scale || false;
    const stdD = 0.125;
    const mean = 0.5;
    if (scale) {
      return (
        1
        / (1 / (stdD * Math.sqrt(2 * Math.PI)) * Math.pow(Math.E, -1 * Math.pow(x - mean, 2) / (2 * Math.pow(stdD, 2))))
      );
    }
    return (
      1
        / (stdD * Math.sqrt(2 * Math.PI))
        * Math.pow(Math.E, -1 * Math.pow(x - mean, 2) / (1 * Math.pow(stdD, 2.2)))
        * this.plotOnBell(0.5, true)
    );
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.clicked !== prevState.clicked) {
      return { clicked: nextProps.clicked };
    } return null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.clicked !== this.props.clicked) {
      // let wave = document.getElementById('wave');
      // this.initialiseSvg(wave);

      this.setState({ clicked: this.props.clicked });
    }

    if (prevProps.visible !== this.props.visible) {
      setTimeout(() => {
        this.setState({ isVisible: this.props.visible });
      }, animatiomTime);
    }
  }

  render() {
    const { isVisible } = this.state;
    const usingIE = ls.get('usingIE');
    if (usingIE || this.state.clicked === true) {
      return (
        <React.Fragment>
          <div style={styles.mapOverlay}>
            <Box className="box" pose={isVisible ? 'visible' : 'hidden'}>
              <svg viewBox="0 0 0 0">
                <defs>
                  <filter id="f1" x="0" y="0">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="0.55" />
                  </filter>
                </defs>
                <g id="wave" fillOpacity="0.7" filter="url(#f1)" />
              </svg>
            </Box>
          </div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <div style={styles.mapOverlay}>
          <Box className="box" pose={isVisible ? 'visible' : 'hidden'}>
            <svg viewBox="0 0 100 50">
              <defs>
                <filter id="f1" x="0" y="0">
                  <feGaussianBlur in="SourceGraphic" stdDeviation="0.55" />
                </filter>
              </defs>
              <g id="wave" fillOpacity="0.7" filter="url(#f1)" />
            </svg>
          </Box>
        </div>
      </React.Fragment>
    );
  }
}

export default translate(withStyles(styles)(SunriseSunsetOverlay));
