import localforage from "localforage";
import {
  CREATE,
  DELETE,
  DELETE_MANY,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
  UPDATE_MANY
} from "react-admin";

import api from "../../dd_data_module/services/api";
import { bundle, sortResponse } from "./commonProviderFunctions";

const getAllDistrictsList = (type, resource, params) => {
  const { field, order } = params.sort;

  let direction = "ascend";
  if (order === "DESC") {
    direction = "descend";
  }

  if (field === "id") {
    direction = "ascend";
  }

  const { page, perPage } = params.pagination;
  return api
    .getNewDistricts(
      page,
      perPage,
      { columnKey: field, order: direction },
      params.filter,
      params.filter.simple,
    )
    .then(response => new Promise(resolve => {
      const code = response.status;
      if (code !== 200) {
        // grab from localForage then run function
        localforage.getItem("xalldistrictsdatabundle").then(databundle => {
          const { datastream, count } = databundle;
          const repackagedBundle = bundle(
            datastream,
            type,
            resource,
            params,
            count
          ); // Pick up the new params
          const thisPromise = sortResponse(repackagedBundle);
          thisPromise.then(resp => {
            resolve(resp);
          });
        });
      } else {
        const alldistricts = response.data.result;
        const count = response.data.info.total_count;
        // set to localforage, then run function
        const databundle = bundle(
          alldistricts,
          type,
          resource,
          params,
          count
        );

        localforage
          .setItem("xalldistrictsdatabundle", databundle)
          .then(savedDatabundle => {
            const thisPromise = sortResponse(savedDatabundle);
            thisPromise.then(resp => {
              resolve(resp);
            });
          });
      }
    }));
};

const getDistrict = params => api.getNewDistrict(params.id).then(response => new Promise(resolve => {
  const { message } = response.data;
  const { code } = response.data.response;
  if (message !== "Success" || code !== 200) {
    localforage.getItem("xdistrictonedatabundle").then(databundle => {
      resolve(databundle);
    });
  } else {
    const districtone = response.data.result;
    const capabilities = response.data.capabilities;
    const databundle = { data: districtone, capabilities };
    localforage
      .setItem("xdistrictonedatabundle", databundle)
      .then(savedDatabundle => {
        resolve(savedDatabundle);
      });
  }
}));

const updateDistrict = (districtId, params) => {
  const updateFunction = 'enabled' in params ? api.updateDistrict : api.updateDistrictSettings;
  return updateFunction(districtId, params).then(response => new Promise((resolve, reject) => {
    const code = response.status;
    if (code === 403) {
      resolve("Not authenticated");
    } else if (code === 200) {
      const districtSettings = response.data;
      const databundle = { data: districtSettings };
      resolve(databundle);
    } else {
      reject(code);
    }
  }))
};

const getAllDistrictsHundredList = () => api
  .getNewDistricts(1, 100, { columnKey: "id", order: "DESC" }, {}, false)
  .then(response => new Promise(resolve => {
    const code = response.status;
    if (code === 403) {
      resolve("Not authenticated");
    } else if (code !== 200) {
      localforage
        .getItem("xallhundreddistrictsdatabundle")
        .then(databundle => {
          const { datastream, count } = databundle;
          const repackagedBundle = bundle(datastream, count); // Pick up the new params
          const thisPromise = sortResponse(repackagedBundle);
          thisPromise.then(resp => {
            resolve(resp);
          });
        });
    } else {
      const alldistricts = response.data.result;
      const count = response.data.info.total_count;
      // set to localforage, then run function
      const databundle = bundle(alldistricts, count);

      localforage
        .setItem("xallhundreddistrictsdatabundle", databundle)
        .then(savedDatabundle => {
          const thisPromise = sortResponse(savedDatabundle);
          thisPromise.then(resp => {
            resolve(resp);
          });
        });
    }
  }));

const getAllDistrictsResponse = (type, resource, params, districtId) => {
  switch (type) {
    case GET_LIST: {
      return getAllDistrictsList(type, resource, params);
    }
    case GET_ONE:
      return getDistrict(params);
    case GET_MANY:
      return getAllDistrictsHundredList(type, resource, params);
    case GET_MANY_REFERENCE:
      return {
        data: null
      };
    case CREATE:
      return {
        data: null
      };
    case UPDATE:
      return updateDistrict(districtId, params);
    case UPDATE_MANY:
      return {
        data: null
      };
    case DELETE:
      return {
        data: null
      };
    case DELETE_MANY:
      return {
        data: null
      };
    default:
      return false;
  }
};

export default getAllDistrictsResponse;
