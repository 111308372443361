import React from "react";
import Typography from "@material-ui/core/Typography";

const LatenessThresholdField = props => {
  const { latenessThreshold } = props;
  if (latenessThreshold) {
    const outerzone = latenessThreshold;
    const zone = `${outerzone / 60} minutes`;

    return (
      <Typography component="span" body1="body1">
        {zone}
      </Typography>
    );
  }
  return <span />;
};

LatenessThresholdField.defaultProps = {
  source: "Lateness Threshold",
  latenessThreshold: '',
  addLabel: true
};

export default LatenessThresholdField;
