import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Datagrid,
  DateField,
  Filter,
  List,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  BooleanField,
  BooleanInput
} from "react-admin";
import Card from "@material-ui/core/Card";
import TransformScheduleIcon from '@material-ui/icons/EventNote';
import SecureLS from "secure-ls";


import CardIcon from "../components/CardIcon";
import config from "../conf/common";
import Header from "../components/Header";
import AuthenticationError from "../components/AuthenticationError";
import extractProfile from "../analytics_config/extractProfile";
import { pageAnalytic } from "../analytics_config/plugins/setAnalytic";
import ListActions from "../components/ListActions";


const ls = new SecureLS({ encodingType: "aes" });

const styles = theme => ({
  rowEven: {
    backgroundColor: "#F8F8F8"
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  table: {
    minWidth: 500
  },
  tableWrapper: {
    overflowX: "auto"
  },
  main: {
    flex: "1",
    marginRight: "1em",
    marginTop: 20
  },
  card: {
    overflow: "inherit",
    textAlign: "left",
    padding: 16,
    minHeight: 52,
    marginRight: '1em'
  }
});

const PostFilter = props => (
  <Filter {...props}>
    <TextInput label="Search Route Name" source="route_name" defaultValue="" alwayson="true" />
    <TextInput label="Search Run Codes" source="run_code" defaultValue="" alwayson="true" />
    <ReferenceInput
      label="Filter By District"
      source="district.id"
      reference="districts"
      allowEmpty
      perPage={1000000}
      alwayson="true"
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <SelectInput
      style={{ width: 191 }}
      label="Filter By Dispatch Type"
      source="dispatch_type"
      choices={[{ id: "AM", name: "AM" }, { id: "MID", name: "MID" }, { id: "PM", name: "PM" }]}
      alwayson="true"
    />
    <BooleanInput
      style={{ width: 191 }}
      label="Filter By Processed"
      source="processed"
      alwayson="true"
    />
    <BooleanInput
      style={{ width: 191 }}
      label="Filter By Accepted"
      source="accepted"
      alwayson="true"
    />
  </Filter>
);

const TransformScheduleList = withStyles(styles)(({ classes, ...props }) => {
  const headerContent = "All Transform Schedules";

  const newProfile = extractProfile();

  if (newProfile) {
    pageAnalytic(newProfile, { title: 'Transform Schedules Listing' });
  }

  const authenticated = ls.get("authenticated");
  if (authenticated === "yes") {
    return (
      <React.Fragment>
        <Card className={classes.card}>
          <CardIcon
            Icon={TransformScheduleIcon}
            bgColor={config.colors.backgroundRed}
            orientation="left"
          />
          <Header content={headerContent} orientation="left" />
          <List
            title="All Transform Schedules"
            {...props}
            bulkActionButtons={false}
            filters={<PostFilter />}
            actions={<ListActions type="transform_schedule_requests" />}
            sort={{ field: 'created_at', order: 'DESC' }}
          >
            <Datagrid rowClick="show" classes={{ rowEven: classes.rowEven }}>
              <DateField source="created_at" label="Date Created" />
              <DateField source="schedule_date" label="Scheduled Date" sortable={true} />
              <TextField source="external_id" label="External ID" />
              <TextField source="route_name" label="Route Name" />
              <TextField source="run_code" label="Run Code" />
              <TextField source="original_payload.action_code" label="Action Code" sortable={false} />
              <TextField source="dispatch_type" label="Dispatch Type" />
              <TextField source="district.name" label="District" sortBy="district_name" />
              <BooleanField source="accepted" label="Accepted" sortable={false} />
              <BooleanField source="processed" label="Processed" sortable={false} />
            </Datagrid>
          </List>
        </Card>
      </React.Fragment>
    );
  }
  return <AuthenticationError />;
});

export default TransformScheduleList;
